import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Modal, Form, Toast } from 'react-bootstrap';
import { useHistory } from "react-router-dom";


const Watchlist = () => {
  let history = useHistory();

  const [watchlist, setWatchlist] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [toast, setToast] = useState(false);


  useEffect(() => {
          getData()
      }, [])

      const getData = async () => {

         const response = await axios.get('https://backend.tenet-wealth.com/v1/watchList')
         setWatchlist(response.data)
     }

     const removeData = (id) => {
        axios.delete('https://backend.tenet-wealth.com/v1/watchList/'+id).then(res => {
          const del = watchlist.filter(watchlist => id !== watchlist.id)
          setWatchlist(del)
        })
     }
     const renderHeader = () => {
         let headerElement = ['id', 'sym', 'conditions', 'text']

         return headerElement.map((key, index) => {
             return <th style={{"textAlign":"center"}} key={index}>{key.toUpperCase()}</th>
         })
     }

     function addWatchlist(event) {
       event.preventDefault();

       const payload= {'sym':event.target[0].value,
                      'cond':event.target[1].value,
                      'message':event.target[2].value}
       handleClose();
       axios.post('https://backend.tenet-wealth.com/v1/watchList',payload).then(res => {
         setToast(true);
         getData()
       })

     }

     const renderBody = () => {

        return watchlist && watchlist.map(({ id,sym, conditions, text }) => {
            return (
                <tr key={id}>
                    <td>{sym}</td>
                    <td>{conditions}</td>
                    <td>{text}</td>
                    <td className='opration'>
                        <button className='btn btn-outline-danger ' onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) removeData(id) } }>Delete</button>
                    </td>
                </tr>
            )
        })
    }

    return (
    <div>
      <div className='container'>
        <div className='row justify-content-md-center'>
          <div className='container col-10 ' >
              <h2 id='title' style={{"color":"#062190"}}>Watchlist</h2>
              <Button className='mb-3' variant='primary' onClick={() => history.push('/dashboard')}>Home</Button>

              <table id='employee' className='table-striped table-hover table-responsive'>
                  <thead className='thead-dark'>
                      <tr style={{"backgroundColor": "#5A5A5A","color":"#FFFFFF"}}>{renderHeader()}</tr>
                  </thead>
                  <tbody  style={{"justifyContent":"center"}}>
                      {renderBody()}
                  </tbody>
              </table>
          </div>
          <div className='col-2'>
            <br/><br/><br/>
            <button className="btn btn-primary" onClick={handleShow}>Add to Watch List</button>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add to Watch List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={e => addWatchlist(e)}>
            <Form.Group controlId="addWatchlist">
              <Form.Label>Criteria</Form.Label>
              <Form.Control type="text" name='sym' placeholder="Enter a symbol" /><br/>
              <Form.Control type="text" name ='condition' placeholder="Enter conditions to trigger alert"/><br/>
              <Form.Control type="text" name='condition' placeholder="Text to display for alert"/>

            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-danger' onClick={handleClose}>
            Cancel
          </button>

        </Modal.Footer>
      </Modal>
      <Toast onClose={() => setToast(false)} show={toast} delay={5000} autohide  style={{
        position: 'absolute',
        top: 25,
        right: 10,
      }}>
        <Toast.Header>
          <strong className="mr-auto">Watchlist</strong>
          <small>now</small>
        </Toast.Header>
        <Toast.Body>Watch list item added</Toast.Body>
      </Toast>
    </div>
    )

}
export default Watchlist

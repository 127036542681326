import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Login from './components/login.js';
import Home from './components/home.js';
import Navigation from './components/Navigation.js';
import Dashboard from './components/Dashboard.js';
import Trading from './components/Trading.js';
import Watchlist from './components/watchlist.js';
import Restrictions from "./components/restrictions/FilterTable";
import Restrictions1 from "./components/restrictions/Restrictions.js"
import Overview from "./components/Overview.js";
import BlockTrading from "./components/BlockTrading.js";
import WithdrawalsAddEdit from "./components/withdrawals/WithdrawalsAddEdit.js";
import AccountOverview from"./components/accounts/AccountOverview.js";
import Accounts from"./components/accounts/Accounts.js";
import Settings from "./components/admin/Settings.js";
import AdminDashboard from "./components/admin/AdminDashboard.js";
import ManualTrading from "./components/ManualTrading.js";
import Notifications from "./components/Notifications.js";
import FileDownload from "./components/FileDownload.js";
import CashManagement from "./components/CashManagement.js";
import PendedTrades from "./components/PendedTrades.js";
import Equivalences from "./components/Equivalences.js";

class App extends Component {
  render() {
    return(
      <BrowserRouter>
        <div>
          {/*<Navigation/>*/}
            <Switch>
            <Route path="/" component={Home} exact/>
            <Route path="/login" component = {Login}/>
            <Route path='/dashboard' component = {Dashboard}/>
            <Route path='/trading' component={Trading}/>
            <Route path='/watchlist' component={Watchlist}/>
            <Route path="/restrictions" component = {Restrictions1} />
            <Route path="/overview" component= {Overview} />
            <Route path="/blocktrading" component = {BlockTrading}/>
            <Route path="/manualtrading" component = {ManualTrading}/>
            <Route path="/accounts" component={Accounts} />
            <Route path="/settings" component={Settings} />
            <Route path="/notifications" component={Notifications} />
            <Route path='/fileDownload' component={FileDownload} />
            <Route path='/cashmanagement' component={CashManagement} />
            <Route path='/admin' component ={AdminDashboard} />
            <Route path='/pendedTrades' component={PendedTrades} />
            <Route path='/equivalences' component={Equivalences} />

          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}
export default App;

import React, {useState, useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Toast } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup'
import { useHistory } from "react-router-dom";
import {Table, Layout, Menu, Popconfirm, Statistic, Switch, Modal, notification} from 'antd';
import Select from 'react-select';


export default function UserEdit(props){
  const [editUserInfo,setEditUserInfo] = useState({});
  const [otherData,setOtherData] = useState({});
  const [loading,setLoading] = useState(true);

  const [newPassword,setNewPassword] = useState('');
  const [resetPW,setResetPW] = useState(false);

  const handlePWClose = () => {
    setResetPW(false);
  }

  const editChange = (e) =>{
    if(e){
      const result = e.map(row => row.value).join()
      setEditUserInfo({...editUserInfo,defaultEdit: result});
    } else{
      setEditUserInfo({...editUserInfo,defaultEdit: ''});
    }
  }
  const ownerChange = (e) =>{
    if(e){
      const result = e.map(row => row.value).join()
      setEditUserInfo({...editUserInfo,defaultOwner: result});
    } else{
      setEditUserInfo({...editUserInfo,defaultOwner: ''});
    }
  }
  const tradeChange = (e) =>{
    if(e){
      const result = e.map(row => row.value).join()
      setEditUserInfo({...editUserInfo,defaultTrade: result});
    } else{
      setEditUserInfo({...editUserInfo,defaultTrade: ''});
    }
  }

  const roleChange = (e) =>{
    if(e){
      const result = e.map(row => row.value).join()
      setEditUserInfo({...editUserInfo,roles: result});
    } else{
      setEditUserInfo({...editUserInfo,roles: ''});
    }
  }

  const coreGroupChange = (e) =>{
    if(e){
      const result = e.value
      setEditUserInfo({...editUserInfo,core_group: result});
    } else{
      setEditUserInfo({...editUserInfo,core_group: ''});
    }
  }

  const resetPassword = (e) => {
    axios.post('https://backend.tenet-wealth.com/v1/resetPassword',{username:editUserInfo.username,password:newPassword},{withCredentials: true}).then(res => {
      if(res.data['success']){
        notification['success']({
          message: 'Password Reset',
          description: `Password has been successfully changed!`
        })
        setResetPW(false);
      }else if (res.data['error']) {
        notification['error']({
          message:'Error',
          description: res.data['error']
        })
      }else{
        notification['error']({
          message:'Error',
          description: 'Unkown error. Contact administrator'
        })

      }
    }).catch( e =>{
      notification['error']({
        message: 'Error',
        description: `Error: ${e}`
      })
    })
  }

  const submitEdit = (e) => {
    axios.post('https://backend.tenet-wealth.com/v1/editUser',editUserInfo,{withCredentials: true}).then(res => {
      if(res.data['success']){
        notification['success']({
          message: 'User Updated',
          description: `User updated successfully!`
        })

      }else if (res.data['error']) {
        notification['error']({
          message:'Edit User Error',
          description: res.data['error']
        })

      }else{
        notification['error']({
          message:'Edit User Error',
          description: 'Unkown error. Contact administrator'
        })

      }
    }).catch( e =>{
      notification['error']({
        message: 'Edit user failed',
        description: `Error: ${e}`
      })
    })

  }

  useEffect(()=>{
    axios.get('https://backend.tenet-wealth.com/v1/editUserInfo/'+props.username,{withCredentials: true}).then(res =>{
      setEditUserInfo(res.data.data);
      setOtherData(res.data);

    })
  },[])


  if(Object.keys(otherData) < 7){
    return(
      <p>Loading Data</p>
    )
  }
  return(
    <div>
      <p>{props.username}</p>
      {!otherData.cantEdit.includes('roles') &&
      <div className= 'mb-3'>
        <Form.Label>User Roles</Form.Label>
        <Select
          isMulti
          defaultValue = {editUserInfo.roles.split(',').map(row => ({value:row, label:row}))}
          placeholder = "Roles"
          options = {otherData.pickRoles.map( row => ({value:row, label: row}))}
          onChange = {roleChange}
        />
      </div>
    }
    {!otherData.cantEdit.includes('core_group') &&
      <div className = 'mb-3'>
      <Form.Label>Core Group</Form.Label>
        <Select
          defaultValue = {{value: editUserInfo.core_group, label: editUserInfo.core_group}}
          options = {otherData.pickCoreGroups.map( row => ({value: row, label: row}))}
          isSearchable = {false}
          onChange = {coreGroupChange}
        />
      </div>
    }
    {!otherData.cantEdit.includes('mobile') &&
      <div className='mb-3'>
        <Form.Label>Mobile Number</Form.Label>
        <Form.Control type="number" placeholder="mobile number" value={editUserInfo.mobile} onChange={e =>setEditUserInfo({...editUserInfo,mobile:e.target.value})} />
      </div>
    }
    {!otherData.cantEdit.includes('emailAddress') &&
      <div className='mb-3'>
        <Form.Label>Email Address</Form.Label>
        <Form.Control type="email" placeholder="email" value={editUserInfo.emailAddress} onChange={e =>setEditUserInfo({...editUserInfo,emailAddress:e.target.value})} />
      </div>
    }
    {!otherData.cantEdit.includes('ccEmailAddresses') &&
      <div className='mb-3'>
        <Form.Label>CC Email Address</Form.Label>
        <Form.Control type="email" placeholder="CC email" value={editUserInfo.ccEmailAddresses} onChange={e =>setEditUserInfo({...editUserInfo,ccEmailAddresses:e.target.value})} />
      </div>
    }
    {!otherData.cantEdit.includes('defaultEdit') &&
      <div className='mb-3'>
        <Form.Label>Default Edit List</Form.Label>
        <Select
          isMulti
          defaultValue={editUserInfo.defaultEdit.split(',').map(row => ({value:row, label:row}))}
          placeholder="Default Edit List"
          options={otherData.defaultEditList.map(row => ({value:row, label:row}))}
          onChange={editChange}
          isClearable
        />
      </div>
    }
    {!otherData.cantEdit.includes('defaultOwner') &&
      <div className='mb-3'>
        <Form.Label>Default Owner List</Form.Label>
        <Select
          isMulti
          defaultValue={editUserInfo.defaultOwner.split(',').map(row => ({value:row, label:row}))}
          placeholder="Default Owner List"
          options={otherData.defaultOwnerList.map(row => ({value:row, label:row}))}
          onChange={ownerChange}
          isClearable
        />
      </div>
    }
    {!otherData.cantEdit.includes('defaultTrade') &&
      <div className='mb-3'>
        <Form.Label>Default Trade List</Form.Label>
        <Select
          isMulti
          defaultValue={editUserInfo.defaultTrade.split(',').map(row => ({value:row, label:row}))}
          placeholder="Default Trade List"
          options={otherData.defaultTradeList.map(row => ({value:row, label:row}))}
          onChange={tradeChange}
          isClearable
        />
      </div>
    }
      {window.localStorage.admin =="true" &&
        <div className='mb-3'>
          <div className='mb-3'>
            <Form.Label>TD Block Account #</Form.Label>
            <Form.Control type="number" placeholder="Account number" value={editUserInfo.tdBlockAccount} onChange={e =>setEditUserInfo({...editUserInfo,tdBlockAccount:e.target.value})} />
          </div>
          <div className='mb-3'>
            <Form.Label>Fidelity Block Account #</Form.Label>
            <Form.Control type="number" placeholder="Account number" value={editUserInfo.fidelityBlockAccount} onChange={e =>setEditUserInfo({...editUserInfo,fidelityBlockAccount:e.target.value})} />
          </div>
          <div className='mb-3'>
            <Form.Label>Schwab Block Account #</Form.Label>
            <Form.Control type="number" placeholder="Account number" value={editUserInfo.schwabBlockAccount} onChange={e =>setEditUserInfo({...editUserInfo,schwabBlockAccount:e.target.value})} />
          </div>
          <div className='mb-3'>
            <label>
              <input type="checkbox"
                className='mr-2 ml-2'
                defaultChecked={editUserInfo.tdBlockOptions}
                onChange={() => setEditUserInfo({...editUserInfo,tdBlockOptions:!editUserInfo.tdBlockOptions})}
              />
              TD Block Options Privelege
            </label>
          </div>
          <div className='mb-3'>
            <label>
              <input type="checkbox"
                className='mr-2 ml-2'
                defaultChecked={editUserInfo.fidelityBlockOptions}
                onChange={() => setEditUserInfo({...editUserInfo,fidelityBlockOptions:!editUserInfo.fidelityBlockOptions})}
              />
              Fidelity Block Options Privelege
            </label>
          </div>
          <div className='mb-3'>
            <label>
              <input type="checkbox"
                className='mr-2 ml-2'
                defaultChecked={editUserInfo.schwabBlockOptions}
                onChange={() => setEditUserInfo({...editUserInfo,schwabBlockOptions:!editUserInfo.schwabBlockOptions})}
              />
              Schwab Block Options Privelege
            </label>
          </div>
          <div className='mb-3'>
            <Button variant="danger" onClick={() => setResetPW(true)}>Reset Password</Button>
          </div>
        </div>
        }
          <div className='mb-3'>
            <Button variant="primary" onClick = {submitEdit} className="mb-3">Save Changes</Button>
          </div>

          <Modal visible={resetPW} title="Reset Password" onCancel={handlePWClose} okButtonProps={{style: {display:'none'}}}>
            <div className='m-5'>
              <Form.Label>New Password</Form.Label>
              <Form.Control className='mb-5' type="text" placeholder="password" value={newPassword} onChange={e =>setNewPassword(e.target.value)} />
              <br/>
              <Button onClick={resetPassword}>Submit Password Reset</Button>

            </div>
          </Modal>

    </div>
  )


}

import React, {useState} from 'react';
import { Form, Button, Toast } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import axios from 'axios'
import { useHistory } from "react-router-dom";
import {Modal} from 'antd';




const Login = () => {

  let history = useHistory();
  const [toast, setToast] = useState(false);
  const [verifyCode,setVerifyCode] = useState('');
  const [verifyModal,setVerifyModal] = useState(false);
  const [pw,setPw] = useState('');

  const sendLogin = (event) => {
    event.preventDefault();
    window.localStorage.clear();
    const pw = event.target[1].value

    const payload = {
      username:event.target[0].value,
      password:pw
    }
    return axios.post('https://backend.tenet-wealth.com/login',payload,{withCredentials: true}).then(response => {

      if (response.data.login) {
          window.localStorage.setItem('user',response.data.user)
          window.localStorage.setItem('admin',response.data.admin)
          setPw(pw)
          setVerifyModal(true);
      }

    }).catch((err) => {
      //Pop up bad login request
      setToast(true);
    })
  }

  const verify2fa = () => {
    const payload = {
      code: verifyCode,
      user: window.localStorage.getItem('user'),
      password: pw
    };
    return axios.post('https://backend.tenet-wealth.com/verify2fa',payload,{withCredentials: true}).then(response => {
      if (response.data.login) {
          history.push("/dashboard");
      }
    })
    .catch((err) => {
      //Pop up bad login request
      setToast(true);
    })

  }

  return(
    <div className="container">
    <Card bg='light' className="text-center shadow-lg mb-5 mt-5">
      <Card.Header>
        <Card.Title  style={{"fontWeight":"bold","fontSize":"200%"}}>Login to TWM Dashboard</Card.Title>
        <h6>For Advisor Use Only</h6>
      </Card.Header>
    <br/>
    <Card.Body>
        <Form onSubmit={e => sendLogin(e)}>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Username</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" required placeholder="Username" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Password</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg " required type="password" placeholder="Password" />
          </div>

          <Card.Footer>
            <Button variant="outline-primary" type="submit">
              Login
            </Button>
          </Card.Footer>
        </Form>


    </Card.Body>

    </Card>
      <Modal title="2-Factor Authentification" visible={verifyModal} onCancel={() => setVerifyModal(false)} okButtonProps={{style: {display:'none'}}} cancelButtonProps={{ style: { display: 'none' } }}>
        <div>
          <Form.Label className="mt-2">Verification Code</Form.Label>
          <Form.Control className="mt-2" required placeholder="6 digit code" onChange={e => setVerifyCode(e.target.value)}/>
          <Button className= "mt-2" variant="outline-primary" type="submit" onClick={() => verify2fa()}>
            Verify
          </Button>

        </div>


      </Modal>


      <Toast onClose={() => setToast(false)} show={toast} delay={5000} autohide  style={{
        position: 'relative',
        top: 25,
        right: 10,
        background: 'grey'
      }}>
        <Toast.Header>
          <strong className="mr-auto">Login Failed</strong>
          <small>1s ago</small>
        </Toast.Header>
        <Toast.Body>Please Rety</Toast.Body>
      </Toast>
    </div>

  )


}

export default Login;

import React,{useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Button, Row, Col } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import AccountOverview from "./AccountOverview.js"
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import Select from 'react-select';
import { uuid } from 'uuidv4';

import { Layout, Menu, Modal, Table} from 'antd';


const { Header, Content, Footer } = Layout;

export default function Accounts() {
  let history = useHistory();

  const [acctList,setAcctList] = useState([]);
  const [filterAcct,setFilterAcct] = useState([]);
  const [selectList,setSelectList] = useState([]);
  const [acct,setAcct] = useState('');
  const [acctMv,setAcctMv] = useState('');
  const [acctDetail,setAcctDetail] = useState(false);
  const [model,setModel] = useState('');
  const [hh, setHH] = useState('');
  const [modelList,setModelList] = useState([]);
  const [hhList,setHHList] = useState([]);


  const handleClose = () => {
    setAcct('');
    setAcctDetail(false);
  }

  useEffect(() => {
    axios.get('https://backend.tenet-wealth.com/v1/accounts',{withCredentials: true}).then(res => {
      setAcctList(res.data);
      setFilterAcct(res.data);
      setSelectList(res.data.map( x => ({'value': x['acct'], 'label':x['acctnm']+ ' : '+x['acctr']+' : '+x['acct']})));
      const models = [...new Set(res.data.map(row => row.model))];
      const hhs = [...new Set(res.data.map(row => row.hh))];
      setModelList(models.map( x => ({'value': x, 'label':x})));
      setHHList(hhs.map(x => ({'value':x, 'label':x})));

    }).catch(e => {
			// check error needed
			// 400 error: redirect
      if(e.response.status ==401){
        history.push("/login");
      };
      console.log(e)
			// 500 error: show feedback
		});
  },[])

  const acctSelectChange = (e) =>{
    if(e){
      setAcct(e.value);
      setAcctMv(acctList.find(row => row.acct==e.value).acctmv)
      setAcctDetail(true);
    } else{
      setAcct('');
    }
  }

  const modelSelectChange = (e) =>{
    console.log(filterAcct)
    if(e){
      setModel(e.value);
      setFilterAcct(filterAcct.filter(row => row.model == e.value));

    } else{
      setModel('')
      if(hh.length >0){
        setFilterAcct(acctList.filter(row => row.hh == hh));
      }else{
        setFilterAcct(acctList);
      }
    }
  }

  const hhSelectChange = (e) =>{
    if(e){
      setHH(e.value);
      setFilterAcct(filterAcct.filter(row => row.hh == e.value));

    } else{
      setHH('')
      if(model.length >0){
        setFilterAcct(acctList.filter(row => row.model == model));
      }else{
        setFilterAcct(acctList);
      }
    }
  }



  const showDetail = (acct,mv) => {
    setAcct(acct);
    setAcctMv(mv);
    setAcctDetail(true);
  }

  const options = {


    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE
  }

  const acctCols = [
    {
      title: 'Account',
      dataIndex: 'acct',
      key: 'acct',
      sorter: (a,b) => a.acct -b.acct
    },
    {
      title: 'Name',
      dataIndex: 'acctnm',
      key: 'acctnm',
      sorter: (a,b) => a.acctnm.localeCompare(b.acctnm),
    },
    {
      title: 'Market Value',
      dataIndex: 'acctmv',
      key: 'acctmv',
      sorter: (a,b) => a.acctmv -b.acctmv
    },
    {
      title: 'Household',
      dataIndex: 'hh',
      key: 'hh',
      sorter: (a,b) => a.hh.localeCompare(b.hh),
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      sorter: (a,b) => a.model.localeCompare(b.model),
    },
    {
      title: 'Details',
      dataIndex: 'acct',
      key: 'acct',
      render: (text,record) =>(
        <>
          <Button variant="success"  onClick={e => showDetail(record.acct,record.acctmv)}>Details</Button>
        </>
      ),

    }

  ]

  return(
    <AlertProvider template={AlertTemplate} {...options}>

    <Layout className="layout">
      <Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1">
            Accounts
          </Menu.Item>
          <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
            Home
          </Menu.Item>
          <Menu.Item key="3" onClick={() => axios.get('https://backend.tenet-wealth.com/refreshQuotes')} >
            Refresh Quotes
          </Menu.Item>

        </Menu>
      </Header>
    <Content style={{ padding: '20px 50px' }}>
    <div className='container-fluid' style={{background: "#fff", padding:'20px'}}>
      <Row className='ml-3'>
        <Col lg={3} md={6}>
          <Select
            className="mt-3 mb-3"
            placeholder="Account Select"
            value = {selectList.filter( row => row.value==acct)}
            options = {selectList}
            onChange={acctSelectChange}
            isClearable
          />
        </Col>
      </Row>
      <Row className='ml-3'>
          <Col lg={3} md={6}>
          <label>Model Filter</label>
            <Select
              className="mt-3 mb-3"
              placeholder="Model Filter"
              value = {{'value': model, 'label': model}}
              options = {modelList}
              onChange={modelSelectChange}
              isClearable
            />
          </Col>
          <Col lg={3} md={6}>
            <label>Household Filter</label>
            <Select
              className="mt-3 mb-3"
              placeholder="Household Filter"
              value = {{'value': hh, 'label': hh}}
              options = {hhList}
              onChange={hhSelectChange}
              isClearable
            />
          </Col>
      </Row>
      {acctList.length>0 ? <Row>
        <Table className='m-3' dataSource={filterAcct} columns={acctCols} bordered={true} pagination={false}  scroll={{ y: '50vh' }}/>
        <div className='ml-5'>
          <p>{filterAcct.length} Accounts</p>
        </div>
      </Row> :
      <div className="d-flex justify-content-center align-items-center mt-5">
         <ReactLoading type="spin" color="navy" className='mt-5 mb-5' />
       </div>}
       </div>
       </Content>

      {/*      <Modal show={acctDetail} onHide={handleClose} size='lg'>
              <Modal.Header closeButton>
                <Modal.Title>Details</Modal.Title>
              </Modal.Header>
              <AccountOverview acct={acct} mv={acctMv}/>
            </Modal>*/}
      <Modal title="Account Details" width="80%" visible={acctDetail} onCancel={handleClose} okButtonProps={{style: {display:'none'}}}>
        <AccountOverview acct={acct} mv={acctMv} key={uuid()} />
      </Modal>
    </Layout>
    </AlertProvider>
  )
}

import React,{useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {Form, Button, Row, Col, Toast } from 'react-bootstrap';
import { Layout, Menu, Table, Tabs, Statistic, notification, Popconfirm, Modal} from 'antd';
import ReactLoading from "react-loading";

import Select from 'react-select';

const { Header, Content, Footer } = Layout;
const {TabPane} = Tabs;


export default function PendedTrades(){
  let history = useHistory();
  const [acctDetail,setAcctDetail] = useState([])
  const [csdnDetail,setCsdnDetail] = useState([])
  const [acctTradeDetail,setAcctTradeDetail] = useState([])
  const [csdnTradeDetail,setCsdnTradeDetail] = useState([])
  const [detailAccount,setDetailAccount] = useState("")
  const [detailName,setDetailName] = useState("")
  const [detailR,setDetailR] = useState("")
  const [detailCsdn,setDetailCsdn] = useState("")
  const [detailType,setDetailType] = useState("")
  const [detailSym,setDetailSym] = useState("")
  const [detailCsdnCsdn,setDetailCsdnCsdn] = useState("")
  const [detailModal,setDetailModal] = useState(false)
  const [createAllocations,setCreateAllocations] = useState(true)
  const [selectedRowKeys,setSelectedRowKeys] = useState([]);
  const [loading,setLoading] = useState(false);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }


  const getPendedSummary = () => {
    axios.get('https://backend.tenet-wealth.com/v1/pendedTradesSummary',{withCredentials: true}).then(res=>{
      var acctData = res.data['by_acct']
      acctData.map( (row,index) => row.key = row.Account)
      setAcctDetail(acctData)
      setCsdnDetail(res.data['by_csdn'])

    })
  }



  useEffect( () => {
    getPendedSummary()
  },[])

  function sendTrades(){
    console.log(selectedRowKeys)
    const output = {'createAllocations': createAllocations, 'accts': selectedRowKeys.join()}
    setLoading(true);
    axios.post('https://backend.tenet-wealth.com/v1/sendPendedTrades',output,{withCredentials: true}).then(res => {
      console.log(res.data);
      setLoading(false);
      notification['success']({
        message: 'Trades Sent',
        description: 'Trades Files will now download'
      });
      getPendedSummary();
      res.data.forEach(file => axios.get('https://backend.tenet-wealth.com/v1/downloadTradeFile/'+file,{withCredentials: true}).then(res=>{
        var url = window.URL.createObjectURL(new Blob([res.data]));
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }))
    }).catch( e =>{
      notification['error']({
        message: 'Error',
        description: e,
      });
      setLoading(false);
    });

  }

  function deleteTrades(){
    console.log(selectedRowKeys.join())
    const output = {accts: selectedRowKeys.join()}
    setLoading(true);
    axios.post('https://backend.tenet-wealth.com/v1/deletePendedTrades',output,{withCredentials: true}).then(res => {
      console.log(res.data);
      setLoading(false);
      getPendedSummary();
      notification['success']({
        message: 'Trades Deleted',
        description: 'Selected trades were deleted'
      });

    }).catch( e =>{
      notification['error']({
        message: 'Error',
        description: e,
      });
      setLoading(false);
    });

  }

  const acctColumns = [
    {
      title: 'Account',
      dataIndex: 'Account',
      key: 'Account',
      sorter: (a, b) => a.Account.toString().localeCompare(b.Account.toString())

    },
    {
      title: 'Account Name',
      dataIndex: 'Account Name',
      key: 'Account Name',
      sorter: (a, b) => a['Account Name'].localeCompare(b['Account Name'])

    },
    {
      title:'Registration',
      dataIndex: 'Registration',
      key: 'Registration'
    },
    {
      title: 'Model',
      dataIndex: 'Model',
      key: 'Model',
    },
    {
      title: 'Account Value',
      dataIndex: 'Account Value',
      key: 'Account Value',
      sorter: (a, b) => a['Account Value'] - b['Account Value']

    },
    {
      title: 'Starting Cash',
      dataIndex: 'Starting Cash',
      key: 'Starting Cash',
    },
    {
      title: 'Sells',
      dataIndex: 'Sells',
      key: 'Sells',
    },
    {
      title: 'Gain',
      dataIndex: 'Gain',
      key: 'Gain',
      sorter: (a, b) => a.Gain - b.Gain

    },
    {
      title: 'Buys',
      dataIndex: 'Buys',
      key: 'Buys',
    },
    {
      title: 'Ending Cash',
      dataIndex: 'Ending Cash',
      key: 'Ending Cash',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a['Ending Cash'] - b['Ending Cash']

    },
    {
      title:'Details',
      key: 'details',
      render: (text,record) =>(
       <Button type="primary" onClick={e => acctTradeDetails(record.Account)}>Details</Button>
      ),
    }
  ]

const csdnColumns = [
  {
    title: 'Custodian',
    dataIndex: 'csdn',
    key: 'csdn',

  },
  {
    title: 'Symbol',
    dataIndex: 'sym',
    key: 'sym',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.sym.toString().localeCompare(b.sym.toString())

  },
  {
    title: 'Last',
    dataIndex: 'Last',
    key: 'Last',
  },
  {
    title: 'Sell Proceeds',
    dataIndex: 'Sell Proceeds',
    key: 'Sell Proceeds',
    sorter: (a, b) => a['Sell Proceeds'] - b['Sell Proceeds']

  },
  {
    title: 'Sell Quantity',
    dataIndex: 'Sell Quantity',
    key: 'Sell Quantity',
  },
  {
    title: 'Gains',
    dataIndex: 'Gains',
    key: 'Gains',
    sorter: (a, b) => a['Gains'] - b['Gains']

  },
  {
    title: 'Buy Money',
    dataIndex: 'Buy Money',
    key: 'Buy Money',
    sorter: (a, b) => a['Buy Money'] - b['Buy Money']

  },
  {
    title: 'Buy Quantity',
    dataIndex: 'Buy Quantity',
    key: 'Buy Quantity',
  },
  {
    title:'Details',
    key: 'details',
    render: (text,record) =>(
     <Button type="primary" onClick={e => csdnTradeDetails(record.csdn,record.sym)}>Details</Button>
    ),
  }

  ]

  const acctTradeColumns = [
    {
      title: 'Symbol',
      dataIndex: 'sym',
      key: 'sym',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a['sym'].toString().localeCompare(b['sym'].toString())
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',

    },
    {
      title: 'Change Quantity',
      dataIndex: 'chgQ',
      key: 'chgQ',
      sorter: (a, b) => a['chgQ'] - b['chgQ']


    },
    {
      title: 'Gain',
      dataIndex: 'gain',
      key: 'gain',
      sorter: (a, b) => a['gain'] - b['gain']

    },
    {
      title: 'Money',
      dataIndex: 'money',
      key: 'money',
      sorter: (a,b) => a.money - b.money
    },
  ]

  const csdnTradeColumns = [
    {
      title: 'Account',
      dataIndex: 'acct',
      key: 'acct',
      sorter: (a, b) => a['acct'].toString().localeCompare(b['acct'].toString())

    },
    {
      title: 'Account Name',
      dataIndex: 'acctnm',
      key: 'acctnm',
      sorter: (a, b) => a['acctnm'].localeCompare(b['acctnm'])

    },
    {
      title: 'Account Registration',
      dataIndex: 'acctr',
      key: 'acctr'
    },
    {
      title: 'Account Type',
      dataIndex: 'accttype',
      key: 'accttype'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action'
    },
    {
      title: 'Change Quantity',
      dataIndex: 'chgQ',
      key: 'chgQ',
      sorter: (a, b) => a['chgQ'] - b['chgQ']

    },
    {
      title: 'Gain',
      dataIndex: 'gain',
      key: 'gain',
      sorter: (a, b) => a['gain'] - b['gain']
    },
    {
      title: 'Money',
      dataIndex: 'money',
      key: 'money',
      sorter: (a, b) => a['money'] - b['money']

    },
  ]


  function acctTradeDetails(acct){
    setDetailModal(true)
    axios.get('https://backend.tenet-wealth.com/v1/pendedTradesDetails',{withCredentials: true, params:{acct: acct}}).then(res=>{
      setAcctTradeDetail(res.data)
      const detailData = res.data[0];
      if(detailData){
        setDetailAccount(detailData['acct'])
        setDetailR(detailData['acctr'])
        setDetailType(detailData['accttype'])
        setDetailCsdn(detailData['csdn'])
        setDetailName(detailData['acctnm'])
      } else{
        setDetailModal(false)
        notification['error']({
          message: 'Error',
          description: 'Could not get trade details',
        });
      }
    })
  }

  function csdnTradeDetails(csdn,sym){
    setDetailModal(true)
    axios.get('https://backend.tenet-wealth.com/v1/pendedTradesDetails',{withCredentials: true, params:{csdn: csdn, sym: sym}}).then(res=>{
      setCsdnTradeDetail(res.data)
      setDetailCsdnCsdn(csdn)
      setDetailSym(sym)
    })
  }
  function closeDetail(){
    setDetailModal(false)
    setAcctTradeDetail([])
    setCsdnTradeDetail([])
    setDetailAccount("")
    setDetailR("")
    setDetailType("")
    setDetailCsdn("")
    setDetailName("")
    setDetailSym("")
    setDetailCsdnCsdn("")

  }



  return(
    <Layout className="layout">
    <Header>
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
        <Menu.Item key="1">
          Pended Trades
        </Menu.Item>
        <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
          Home
        </Menu.Item>

      </Menu>
    </Header>
    <Content style={{ padding: '20px 50px' }}>
      <div style={{background: "#fff", padding:'20px'}}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Account View" key="1">
            <Table pagination={false} rowSelection = {rowSelection}  scroll={{ y: '50vh' }} columns ={acctColumns} dataSource={acctDetail}/>
          </TabPane>
          <TabPane tab="Custodian View" key="2">
            <Table pagination={false}  scroll={{ y: '50vh' }}columns = {csdnColumns} dataSource={csdnDetail}/>
          </TabPane>
        </Tabs>
        <Row>
          <Form.Check
           type="switch"
           id="createAllocations"
           label="Create Allocations"
           defaultChecked={createAllocations}
           onChange={e => setCreateAllocations(e.target.checked)}
          />
        </Row>
        <Row>
          <Popconfirm title="Confirm Send Trades?" onConfirm={() => sendTrades()}>
            <Button className='mr-3' variant='primary'>Send Selected Trades</Button>
          </Popconfirm>
          <Popconfirm title="Confirm Delete Trades?" onConfirm={() => deleteTrades()}>
            <Button className='mr-3' variant='danger'>Delete Selected Trades</Button>
          </Popconfirm>

        </Row>

      </div>

      <Modal title="loading" visible={loading} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{style: {display:'none'}}}>
        <ReactLoading type="bars" color="navy" />
      </Modal>


      <Modal title="Trade Details" visible={detailModal} onCancel={()=>closeDetail()} width={1500} okButtonProps={{style: {display:'none'}}} >
        {acctTradeDetail.length >0 ?
          <div>
            <Row className='mb-3'>
              <Col xs={6}>
                <Statistic title="Account Number" value={detailAccount} formatter={(value) =>value}/>
              </Col>
              <Col xs={6}>
                <Statistic title="Account Name" value={detailName} formatter={(value) =>value}/>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={4}>
                <Statistic title="Account Registration" value={detailR} formatter={(value) =>value}/>
              </Col>
              <Col xs={4}>
                <Statistic title="Account Type" value={detailType} formatter={(value) =>value}/>
              </Col>
              <Col xs={4}>
                <Statistic title="Custodian" value={detailCsdn} formatter={(value) =>value}/>
              </Col>

            </Row>
            <Table pagination={false} scroll={{ y: '50vh' }} columns={acctTradeColumns} dataSource={acctTradeDetail} />
          </div>
        : csdnTradeDetail.length > 0 ?
            <div>
              <Row className='mb-3'>
                <Col xs={6}>
                  <Statistic title="Custodian" value={detailCsdnCsdn} formatter={(value) =>value}/>
                </Col>
                <Col xs={6}>
                  <Statistic title="Symbol" value={detailSym} formatter={(value) =>value}/>
                </Col>
              </Row>
              <Table pagination={false} scroll={{ y: '50vh' }} columns={csdnTradeColumns} dataSource={csdnTradeDetail} />
            </div>
          : <ReactLoading type="bars" color="navy" />
        }
      </Modal>


    </Content>
    </Layout>
  )
}

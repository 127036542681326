import React,{useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {Form, Button, Row, Col, Toast } from 'react-bootstrap';
import { Layout, Menu, Table, notification, Tabs, Statistic} from 'antd';
import './dashboard.css';
import ReactLoading from "react-loading";
import InputGroup from 'react-bootstrap/InputGroup'
import { Modal as AntModal } from "antd";


import Select from 'react-select';

const { Header, Content, Footer } = Layout;
const {TabPane} = Tabs;

export default function CashManagement(){
  let history = useHistory();
  const [tfNum,setTfNum] = useState(30);
  const [tf,setTf] = useState('day');
  const [cashComp,setCashComp] = useState('');
  const [cashPct,setCashPct] = useState(0);
  const [dpAmt,setDpAmt] = useState(0);
  const [dpComp,setDpComp] = useState('');
  const [netDollarChangeComp,setNetDollarChangeComp] = useState('')
  const [netDollarChange,setNetDollarChange] = useState(0);
  const [netPctChange,setNetPctChange] = useState(0);
  const [netPctChangeComp,setNetPctChangeComp] =useState('');
  const [cashTable,setCashTable] = useState([]);
  const [filterCashTable,setFilterCashTable] = useState([]);
  const [selectedRowKeys,setSelectedRowKeys] = useState([]);
  const [loading,setLoading] = useState(false);

  const [restrictionModal,setRestrictionModal] = useState(false)
  const [restrictionAcct,setRestrictionAcct] = useState('')
  const [addResLoading,setAddResLoading] = useState(false)

  const [pendingModal,setPendingModal] = useState(false)
  const [errorModal,setErrorModal] = useState(false)
  const [finishedModal,setFinishedModal] = useState(false)
  const [detailModal,setDetailModal] = useState(false)
  const [errorDetails,setErrorDetails] = useState([])
  const [createAllocations,setCreateAllocations] = useState(true)
  const [tradeDetails,setTradeDetails] = useState([])
  const [acctDetail,setAcctDetail] = useState([])
  const [csdnDetail,setCsdnDetail] = useState([])
  const [acctTradeDetail,setAcctTradeDetail] = useState([])
  const [csdnTradeDetail,setCsdnTradeDetail] = useState([])

  const [detailAccount,setDetailAccount] = useState("")
  const [detailName,setDetailName] = useState("")
  const [detailR,setDetailR] = useState("")
  const [detailCsdn,setDetailCsdn] = useState("")
  const [detailType,setDetailType] = useState("")
  const [detailSym,setDetailSym] = useState("")
  const [detailCsdnCsdn,setDetailCsdnCsdn] = useState("")

  const [name,setName] = useState('')
  const [type,setType] = useState('mincash')
  const [qty,setQty] = useState('');
  const [expDate,setExpDate] = useState('');
  const [n,setN] = useState('')
  const [tp,setTp] = useState('');
  const [notes,setNotes] = useState('');

  const [tradeModal,setTradeModal] = useState(false);
  const [mincash,setMincash] = useState(.005);
  const [tradeLoading,setTradeLoading] = useState(false);

  const [advisorList,setAdvisorList] = useState([]);
  const [advisor,setAdvisor] = useState('');
  const [sleeveList,setSleeveList] = useState([]);
  const [sleeve,setSleeve] = useState('');

  const [modelFilter,setModelFilter] = useState([]);


  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const restrictionAdd= (acct) => {
    setRestrictionAcct(acct)
    setRestrictionModal(true)

  }

  const restrictionModalClose = () => {
    setRestrictionModal(false);
    setRestrictionAcct('');
  }

  const clear = () =>{
    setTfNum(30);
    setTf('day');
    setCashComp('');
    setCashPct(0);
    setDpAmt(0);
    setDpComp('');
    setNetDollarChangeComp('');
    setNetDollarChange(0);
    setNetPctChange(0);
    setNetPctChangeComp('');
  };

  const cashSubmit = () => {
    setLoading(true)
    const params = {
      tf : tf,
      tf_num: parseFloat(tfNum),
      cash_comp: cashComp,
      cash_pct: parseFloat(cashPct),
      dp_comp: dpComp,
      dp_amt: parseFloat(dpAmt),
      net_dollar_change_comp: netDollarChangeComp,
      net_dollar_change: parseFloat(netDollarChange),
      net_pct_change_comp: netPctChangeComp,
      net_pct_change: parseFloat(netPctChange)
    };

    axios.get('https://backend.tenet-wealth.com/v1/cashdetails',{withCredentials: true, params: params}).then(res=>{
      var response = res.data
      response.map( (row,index) => row.key = row.acct)
      setCashTable(response)
      setFilterCashTable(response)
      const models = [...new Set(res.data.map(row => row.model))]
      setModelFilter(models.map(x => ({text: x, value: x})));
      const advisors = [...new Set(res.data.map(row => row.advisor))]
      setAdvisorList(advisors.map( x=> ({'value':x, 'label':x})));
      setLoading(false)
    }).catch(error => {
      setLoading(false)
      notification['error']({
        message: 'Error',
        description:
          'Problem pulling cash data. Check inputs and try again',
      });

    });

  };

  const cashCols = [
    {
      title: 'Account #',
      dataIndex: 'acct',
      key:'acct',
      sorter: (a,b) => a['acct'] - b['acct'],
    },
    {
      title: 'Name',
      dataIndex: 'acctnm',
      key:'acctnm',
      sorter: (a,b) => a['acctnm'].toString().localeCompare(b['acctnm'].toString())

    },
    {
      title: 'Registration',
      dataIndex: 'acctr',
      key:'acctr',
      sorter: (a,b) => a['acctr'].toString().localeCompare(b['acctr'].toString())

    },
    {
      title: 'Model',
      dataIndex: 'model',
      key:'model',
      filters: modelFilter,
      onFilter: (value,record) => record.model.indexOf(value) == 0,
      sorter: (a,b) => a['model'].localeCompare(b['model'])

    },
    {
      title: 'Market Value',
      dataIndex: 'acctmv',
      key:'acctmv',
      sorter: (a,b) => a['acctmv'].toString().localeCompare(b['acctmv'].toString())

    },
    {
      title: 'Cash Available',
      dataIndex: 'avail',
      key:'avail',
      sorter: (a,b) => a['avail'] - b['avail'],
      defaultSortOrder: 'descend'

    },
    {
      title: 'Percent Cash',
      dataIndex: 'cashPct',
      key:'cashPct',
      sorter: (a,b) => a['cashPct'] - b['cashPct'],
    },
    {
      title: 'Deposits',
      dataIndex: 'deposits',
      key:'deposits',
      sorter: (a,b) => a['deposits'] - b['deposits'],
    },
    {
      title: 'Net % Change',
      dataIndex: 'net_pct_chg',
      key:'net_pct_chg',
      sorter: (a,b) => a['net_pct_chg'] - b['net_pct_chg'],
    },
    {
      title: 'Net $ Change',
      dataIndex: 'net_dollar_chg',
      key:'net_dollar_chg',
      sorter: (a,b) => a['net_dollar_chg'] - b['net_dollar_chg'],
    },
    {
      title: 'Total % Change',
      dataIndex:'total_pct_chg',
      key:'total_pct_chg',
      sorter: (a,b) => a['total_pct_chg'] - b['total_pct_chg'],
    },
    {
      title: 'Restriction',
      dataIndex: 'acct',
      key:'acct',
      render: (text,record) =>(
        <Button variant="danger" onClick={(e)=>restrictionAdd(record.acct)}>Restrict</Button>
      ),
    },

  ]

  const renderRestrictionModal = (type) => {
    if(type=='mincash'){
      return(
        <>
          <div>
            <label>Minimum Amount</label>
            <input className="form-control" name='qty' type='number' value={qty} onChange={e =>setQty(e.target.value)}/>
          </div>
          <div>
            <label className='mt-2'>Expiration Date</label>
            <input className="form-control" name="expdt" value={expDate} onChange={e =>setExpDate(e.target.value)} type="date"/>
          </div>
          <div>
            <label  className='mt-2'>Notes</label>
            <input className="form-control" name="notes" value={notes} onChange={e =>setNotes(e.target.value)} autoComplete="off"/>
          </div>
          <div className='mt-3'>
            <Button type="primary" onClick={(e)=>sendRestriction()}>Submit Restriction</Button>
          </div>
        </>
      )
    }else if (type=='dca') {
      return(
        <>
        <div>
          <label>Amount</label>
          <input className="form-control" name='qty' type='number' value={qty} onChange={e =>setQty(e.target.value)}/>
        </div>

        <div>
          <label>Number of Installments</label>
          <input className="form-control" name='n' type='number' value={n} onChange={e =>setN(e.target.value)}/>
        </div>
        <div>
          <label>Time Period (ex. 4w, 2q, 5d)</label>
          <input className="form-control" name='tp'  value={tp} onChange={e =>setTp(e.target.value)}/>
        </div>

        <div>
          <label  className='mt-2'>Notes</label>
          <input className="form-control" name="notes" value={notes} onChange={e =>setNotes(e.target.value)} autoComplete="off"/>
        </div>
        <div className='mt-3'>
          <Button type="primary" onClick={(e)=>sendRestriction()}>Submit Restriction</Button>
        </div>

        </>
      )
    }else{
      return(
        <>
          <div>
            <label  className='mt-2'>Notes</label>
            <input className="form-control" name="notes" value={notes} onChange={e =>setNotes(e.target.value)} autoComplete="off"/>
          </div>
          <div className='mt-3'>
            <Button type="primary" onClick={(e)=>sendRestriction()}>Submit Restriction</Button>
          </div>
        </>
      )
    }

  };

  const tradeSelected = () =>{
    setTradeModal(true);
  }

  const tradeModalClose = () =>{
    setTradeModal(false);

  }

  function sendTrades(){
    setPendingModal(false);
    const output = {'createAllocations': createAllocations}
    setLoading(true);
    axios.post('https://backend.tenet-wealth.com/v1/sendPendedTrades',output,{withCredentials: true}).then(res => {
      setTradeLoading(false);
      setFinishedModal(true)
      res.data.forEach(file => axios.get('https://backend.tenet-wealth.com/v1/downloadTradeFile/'+file,{withCredentials: true}).then(res=>{
        var url = window.URL.createObjectURL(new Blob([res.data]));
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }))
    }).catch( e =>{
      notification['error']({
        message: 'Server Error',
        description: e,
      });
      setTradeLoading(false);
    });
  }

  function sendErrorTrade(override){
    setErrorModal(false);
    setLoading(true);
    if(override){
      var output = {'tradeType':'buyAndSell','globalMinCash':mincash,'accounts':selectedRowKeys.join(),'overridePended':'yes'}
    }else{
      var output = {'tradeType':'buyAndSell','globalMinCash':mincash,'accounts':selectedRowKeys.join(),'overridePended':'no'}
    }
    axios.post('https://backend.tenet-wealth.com/v1/pendTrades',output,{withCredentials: true}).then(res => {
      if(res.data['error_message']){
        notification['error']({
          message: 'Error',
          description: res.data['error_message'],
        });
        setErrorDetails(res.data['error_table'])
        setErrorModal(true)
      }else if (res.data['by_acct']) {
        notification['success']({
          message: 'Trades Calculated',
          description: 'Trades have been calculated and are pending',
        });
        setAcctDetail(res.data['by_acct'])
        setCsdnDetail(res.data['by_csdn'])
        setPendingModal(true)
      }else{
        notification['warning']({
          message: 'No Trades',
          description: 'No trades were able to be calculated',
        });
      }
      setTradeLoading(false)
    }).catch( e =>{
      notification['error']({
        message: 'Server Error',
        description: e,
      });
      setTradeLoading(false);
    });
  }

  const tradeCalc = () => {
    setTradeLoading(true);
    var output = {'tradeType':'buyAndSell','globalMinCash':mincash,'accounts':selectedRowKeys.join()}
    axios.post('https://backend.tenet-wealth.com/v1/pendTrades',output,{withCredentials: true}).then(res => {
      if(res.data['error_message']){
        notification['error']({
          message: 'Error',
          description: res.data['error_message'],
        });
        setErrorDetails(JSON.parse(res.data['error_table']))
        setErrorModal(true)
      }else if (res.data['by_acct']) {
        notification['success']({
          message: 'Trades Calculated',
          description:
            'Trades have been successfully calculated and are pending.',
        });
        setAcctDetail(res.data['by_acct'])
        setCsdnDetail(res.data['by_csdn'])
        setPendingModal(true)
      }else{
        notification['warning']({
          message: 'No Trades',
          description:
            'No calculations were made',
        });
      }
      setTradeLoading(false);
      setTradeModal(false);

    }).catch( e =>{
      notification['error']({
        message: 'Server Error',
        description: e,
      });
      setTradeLoading(false);
    });

  }

  function acctTradeDetails(acct){
    setDetailModal(true)
    axios.get('https://backend.tenet-wealth.com/v1/pendedTradesDetails',{withCredentials: true, params:{acct: acct}}).then(res=>{
      setAcctTradeDetail(res.data)
      const detailData = res.data[0];
      if(detailData){
        setDetailAccount(detailData['acct'])
        setDetailR(detailData['acctr'])
        setDetailType(detailData['accttype'])
        setDetailCsdn(detailData['csdn'])
        setDetailName(detailData['acctnm'])
      }
    })
  }

  function csdnTradeDetails(csdn,sym){
    setDetailModal(true)
    axios.get('https://backend.tenet-wealth.com/v1/pendedTradesDetails',{withCredentials: true, params:{csdn: csdn, sym: sym}}).then(res=>{
      setCsdnTradeDetail(res.data)
      setDetailCsdnCsdn(csdn)
      setDetailSym(sym)

    })
  }

  function closeDetail(){
    setDetailModal(false)
    setAcctTradeDetail([])
    setCsdnTradeDetail([])
    setDetailAccount("")
    setDetailR("")
    setDetailType("")
    setDetailCsdn("")
    setDetailName("")
    setDetailSym("")
    setDetailCsdnCsdn("")

  }

  const acctColumns = [
  {
    title: 'Account',
    dataIndex: 'Account',
    key: 'Account',
    sorter: (a, b) => a.Account.toString().localeCompare(b.Account.toString())

  },
  {
    title: 'Account Name',
    dataIndex: 'Account Name',
    key: 'Account Name',
    sorter: (a, b) => a['Account Name'].localeCompare(b['Account Name'])

  },
  {
    title:'Registration',
    dataIndex: 'Registration',
    key: 'Registration'
  },
  {
    title: 'Model',
    dataIndex: 'Model',
    key: 'Model',
  },
  {
    title: 'Account Value',
    dataIndex: 'Account Value',
    key: 'Account Value',
    sorter: (a, b) => a['Account Value'] - b['Account Value']

  },
  {
    title: 'Starting Cash',
    dataIndex: 'Starting Cash',
    key: 'Starting Cash',
  },
  {
    title: 'Sells',
    dataIndex: 'Sells',
    key: 'Sells',
  },
  {
    title: 'Gain',
    dataIndex: 'Gain',
    key: 'Gain',
    sorter: (a, b) => a.Gain - b.Gain

  },
  {
    title: 'Buys',
    dataIndex: 'Buys',
    key: 'Buys',
  },
  {
    title: 'Ending Cash',
    dataIndex: 'Ending Cash',
    key: 'Ending Cash',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a['Ending Cash'] - b['Ending Cash']

  },
  {
    title:'Details',
    key: 'details',
    render: (text,record) =>(
     <Button type="primary" onClick={e => acctTradeDetails(record.Account)}>Details</Button>
    ),
  }
]
  const errorColumns = [
    {
      title: 'Account',
      dataIndex: 'acct',
      key: 'acct',

    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',

    },
  ]

  const csdnColumns = [
  {
    title: 'Custodian',
    dataIndex: 'csdn',
    key: 'csdn',

  },
  {
    title: 'Symbol',
    dataIndex: 'sym',
    key: 'sym',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.sym.toString().localeCompare(b.sym.toString())

  },
  {
    title: 'Last',
    dataIndex: 'Last',
    key: 'Last',
  },
  {
    title: 'Sell Proceeds',
    dataIndex: 'Sell Proceeds',
    key: 'Sell Proceeds',
    sorter: (a, b) => a['Sell Proceeds'] - b['Sell Proceeds']

  },
  {
    title: 'Sell Quantity',
    dataIndex: 'Sell Quantity',
    key: 'Sell Quantity',
  },
  {
    title: 'Gains',
    dataIndex: 'Gains',
    key: 'Gains',
    sorter: (a, b) => a['Gains'] - b['Gains']

  },
  {
    title: 'Buy Money',
    dataIndex: 'Buy Money',
    key: 'Buy Money',
    sorter: (a, b) => a['Buy Money'] - b['Buy Money']

  },
  {
    title: 'Buy Quantity',
    dataIndex: 'Buy Quantity',
    key: 'Buy Quantity',
  },
  {
    title:'Details',
    key: 'details',
    render: (text,record) =>(
     <Button type="primary" onClick={e => csdnTradeDetails(record.csdn,record.sym)}>Details</Button>
    ),
  }

]

  const acctTradeColumns = [
    {
      title: 'Symbol',
      dataIndex: 'sym',
      key: 'sym',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a['sym'].toString().localeCompare(b['sym'].toString())
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',

    },
    {
      title: 'Change Quantity',
      dataIndex: 'chgQ',
      key: 'chgQ',
      sorter: (a, b) => a['chgQ'] - b['chgQ']


    },
    {
      title: 'Gain',
      dataIndex: 'gain',
      key: 'gain',
      sorter: (a, b) => a['gain'] - b['gain']

    },
    {
      title: 'Money',
      dataIndex: 'money',
      key: 'money',
      sorter: (a,b) => a.money - b.money
    },
  ]

  const csdnTradeColumns = [
    {
      title: 'Account',
      dataIndex: 'acct',
      key: 'acct',
      sorter: (a, b) => a['acct'].toString().localeCompare(b['acct'].toString())

    },
    {
      title: 'Account Name',
      dataIndex: 'acctnm',
      key: 'acctnm',
      sorter: (a, b) => a['acctnm'].localeCompare(b['acctnm'])

    },
    {
      title: 'Account Registration',
      dataIndex: 'acctr',
      key: 'acctr'
    },
    {
      title: 'Account Type',
      dataIndex: 'accttype',
      key: 'accttype'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action'
    },
    {
      title: 'Change Quantity',
      dataIndex: 'chgQ',
      key: 'chgQ',
      sorter: (a, b) => a['chgQ'] - b['chgQ']

    },
    {
      title: 'Gain',
      dataIndex: 'gain',
      key: 'gain',
      sorter: (a, b) => a['gain'] - b['gain']
    },
    {
      title: 'Money',
      dataIndex: 'money',
      key: 'money',
      sorter: (a, b) => a['money'] - b['money']

    },
  ]

  const advisorSelectChange = (e) => {
    if(e){
      setAdvisor(e.value)
      if(sleeve){
        setFilterCashTable(cashTable.filter(row =>row.advisor == e.value).filter(row => row.sleeves.includes(sleeve)));
      }else{
        setFilterCashTable(cashTable.filter(row =>row.advisor == e.value));

      }
    }
    else{
      setAdvisor('')
      if(sleeve){
        setFilterCashTable(cashTable.filter(row => row.sleeves.includes(sleeve)));
      }else{
        setFilterCashTable(cashTable);

      }
    }
  }

  const sleeveSelectChange = (e) => {
    if(e){
      setSleeve(e.value)
      if(advisor){
        setFilterCashTable(cashTable.filter(row =>row.advisor == advisor).filter(row => row.sleeves.includes(e.value)))
      }
      else{
        setFilterCashTable(cashTable.filter(row=> row.sleeves.includes(e.value)))
      }
    } else{
      setSleeve('')
      if(advisor){
        setFilterCashTable(cashTable.filter(row =>row.advisor == advisor))
      }
      else{
        setFilterCashTable(cashTable)
      }

    }
  }

  const getSleeves = () =>{
    axios.get('https://backend.tenet-wealth.com/v1/sleeves', {withCredentials: true}).then(res =>{
      const sList = JSON.parse(res.data.sleeves);
      setSleeveList(sList.map( (x,index) => ({'value': x, 'label':x,'key':index})));
    }).catch((err) => {
      console.log(err)
    })
  };

  useEffect( () => {

    getSleeves();
  },[])



  const sendRestriction= () =>{
    setAddResLoading(true);
    var output = {}
    output['name'] = name;
    output['type'] = type;
    output['account'] = restrictionAcct;
    output['scope'] = 'account';
    output['qty'] = qty;
    output['notes'] = notes;
    output['n'] = n;
    output['tp'] = tp;
    output['expdt'] = expDate;

    axios.post('https://backend.tenet-wealth.com/v1/restrictions',output,{withCredentials: true}).then(res => {
      if(res.data.error){
        notification['error']({
          message: 'Error',
          description:
            'Could not add restriction' + res.data.error,
        });
      }else{
        notification['success']({
          message: 'Success',
          description:
            'Restriction Added',
        });
        setName('')
        setQty('')
        setTp('')
        setN('')
        setNotes('')

      }
      setRestrictionModal(false);
      setAddResLoading(false);
      cashSubmit();
    }).catch( e =>{
      notification['error']({
        message: 'Error',
        description:
          'Server error occured. Check that inputs are valid and complete',
      });
      setAddResLoading(false)
    });

  }

  return(
    <Layout className="layout">
    <Header>
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
        <Menu.Item key="1">
          Cash Management
        </Menu.Item>
        <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
          Home
        </Menu.Item>
        <Menu.Item key="3" onClick={() => axios.get('https://backend.tenet-wealth.com/refreshQuotes')} >
          Refresh Quotes
        </Menu.Item>

      </Menu>
    </Header>
    <Content style={{ padding: '20px 50px' }}>
      <div style={{background: "#fff", padding:'20px'}}>
        <Row className='mb-3'>
          <Col sm={2}>
            <h6>Deposit Timeframe</h6>
          </Col>
          <Col sm={1}>
            <input style={{"width":'100%'}} className='mr-3 ml-3 my-input' type='number' value={tfNum} onChange={e => setTfNum(e.target.value)}/>
          </Col>
          <Col sm={2}>
            <Form.Control as="select" value={tf} onChange={e =>setTf(e.target.value)}>
              <option value='day'>Day(s)</option>
              <option value='week'>Week(s)</option>
              <option value='month'>Month(s)</option>
            </Form.Control>
          </Col>
        </Row>
        <Row className='mb-1'>
          <Col sm={2}>
            <h6>Deposit Amount</h6>
          </Col>
          <Col sm={1}>
            <Form.Control as='select' className='mr-3 ml-3' value={dpComp} onChange={e => setDpComp(e.target.value)} >
              <option value=''>   </option>
              <option value='>'> &gt; </option>
              <option value='<'> &lt; </option>
            </Form.Control>
          </Col>
          <Col sm={2}>
            <input className="my-input" style={{"width":'100%'}} type='number' value={dpAmt} onChange={e => setDpAmt(e.target.value)} />
          </Col>

        </Row>
        <Row>
          <Col sm={6}>
            <hr/>
          </Col>
          <Col sm={6}>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={2}>
            <h6>Percentage of Cash Available</h6>
          </Col>
          <Col sm={1}>
              <Form.Control as='select' className='mr-3 ml-3' value={cashComp} onChange={e => setCashComp(e.target.value)} >
                <option value=''>   </option>
                <option value='>'> &gt; </option>
                <option value='<'> &lt; </option>
              </Form.Control>
          </Col>
          <Col sm={2}>
            <input className="my-input" style={{"width":'100%'}} type='number' value={cashPct} onChange={e => setCashPct(e.target.value)} />
          </Col>
        </Row>

        <Row className='mb-3'>
          <Col sm={2}>
            <h6>Net Dollar Change</h6>
          </Col>
          <Col sm={1}>
            <Form.Control as="select" className='mr-3 ml-3' value={netDollarChangeComp} onChange={e => setNetDollarChangeComp(e.target.value)} >
              <option value=''>   </option>
              <option value='>'> &gt; </option>
              <option value='<'> &lt; </option>
            </Form.Control>
          </Col>
          <Col sm={2}>
            <input className="my-input" style={{"width":'100%'}} type='number' value={netDollarChange} onChange={e => setNetDollarChange(e.target.value)} />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={2}>
            <h6>Net Percent Change</h6>
          </Col>
          <Col sm={1}>
            <Form.Control as='select' className='mr-3 ml-3' value={netPctChangeComp} onChange={e => setNetPctChangeComp(e.target.value)} >
              <option value=''>   </option>
              <option value='>'> &gt; </option>
              <option value='<'> &lt; </option>
            </Form.Control>
          </Col>
          <Col sm={2}>
            <input className="my-input" style={{"width":'100%'}} type='number' value={netPctChange} onChange={e => setNetPctChange(e.target.value)} />
          </Col>
        </Row>

        <Row className='mt-3 mb-3'>
          <Button className='mr-3' variant='primary' onClick={cashSubmit}>Submit</Button>
          <Button className='mr-3' variant='danger' onClick={clear}>Reset</Button>
          <p>Number of Accounts: {cashTable.length}</p>
        </Row>

        <Row>
          <Col sm={6}>
            <hr/>
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col lg={3} md={6}>
            <label>Sleeve Filter</label>
            <Select
              className="mt-3 mb-3"
              placeholder="Sleeve Filter"
              value = {{'value': sleeve, 'label': sleeve}}
              options = {sleeveList}
              onChange={sleeveSelectChange}
              isClearable
            />
          </Col>

          {window.localStorage.getItem('admin')=="true" &&
          <Col lg={3} md={6}>
            <label>Advisor Filter</label>
            <Select
              className="mt-3 mb-3"
              placeholder="Advisor Filter"
              value = {{'value': advisor, 'label': advisor}}
              options = {advisorList}
              onChange={advisorSelectChange}
              isClearable
            />
          </Col>
        }
        </Row>

        <Row className='mt-3'>
          <Button className='mr-3' variant='primary' onClick={tradeSelected}>Trade Selected</Button>
          <p>Number of Accounts Selected: {selectedRowKeys.length}</p>
        </Row>
        <Row>
          <Table loading={tradeLoading} rowSelection = {rowSelection} dataSource={filterCashTable} columns={cashCols} pagination={{position: ['topRight'], defaultPageSize: 8, showSizeChanger: true, pageSizeOptions: ['5', '8', '10', '20']}}/>
        </Row>
      </div>
      <AntModal title={'Restriction For :' + restrictionAcct} visible={restrictionModal} onCancel={restrictionModalClose} okButtonProps={{style: {display:'none'}}}>
        { addResLoading ?
          <div className="d-flex justify-content-center align-items-center mt-5">
             <ReactLoading type="spin" color="navy" className='mt-5 mb-5' />
           </div>
         :
        <div className='m-2'>
          <div>
            <label>Restriction Name</label>
            <input className="form-control" name="name" value={name} onChange={e =>setName(e.target.value)}/>
          </div>
          <div>
            <label  className='mt-2'>Restriction Type</label>
            <select className="form-control" name="type" value={type} onChange={e => setType(e.target.value)}>
              <option value="mincash">Minimum Cash</option>
              <option value="dca">Dollar Cost Average</option>
              <option value="nondiscretionary">Nondiscretionary</option>
              <option value="donttrade">Don't Trade</option>
            </select>
          </div>
          {renderRestrictionModal(type)}
        </div> }

      </AntModal>

      <AntModal title="Trade Accounts" visible={tradeModal} onCancel={tradeModalClose} okButtonProps={{style: {display:'none'}}}>
        { tradeLoading ?
          <div className="d-flex justify-content-center align-items-center mt-5">
             <ReactLoading type="spin" color="navy" className='mt-5 mb-5' />
           </div>
         :
        <div className='m-2'>
          <Form.Label>
            Percent of Cash to Leave in Accounts (Minimum Cash Percent)
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control value ={mincash}  onChange={e =>setMincash(e.target.value)} as="input" type="number" step={.01} min={0} max={99} id='globalMinCash'  >
            </Form.Control>
            <InputGroup.Append>
              <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
            </InputGroup.Append>

          </InputGroup>

          <div className='mt-3'>
            <Button className='mr-3' variant='primary' onClick={tradeCalc}>Calculate Trades</Button>
          </div>
        </div> }

      </AntModal>

      <AntModal title="Trades Pended" maskClosable={false} visible={pendingModal} onCancel={() => setPendingModal(false)} width={2000} okButtonProps={{style: {display:'none'}}}>
          <Button type='primary' onClick={() => history.push("/pendedTrades")}>Pended Trades Page</Button>
      </AntModal>

      <AntModal title="Trades with Errors" maskClosable={false} visible={errorModal} onCancel={() => setErrorModal(false)} width={2000} okButtonProps={{style: {display:'none'}}}>
        <Table pagination={false}  scroll={{ y: '50vh' }} columns ={errorColumns} dataSource={errorDetails}/>
        <Row>
          <Button type='primary' className='mr-3' onClick={() => sendErrorTrade(true)}>Override Trades</Button>
          <Button type='primary' onClick={() => sendErrorTrade(false)}>Send Without Override</Button>
        </Row>
      </AntModal>


      <AntModal title="Trades Submited" maskClosable={false} visible={finishedModal} onCancel={() => setFinishedModal(false)} width={2000} okButtonProps={{style: {display:'none'}}}>
        <p>Trades Submitted. Trade files will download now</p>
      </AntModal>

      <AntModal title="Trade Details" visible={detailModal} onCancel={()=>closeDetail()} width={1500} okButtonProps={{style: {display:'none'}}} >
        {acctTradeDetail.length >0 ?
          <div>
            <Row className='mb-3'>
              <Col xs={6}>
                <Statistic title="Account Number" value={detailAccount} formatter={(value) =>value}/>
              </Col>
              <Col xs={6}>
                <Statistic title="Account Name" value={detailName} formatter={(value) =>value}/>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={4}>
                <Statistic title="Account Registration" value={detailR} formatter={(value) =>value}/>
              </Col>
              <Col xs={4}>
                <Statistic title="Account Type" value={detailType} formatter={(value) =>value}/>
              </Col>
              <Col xs={4}>
                <Statistic title="Custodian" value={detailCsdn} formatter={(value) =>value}/>
              </Col>

            </Row>
            <Table pagination={false} scroll={{ y: '50vh' }} columns={acctTradeColumns} dataSource={acctTradeDetail} />
          </div>
        : csdnTradeDetail.length > 0 ?
            <div>
              <Row className='mb-3'>
                <Col xs={6}>
                  <Statistic title="Custodian" value={detailCsdnCsdn} formatter={(value) =>value}/>
                </Col>
                <Col xs={6}>
                  <Statistic title="Symbol" value={detailSym} formatter={(value) =>value}/>
                </Col>
              </Row>
              <Table pagination={false} scroll={{ y: '50vh' }} columns={csdnTradeColumns} dataSource={csdnTradeDetail} />
            </div>
          : <ReactLoading type="bars" color="navy" />
        }
      </AntModal>


    </Content>
    </Layout>
  )
}

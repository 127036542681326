import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Form, Toast, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import {Table, Layout, Menu, Button, Popconfirm, Statistic, Switch, Modal} from 'antd';
import './dashboard.css';
import {VictoryPie, VictoryTooltip} from 'victory';
import {ExclamationCircleOutlined, WarningFilled, CheckSquareFilled } from '@ant-design/icons';
import ReactLoading from "react-loading";


const { Header, Content, Footer } = Layout;

const Trading = () => {
  let history = useHistory();
  const [user,setUser]= useState('');
  const [symlist,setSymlist] = useState([]);
  const [inputList, setInputList] = useState(
    [{ action:"", sym: "", notes:"", sleeveInfo:"", excludeSleeves:"",excludeSyms:""}]
  );
  const [editType,setEditType] = useState('sleeve');

  const [addSleeveHoldings,setAddSleeveHoldings] = useState([{ sym: "",wt: 0,max:"",min:"",mind:"",minq:"", notes:""}]);
  const [addSleeveName,setAddSleeveName] = useState("");
  const [addSleeveNameAvailable,setAddSleeveNameAvailable] = useState("pending")
  const [addSleeveType,setAddSleeveType] = useState("");
  const [addSleeveAC,setAddSleeveAC] = useState("");
  const [addSleeveDescription,setAddSleeveDescription] = useState("");
  const [addSleeveNotes,setAddSleeveNotes] = useState("");
  const [addSleeveMaxTol,setAddSleeveMaxTol] = useState("1.25");
  const [addSleeveMinTol,setAddSleeveMinTol] = useState(".75");
  const [addSleeveOwner,setAddSleeveOwner] = useState("");
  const [addSleeveView,setAddSleeveView] = useState("");
  const [addSleeveEdit,setAddSleeveEdit] = useState("");
  const [addSleeveTrade,setAddSleeveTrade] = useState("");

  const [editSleeveEdit,setEditSleeveEdit] = useState("");
  const [editSleeveView,setEditSleeveView] = useState("");
  const [editSleeveTrade,setEditSleeveTrade] = useState("");

  const [editModelEdit,setEditModelEdit] = useState("");
  const [editModelView,setEditModelView] = useState("");
  const [editModelTrade,setEditModelTrade] = useState("");

  const [sleevesList,setSleevesList] = useState([]);
  const [addSleevesList,setAddSleevesList] = useState([]);
  const [sleeve,setSleeve] = useState('');
  const [sleeveDetails,setSleeveDetails]  = useState([]);

  const [modelsList,setModelsList] = useState([]);
  const [selectedModel,setSelectedModel] = useState('');
  const [holdings,setHoldings] = useState([]);
  const [proposedHoldings,setProposedHoldings] = useState([]);

  const [addModelName,setAddModelName] =useState("");
  const [addModelNameAvailable,setAddModelNameAvailable] = useState("pending")
  const [addHoldings,setAddHoldings] = useState([{ sleeve:'', swt:0}]);

  const [ownerDefaults,setOwnerDefaults] = useState({});
  const [ownerList,setOwnerList] = useState([]);
  const [modelOwner,setModelOwner] = useState("");
  const [modelView,setModelView] = useState("");
  const [modelEdit,setModelEdit] = useState("");
  const [modelTrade,setModelTrade] = useState("");

  const [manualEdit,setManualEdit] = useState(false);
  const [loading,setLoading] = useState(false);

  const [symsList,setSymsList] = useState([]);
  const [symSelect,setSymSelect] = useState('');
  const [symDetails,setSymDetails] = useState([]);

  const [toast,setToast] = useState(false);
  const [toastMsg,setToastMsg] = useState({})

  const symSelectChange = (e) =>{
    if(e){
      setSymSelect(e.value);
      getSymDetails(e.value);
    } else{
      setSymSelect('');
      setSymDetails([]);
    }
  }
  const sleeveSelectChange = (e) =>{
    if(e){
      setSleeve(e.value);
      getSleeveDetails(e.value)
      getPortfolioAccess('sleeve',e.value);
    } else{
      setSleeve('');
      setSleeveDetails([]);
    }
  }
  const modelSelectChange = (e) =>{
    if(e){
      setSelectedModel(e.value);
      getModelHoldings(e.value);
      getPortfolioAccess('model',e.value)
    } else{
      setSelectedModel('');
      setHoldings([]);
      setProposedHoldings([]);
    }
  }

  const getPortfolioAccess = (type,name) => {
    const params = {type: type, name: name}

    axios.get('https://backend.tenet-wealth.com/v1/portfolioAccess',{withCredentials: true, params: params}).then(res =>{
      const response = res.data[0]
      if(type=='sleeve'){
        setEditSleeveEdit(response['edit'])
        setEditSleeveView(response['view'])
        setEditSleeveTrade(response['trade'])
      }else if(type=='model'){
        setEditModelEdit(response['edit'])
        setEditModelView(response['view'])
        setEditModelTrade(response['trade'])
      }
    })
  }

  const submitSleeveEditRights = () => {
    const params = {
      type: 'sleeve',
      name: sleeve,
      data: {view: editSleeveView, edit: editSleeveEdit, trade: editSleeveTrade}
    }
    axios.post('https://backend.tenet-wealth.com/v1/portfolioAccessEdit',params,{withCredentials: true}).then(res => {
      setToastMsg({'header':'Sleeve Access Rights Processed','body':res.data.message})
      setToast(true);
    }).catch( e =>{
      setToastMsg({'header':'ERROR','body': 'could not process. \n REASON: '+e});
      setToast(true);
    })


  }
  const submitModelEditRights = () => {
    const params = {
      type: 'model',
      name: selectedModel,
      data: {view: editModelView, edit: editModelEdit, trade: editModelTrade}
    }
    axios.post('https://backend.tenet-wealth.com/v1/portfolioAccessEdit',params,{withCredentials: true}).then(res => {
      setToastMsg({'header':'Model Access Rights Processed','body':res.data.message})
      setToast(true);
    }).catch( e =>{
      setToastMsg({'header':'ERROR','body': 'could not process. \n REASON: '+e});
      setToast(true);
    })


  }


  const authenticate = () => {
     axios.post('https://backend.tenet-wealth.com/authenticate',{'authenticate':'please'}, {withCredentials: true}).then(res =>{
       setUser(res.data.user);
     })
    .catch((err) => {
      history.push("/login");
    })
  };

  const getSleeves = () =>{
    axios.get('https://backend.tenet-wealth.com/v1/sleeves', {withCredentials: true}).then(res =>{
      const sList = JSON.parse(res.data.sleeves);
      setSleevesList(sList.map( (x,index) => ({'value': x, 'label':x,'key':index})));
    }).catch((err) => {
      if(err.response){
        if(err.response.status ==401){
        history.push("/login");
      };
    } else{console.log(err)}
    })
  };

  const getModelSleeves = (owner) => {
    axios.get('https://backend.tenet-wealth.com/v1/modelAddSleeves/'+owner, {withCredentials: true}).then(res =>{
      const sList = JSON.parse(res.data.sleeves);
      setAddSleevesList(sList.map( (x,index) => ({'value': x, 'label':x,'key':index})));
    }).catch((err) => {
      if(err.response){
        if(err.response.status ==401){
        history.push("/login");
      };
    } else{
        console.log(err)
        setAddSleevesList([])
      }
    })
  };



  const getSyms = () =>{
    axios.get('https://backend.tenet-wealth.com/v1/sleeveSymbols', {withCredentials: true}).then(res =>{
      const sList = JSON.parse(res.data.syms);
      setSymsList(sList.map( (x,index) => ({'value': x, 'label':x,'key':index})));
    })

  }

  const getSleeveDetails = (selectedSleeve) => {
    setSleeveDetails([]);
    axios.get('https://backend.tenet-wealth.com/v1/sleeveDetails/'+selectedSleeve, {withCredentials: true}).then(res =>{
      var details = JSON.parse(res.data.sleeveInfo);
      details = details.filter(x => x.sym !== 'moneyMarket')
      setSleeveDetails(details.map((row,index) => ({...row,newWt: row['wt'],key: index})).reverse());
    })
  }

  const getSymDetails = (selectedSym) => {
    setSymDetails([]);
    axios.get('https://backend.tenet-wealth.com/v1/symDetails/'+selectedSym, {withCredentials: true}).then(res =>{
      const details = JSON.parse(res.data.symDetail);
      setSymDetails(details);
    })

  }

  const getModels = () => {
    axios.get('https://backend.tenet-wealth.com/v1/models',{withCredentials: true}).then(res =>{
      setModelsList(res.data.map( x => ({'value': x, 'label':x})));
    });
  }

  const getModelHoldings = (model) => {
    axios.get('https://backend.tenet-wealth.com/v1/modelHoldings/'+model,{withCredentials: true}).then(res =>{
      if(res.data){
        setHoldings(res.data);
        setProposedHoldings( res.data.map((x) => ({'sleeve':x.sleeve,'swt':x.swt,'id':x.id}) ) );
      }else{
        setProposedHoldings([{'sleeve':"",'swt':0}]);
      }
    });
  }

  const checkModelName = () => {
    axios.get('https://backend.tenet-wealth.com/v1/modelNameAvailable/'+addModelName,{withCredentials: true}).then(res =>{
      if(res.data){
        if(Object.keys(res.data)[0] == "success"){
          setAddModelNameAvailable("yes")
        }else if(Object.keys(res.data)[0] == "error"){
          setAddModelNameAvailable("no")
        }else{
          setAddModelNameAvailable("pending")
        }
      }
    });
  }

  const checkSleeveName = () => {
    axios.get('https://backend.tenet-wealth.com/v1/sleeveNameAvailable/'+addSleeveName,{withCredentials: true}).then(res =>{
      if(res.data){
        if(Object.keys(res.data)[0] == "success"){
          setAddSleeveNameAvailable("yes")
        }else if(Object.keys(res.data)[0] == "error"){
          setAddSleeveNameAvailable("no")
        }else{
          setAddSleeveNameAvailable("pending")
        }
      }
    });
  }

  const getOwnerData = () => {
    axios.get('https://backend.tenet-wealth.com/v1/portfolioOwners',{withCredentials: true}).then(res =>{
      if(res.data){
        setOwnerDefaults(res.data.defaults);
        setOwnerList(res.data.owners)
        const initialOwner = res.data.owners[0]
        setModelOwner(initialOwner)
        setAddSleeveOwner(initialOwner)
        setModelEdit(res.data.defaults[initialOwner]['edit'])
        setModelView(res.data.defaults[initialOwner]['view'])
        setModelTrade(res.data.defaults[initialOwner]['trade'])

        setAddSleeveEdit(res.data.defaults[initialOwner]['edit'])
        setAddSleeveView(res.data.defaults[initialOwner]['view'])
        setAddSleeveTrade(res.data.defaults[initialOwner]['trade'])

        getModelSleeves(initialOwner);

      }
    });

  }



  const symLookup = (e) => {
    const sym = e.target.value;
    if (sym.length >0) {
      axios.get('https://backend.tenet-wealth.com/symsearch/'+sym).then( res =>{
        if (res.data.length > 0 ) {
          const newList= [];
          res.data.map(row =>{
            newList.push({"value":row.ticker, "label": row.company})
          })
          setSymlist(newList);
        } else {
          setSymlist([]);
        }

      })
      .catch((err) =>{
        console.log('Error with symbol search')
      })
    }

  }

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleAddSleeveInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addSleeveHoldings];
    if(name=="sym" || name=="notes"){
      list[index][name] = value;
    }else{
      const num = parseFloat(value);
      if(isNaN(num)){
        if(name=="wt"){
          list[index][name] = 0
        }else{
          list[index][name] = ""
        }
      }else{
        list[index][name] = parseFloat(value);
      }
    }
    setAddSleeveHoldings(list);
  }

  const handleModelInputChange = (name,value, index) => {
    if(typeof(value)=="number"){
      if(isNaN(value)){
        const list = [...proposedHoldings];
        list[index][name] = 0;
        setProposedHoldings(list);
        return
      }
    };
    const list = [...proposedHoldings];
    list[index][name] = value;
    setProposedHoldings(list);
  };

  const handleModelAddInputChange = (name,value, index) => {
    if(typeof(value)=="number"){
      if(isNaN(value)){
        const list = [...addHoldings];
        list[index][name] = 0;
        setAddHoldings(list);
        return
      }

    };
    const list = [...addHoldings];
    list[index][name] = value;
    setAddHoldings(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddSleeveRemoveClick = index => {
    const list = [...addSleeveHoldings];
    list.splice(index, 1);
    setAddSleeveHoldings(list);
  };

  const handleModelRemoveClick = index => {
    const list = [...proposedHoldings];
    list.splice(index, 1);
    setProposedHoldings(list);
  };

  const handleAddModelRemoveClick = index => {
    const list = [...addHoldings];
    list.splice(index, 1);
    setAddHoldings(list);
  };



  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList(
      [...inputList, { action:"", sym: "", notes:"", sleeveInfo:"", excludeSleeves:"",excludeSyms:""}]
    );
  };

  const handleAddSleeveAddClick = () => {
    setAddSleeveHoldings(
      [...addSleeveHoldings, { sym: "",wt: 0,max:"",min:"",mind:"",minq:"", notes:""}]
    );
  };
  const handleModelAddClick = () => {
    const proposed = [...proposedHoldings, { sleeve:"", swt:0}]
    setProposedHoldings(proposed);

  };

  const handleAddModelAddClick = () => {
    const proposed = [...addHoldings, { sleeve:"", swt:0}]
    setAddHoldings(proposed);

  };


  const submitSleeveEdit = () => {
    setLoading(true)
    console.log(inputList);
    axios.post('https://backend.tenet-wealth.com/v1/sleeveEdit',inputList,{withCredentials: true}).then(res => {
      setToastMsg({'header':'Sleeve Edit Processed','body':res.data.message})
      setToast(true);
      setLoading(false)
    }).catch( e =>{
      setToastMsg({'header':'ERROR','body': 'could not process. \n REASON: '+e});
      setToast(true);
      setLoading(false);
    })

  }

  const submitNewSleeveEdit = () => {
    setLoading(true)
    const payload = {
      sleeve: sleeve,
      sleeve_details: sleeveDetails
    }
    axios.post('https://backend.tenet-wealth.com/v1/sleeveSetEdit',payload,{withCredentials: true}).then(res => {
      getSleeveDetails(sleeve)
      setLoading(false);
      setToastMsg({'header':'Sleeve Edit Processed','body':res.data.message})
      setToast(true);
    }).catch( e =>{
      setToastMsg({'header':'ERROR','body': 'could not process. \n REASON: '+e});
      setToast(true);
      setLoading(false);
    })
  }

  const submitSleeveAdd = () => {
    const payload = {
      'name':addSleeveName,
      'sleeveType':addSleeveType,
      'sleeveAC':addSleeveAC,
      'description':addSleeveDescription,
      'notes':addSleeveNotes,
      'maxTol':parseFloat(addSleeveMaxTol),
      'minTol':parseFloat(addSleeveMinTol),
      'owner':addSleeveOwner,
      'edit':addSleeveEdit,
      'view':addSleeveView,
      'trade':addSleeveTrade,
      'sleeve_holdings':JSON.stringify(addSleeveHoldings)
    }
    console.log(payload)
    axios.post('https://backend.tenet-wealth.com/v1/sleeveAdd',payload,{withCredentials: true}).then(res => {
      setToastMsg({'header':'Sleeve Add Processed','body':res.data.message})
      setToast(true);
      setAddSleeveName('');
      setAddSleeveNameAvailable("pending");
      setAddSleeveHoldings([]);
    });


  }

  const submitModelEdit = () => {
    const list = [...proposedHoldings];

    proposedHoldings.forEach((i,index) => {
      list[index]['model'] = selectedModel;
      holdings.forEach(j => {
        if(i.sleeve == j.sleeve){
          list[index]['id'] = j.id;
        }
      })
    });
    holdings.forEach(row => {
      if(!proposedHoldings.some(i => i.sleeve == row.sleeve)){
        list.push({"sleeve":row.sleeve,"swt":0,"id":row.id})
      }
    });
    axios.post('https://backend.tenet-wealth.com/v1/modelEdit',list,{withCredentials: true}).then(res => {
      setToastMsg({'header':'Model Edit Processed','body':res.data.message})
      setToast(true);
      getModelHoldings(selectedModel);
    });
  }

  const submitModelAdd = () => {
    const payload = {
      'portfolio_type':'model',
      'portfolio_name':addModelName,
      'owner':modelOwner,
      'edit':modelEdit,
      'view':modelView,
      'trade':modelTrade,
      'description':'',
      'notes':'',
      'model_table':JSON.stringify(addHoldings)
    }
    axios.post('https://backend.tenet-wealth.com/v1/modelAdd',payload,{withCredentials: true}).then(res => {
      setToastMsg({'header':'Model Add Processed','body':res.data.message})
      setToast(true);
      setAddModelName('');
      setAddModelNameAvailable("pending");
      getModels();
      setAddHoldings([{ sleeve:'', swt:0}]);
    });

  }

  const handleModelOwner = (e) => {
    const owner = e.target.value;
    setModelOwner(owner);
    try{
      setModelView(ownerDefaults[owner]['view'])
    }
    catch{
      setModelView("");
    }
    try{
      setModelEdit(ownerDefaults[owner]['edit'])
    }
    catch{
      setModelEdit("");
    }
    try{
      setModelTrade(ownerDefaults[owner]['trade'])
    }
    catch{
      setModelTrade("");
    }
    getModelSleeves(owner)
  }

  const handleSleevelOwner = (e) => {
    const owner = e.target.value;
    setAddSleeveOwner(owner);
    try{
      setAddSleeveView(ownerDefaults[owner]['view'])
    }
    catch{
      setAddSleeveView("");
    }
    try{
      setAddSleeveEdit(ownerDefaults[owner]['edit'])
    }
    catch{
      setAddSleeveEdit("");
    }
    try{
      setAddSleeveTrade(ownerDefaults[owner]['trade'])
    }
    catch{
      setAddSleeveTrade("");
    }
  }


  const renderSleeveEdit = () => {
    return inputList.map((x, i) => {
      return (
          <>
          <Row>
            <h4>Manual Sleeve Edit</h4>
          </Row>
          <div className="container-flex m-3">
            <div className="col-12">
              <input className="my-input" name="action" placeholder="Action" value={x.action} onChange={e => handleInputChange(e, i)} autoComplete="off"/>
              <input
                required
                name="sym"
                className="my-input"
                placeholder="Symbol"
                value={x.sym}
                list={"symbol-datalist"+i}
                onChange={e => {symLookup(e);
                                handleInputChange(e,i) }
                        }
                autoComplete="off"
              />
              <datalist id={"symbol-datalist"+i} name='sym' onChange={e => handleInputChange(e,i)}>
                {symlist.map((row,index) => {
                  return(
                    <option key={index} value={row.value}>{row.label}</option>
                  )
                })}
              </datalist>
              <input className="my-input" name="notes" placeholder="Notes" value={x.notes} onChange={e => handleInputChange(e, i)} autoComplete="off"/>
              <input className="my-input" name="sleeveInfo" placeholder="Sleeve Info" value={x.sleeveInfo} onChange={e => handleInputChange(e, i)} autoComplete="off"/>
              <input className="my-input" name="excludeSleeves" placeholder="Exclude Sleeves" value={x.excludeSleeves} onChange={e => handleInputChange(e, i)} autoComplete="off"/>
              <input className="my-input" name="excludeSyms" placeholder="Exlude Symbols" value={x.excludeSyms} onChange={e => handleInputChange(e, i)} autoComplete="off"/>
              {inputList.length - 1 === i && <Button className="ml-3" type="primary" onClick={handleAddClick}>Add</Button>}
              {inputList.length !== 1 && <Button className="ml-3" type="primary" danger onClick={() => handleRemoveClick(i)}>Delete</Button>}
            </div>
          </div>
          <Popconfirm title="Confirm Submit?" onConfirm={submitSleeveEdit}>
            <Button type="primary">Submit Edits</Button>
          </Popconfirm>

          </>
      );
    })
  }

  const renderSleeveAdd = () =>{
    return addSleeveHoldings.map((x,i) => {
      return(
        <div className="container-flex m-3">
          <div className="col-12">
          {i==0 && <div><label style={{paddingRight:'120px'}} htmlFor="sleeve0">Symbol</label>
            <label style={{paddingRight:'65px'}} htmlFor="swt0">Weight</label>
            <label style={{paddingRight:'35px'}} htmlFor="swt0">Max Weight</label>
            <label style={{paddingRight:'35px'}} htmlFor="swt0">Min Weight</label>
            <label style={{paddingRight:'10px'}} htmlFor="swt0">Minimum Dollar</label>
            <label style={{paddingRight:'10px'}} htmlFor="swt0">Minimum Quantity</label>
            <label htmlFor="swt0">Notes</label>
            <br/></div>}

            <input
              required
              name="sym"
              className="my-input"
              placeholder="Symbol"
              value={x.sym}
              list={"symbol-datalist1"+i}
              onChange={e => {symLookup(e);
                              handleAddSleeveInputChange(e,i) }
                      }
              autoComplete="off"
            />
            <datalist id={"symbol-datalist1"+i} name='sym' onChange={e => handleAddSleeveInputChange(e,i)}>
              {symlist.map((row,index) => {
                return(
                  <option key={index} value={row.value}>{row.label}</option>
                )
              })}
            </datalist>
            <input className="my-input" name="wt" placeholder="Weight" type="number" min= {0} max={1} step={.1} value={x.wt} onChange={e => handleAddSleeveInputChange(e, i)} autoComplete="off"/>
            <input className="my-input" name="max" placeholder="Max" type="number" min= {0} max={1} step={.1}  value={x.max} onChange={e => handleAddSleeveInputChange(e, i)} autoComplete="off"/>
            <input className="my-input" name="min" placeholder="Min" type="number" min= {0} max={1} step={.1} value={x.min} onChange={e => handleAddSleeveInputChange(e, i)} autoComplete="off"/>
            <input className="my-input" name="mind" placeholder="Min Dollar" type="number" value={x.mind} onChange={e => handleAddSleeveInputChange(e, i)} autoComplete="off"/>
            <input className="my-input" name="minq" placeholder="Min Quantity" type="number" value={x.minq} onChange={e => handleAddSleeveInputChange(e, i)} autoComplete="off"/>
            <input className="my-input" name="notes" placeholder="Notes" value={x.notes} onChange={e => handleAddSleeveInputChange(e, i)} autoComplete="off"/>
            {addSleeveHoldings.length - 1 === i && <Button className="ml-3" type="primary" onClick={handleAddSleeveAddClick}>Add</Button>}
            {addSleeveHoldings.length !== 1 && <Button className="ml-3" type="primary" danger onClick={() => handleAddSleeveRemoveClick(i)}>Delete</Button>}

          </div>
        </div>

      )
    })

  }

  const renderModelEdit = () => {
    return proposedHoldings.map((x,i) => {
      return(
        <div className="form-group row">
          <div className="col-sm-10">
          {i==0 && <div><label style={{paddingRight:'215px'}} htmlFor="sleeve0">Sleeve</label> <label htmlFor="swt0">Weight</label><br/></div>}
            <input
              name={"sleeve"+i}
              className="my-input"
              placeholder="Sleeve"
              value={x.sleeve}
              style={{'width':'250px','marginRight':'10px'}}
              list={"sleeve-datalist"+i}
              onChange={e => {
                              handleModelInputChange('sleeve',e.target.value,i) }
                      }
              autoComplete="off"
            />
            <datalist id={"sleeve-datalist"+i} name='sleeve' onChange={e => handleModelInputChange('sleeve',e.target.value,i)}>
              {sleevesList.map((row,index) => {
                return(
                  <option key={index} value={row.label}>{row.label}</option>
                )
              })}
            </datalist>
            <input className="my-input" style={{"width":'100px'}} name={"swt"+i} type="number" min={0} max={1} step={.01} placeholder="Sleeve Weight" value ={x.swt} onChange={e => handleModelInputChange('swt',parseFloat(e.target.value),i)} autoComplete="off"/>
            {proposedHoldings.length - 1 === i && <Button className="ml-3" type="primary" onClick={handleModelAddClick}>Add</Button>}
            {proposedHoldings.length !== 1 && <Button className="ml-3" type="primary" danger onClick={() => handleModelRemoveClick(i)}>Delete</Button>}
          </div>
        </div>
      );
    })
  }
  const renderModelAdd = () => {
    return addHoldings.map((x,i) => {
      return(
        <div className="form-group row">
          <div className="col-sm-10">
            {i==0 && <div><label style={{paddingRight:'215px'}} htmlFor="sleeve0">Sleeve</label> <label htmlFor="swt0">Weight</label><br/></div>}
            <input
              name={"sleeve"+i}
              className="my-input"
              placeholder="Sleeve"
              value={x.sleeve}
              style={{'width':'250px','marginRight':'10px'}}
              list={"sleeve-datalist"+i}
              onChange={e => {
                              handleModelAddInputChange('sleeve',e.target.value,i) }
                      }
              autoComplete="off"
            />
            <datalist id={"sleeve-datalist"+i} name={"sleeve"+i} onChange={e => handleModelAddInputChange('sleeve',e.target.value,i)}>
              {addSleevesList.map((row,index) => {
                return(
                  <option key={index} value={row.label}>{row.label}</option>
                )
              })}
            </datalist>
            <input className="my-input" name={"swt"+i} style={{"width":'100px'}} type="number" min={0} max={1} step={.01} placeholder="Sleeve Weight" value ={x.swt} onChange={e => handleModelAddInputChange('swt',parseFloat(e.target.value),i)} autoComplete="off"/>
            {addHoldings.length - 1 === i && <Button className="ml-3" type="primary" onClick={handleAddModelAddClick}>Add</Button>}
            {addHoldings.length !== 1 && <Button className="ml-3" type="primary" danger onClick={() => handleAddModelRemoveClick(i)}>Delete</Button>}
          </div>
        </div>
        );
    })
  }

  const detailColumns = [
  {
    title: 'Symbol',
    dataIndex: 'sym',
    key: 'sym',
    sorter: (a, b) => a.sym.localeCompare(b.sym)

  },
  {
    title: 'Weight',
    dataIndex: 'wt',
    key: 'wt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.wt - b.wt
  }]

  const sleeveEditCols = [
    {
      title: 'Symbol',
      dataIndex: 'sym',
      key: 'sym',
      sorter: (a, b) => a.sym.localeCompare(b.sym),
      render: (text,record) => (
        <>
        {record.wt == 0 ?
          <input type="text" value = {record.sym} onChange={(e) => {
              var change = [...sleeveDetails];
              var foundIndex = change.findIndex((x) => x.key == record.key);
              change[foundIndex]["sym"] = e.target.value;
              setSleeveDetails(change);
            }}
          />
        :
        <td className='ant-table-cell'>{record.sym}</td>
      }
      </>
    ),
    },
    {
      title: 'Current Weight',
      dataIndex: 'wt',
      key: 'wt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.wt - b.wt
    },
    {
      title: 'New Weight',
      dataIndex: 'sym',
      key: 'sym',
      render: (text,record) =>(
        <input style= {{'width':'80%'}} type="number" value = {record.newWt} min={0} max={1} step={.001} onChange={(e) => {
            var change = [...sleeveDetails];
            var foundIndex = change.findIndex((x) => x.key == record.key);
            change[foundIndex]["newWt"] = parseFloat(e.target.value);
            setSleeveDetails(change);
          }}
/>
      ),

    }




  ]

  const symColumns = [
  {
    title: 'Sleeve',
    dataIndex: 'sleeve',
    key: 'sleeve',
    sorter: (a, b) => a.sleeve.localeCompare(b.sleeve)

  },
  {
    title: 'Weight',
    dataIndex: 'wt',
    key: 'wt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.wt - b.wt
  }]

  useEffect( () => {

    getSleeves();
    getSyms();
    getModels();
    getOwnerData();

  },[])

  const renderContent = () => {
    if(editType=="sleeve"){
      return(
      <div>
        <Row className="justify-content-center mt-3">
          <Col xl={5} lg= {6} md={12}>
            <Select
              placeholder="Sleeve"
              options={sleevesList}
              onChange={sleeveSelectChange}
              isClearable
            />
            <br/>
            <div className='mb-2'>
              <Table size="small" columns={sleeveEditCols} dataSource={sleeveDetails} pagination={false}  scroll={{ y: '50vh' }}/>
            </div>
            {sleeve.length > 0 &&
            <>
            <div className='mt-3'>
              <hr/>
              <h6>New Invested Weight: {sleeveDetails.reduce((prev, cur) => prev + cur.newWt, 0).toFixed(5)}</h6>
              <br/>
              <h6>New Cash/Money Market Weight : {(1 - sleeveDetails.reduce((prev, cur) => prev + cur.newWt, 0)).toFixed(5)}</h6>
              <br/>
              <Button type="danger" className='mr-2' onClick = {e => getSleeveDetails(sleeve)}>Reset Changes</Button>
              <Button type="danger" className='mr-2' onClick = {e => setSleeveDetails(sleeveDetails.map(row => ({...row,newWt:0})))}>Sell All Holdings</Button>
              <Button type="primary" className='mr-2'  onClick = {e => setSleeveDetails([...sleeveDetails,{sym:'',wt:0,newWt:0,key: (sleeveDetails.length)}])}>Add Holding</Button>
              <Popconfirm title="Confirm Submit?" onConfirm={submitNewSleeveEdit}>
                <Button type="primary" className='mr-2'>Submit Edits</Button>
              </Popconfirm>

            </div>
            <div className='mt-3'>
              <hr/>
              <h6>Sleeve Access Rights</h6><br/>
              <div className='mt-2'>
                <label htmlFor="modelEdit">Sleeve Edit Rights</label><br/>
                <input className="my-input" name="editSleeveEdit" placeholder="Sleeve Edit Rights" value={editSleeveEdit} onChange={e => setEditSleeveEdit(e.target.value)}/>
              </div>
              <div className='mt-2'>
                <label htmlFor="modelEdit">Sleeve View Rights</label><br/>
                <input className="my-input" name="editSleeveView" placeholder="Sleeve View Rights" value={editSleeveView} onChange={e => setEditSleeveView(e.target.value)}/>
              </div>
              <div className='mt-2 mb-2'>
                <label htmlFor="modelEdit">Sleeve Trade Rights</label><br/>
                <input className="my-input" name="editSleeveEdit" placeholder="Sleeve Edit Rights" value={editSleeveTrade} onChange={e => setEditSleeveTrade(e.target.value)}/>
              </div>
              <Button type="danger" className='mr-2' onClick = {e => getPortfolioAccess('sleeve',sleeve)}>Reset Changes</Button>
              <Popconfirm title="Confirm Submit?" onConfirm={submitSleeveEditRights}>
                <Button type="primary" className='mr-2'>Submit Edits</Button>
              </Popconfirm>

            </div>
            </>}

          </Col>
          <Col xl={7} lg={6} md={12}>
            <div style={{'height':'70vh'}} >
              <h4>Invested Allocation</h4>
              <VictoryPie height={300} width={450} animate={{duration: 1500}}
                labelComponent ={
                  <VictoryTooltip
                    x={225} y={200}
                    orientation="top"
                    pointerLength={0}
                    cornerRadius={50}
                    flyoutWidth={100}
                    flyoutHeight={100}
                    flyoutStyle={{ fill: "lightgrey" }}
                  />
                }
               data={sleeveDetails} x="sym" y="newWt"
               colorScale= "cool"
               innerRadius={60} radius={100}/>
              </div>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col md={5}>
            <h4>Symbol Overview</h4>
            <Select
              placeholder="Symbol"
              options={symsList}
              onChange={symSelectChange}
              isClearable
            />
            <br/>

            <Table size="small" columns={symColumns} dataSource={symDetails} pagination={{ defaultPageSize: 10, showSizeChanger: true,pageSizeOptions: ['10', '20', '30']}}/>
          </Col>
        </Row>
        <hr/>
        <div className="container-flex m-3">
          <Switch onChange={checked => setManualEdit(checked)}/>
          <label>Manual Sleeve Edit</label>
          {manualEdit && renderSleeveEdit() }
        </div>
      </div>
      )
    }else if (editType=="model") {
      return(
        <div className='mt-3'>
        <Col sm={3} className='mb-3'>
          <Select options = {modelsList}
            onChange={modelSelectChange}
            isClearable
          />
        </Col>
        {selectedModel !== '' &&
        <div>
          <Row>
            <h3 className='ml-5'>Current Allocation</h3>
          </Row>
          <Row>
            <Col xl={3} lg={5} sm={8}>
              <VictoryPie height={300} width={450} data={holdings} x="sleeve" y="swt" colorScale="cool" labelRadius = {120} innerRadius={70} radius={100} padAngle={2} padding={{'right': 50, 'left':50}}/>
            </Col>
            <Col xl = {9} lg={7} sm={4}>
              {holdings.map(x => {
                return(
                  <Statistic title={x.sleeve} value={x.swt} formatter={(value) =>((value * 100) + "%")}/>

                )
              })}
            </Col>
          </Row>
          <hr/>
          <Row>
            <h3 className='ml-5'>Proposed Allocation</h3>
          </Row>
          <Row>
            <Col xl={3} lg={5} sm={8}>
              <VictoryPie height={300} width={450} animate={{duration: 1500}} data={proposedHoldings} x="sleeve" y="swt" colorScale="cool" labelRadius = {120} innerRadius={70} radius={100} padAngle={2} padding={{'right': 50, 'left':50}}/>
            </Col>
            <Col xl={3} sm={4}>
              <div className='mt-3'>
                <h6>Model Access Rights</h6><br/>
                <div className='mt-2'>
                  <label htmlFor="modelEdit">Model Edit Rights</label><br/>
                  <input className="my-input" name="editModelEdit" placeholder="Model Edit Rights" value={editModelEdit} onChange={e => setEditModelEdit(e.target.value)}/>
                </div>
                <div className='mt-2'>
                  <label htmlFor="modelEdit">Model View Rights</label><br/>
                  <input className="my-input" name="editModelView" placeholder="Sleeve View Rights" value={editModelView} onChange={e => setEditModelView(e.target.value)}/>
                </div>
                <div className='mt-2 mb-2'>
                  <label htmlFor="modelEdit">Model Trade Rights</label><br/>
                  <input className="my-input" name="editModelTrade" placeholder="Sleeve Edit Rights" value={editModelTrade} onChange={e => setEditModelTrade(e.target.value)}/>
                </div>
                <Button type="danger" className='mr-2' onClick = {e => getPortfolioAccess('model',selectedModel)}>Reset Changes</Button>
                <Popconfirm title="Confirm Submit?" onConfirm={submitModelEditRights}>
                  <Button type="primary" className='mr-2'>Submit Edits</Button>
                </Popconfirm>

              </div>
            </Col>

            <Col xl={6} lg={7} sm={4}>
              {renderModelEdit()}
                {parseFloat(proposedHoldings.reduce((prev, cur) => prev + cur.swt, 0).toFixed(10)) > 1
                  ? <div>
                      <div className="row ml-2">
                        <p style={{"fontSize":"150%"}}>Total Allocation :</p>
                        <p style={{'color':'red',"fontSize":"150%"}}>{parseFloat(proposedHoldings.reduce((prev, cur) => prev + cur.swt, 0).toFixed(5))}</p>
                      </div>
                      <div className="row ml-2">
                        <Button disabled type="primary" onClick={e => console.log('test submit')}>Submit</Button>
                      </div>
                    </div>
                : <div>
                    <div className="row ml-2">
                      <p style={{"fontSize":"150%"}}>Total Allocation :</p>
                      <p style={{"fontSize":"150%"}}>{parseFloat(proposedHoldings.reduce((prev, cur) => prev + cur.swt, 0).toFixed(5))}</p>
                    </div>
                    <div className="row ml-2">
                      <Popconfirm title="Confirm Submit?" onConfirm={submitModelEdit  }>
                        <Button type="primary">Submit Changes</Button>
                      </Popconfirm>
                    </div>
                  </div>}
            </Col>

          </Row>
        </div>
        }
        </div>
      )

    }else if(editType=="addSleeve"){
      return(
        <div className='mt-5'>
        <div className='form-group row'>
          <div className='col sm-10'>
            <label htmlFor="addSleeveName">Sleeve Name</label><br/>
            <input className='my-input' name='addSleeveName' placeholder="Sleeve Name" value={addSleeveName} onChange={e =>{setAddSleeveName(e.target.value); setAddSleeveNameAvailable("pending")} } autoComplete="off"/>
            <Button className='ml-2' type="primary" onClick={checkSleeveName}>Check Availability</Button>
            {addSleeveNameAvailable=="no" && <><WarningFilled style={{'fontSize': '32px', color:'red','marginLeft':'20px'}}/><br/><br/><p>Sleeve name already in use!</p></>}
            {addSleeveNameAvailable=="yes" && <CheckSquareFilled style={{'fontSize': '32px', color:'green','marginLeft':'20px'}}/>}
          </div>
        </div>
        {addSleeveNameAvailable=="yes" &&
        <div>
        <br/><hr/><br/>
          <div className="form-group row">
            <div className="col sm-10">
              <label htmlFor="sleeveOwner">Sleeve Owner</label><br/>
              <select className="my-input" name="sleeveOwner" placeholder="Sleeve Owner" value={addSleeveOwner} onChange={handleSleevelOwner}>
                {ownerList.map(x => {return(
                  <option value={x}>{x}</option>
                )}) }
              </select>
            </div>
          </div>
            <div className="row justify-content-start" >
              <div className="col-lg-2">
                <label htmlFor="modelView">Sleeve Type</label><br/>
                <select className="my-input" name="sleeveType" placeholder="Sleeve Type" value={addSleeveType} onChange={e => setAddSleeveType(e.target.value)}>
                    <option value="adj">Adjustable</option>
                    <option value="static">Static</option>
                </select>
             </div>
              <div className="col-lg-2">
                <label htmlFor="modelEdit">Sleeve Asset Class</label><br/>
                <select className="my-input" name="sleeveAC" placeholder="Asset Class" value={addSleeveAC} onChange={e => setAddSleeveAC(e.target.value)}>
                  <option value="equity">Equity</option>
                  <option value="fixed income">Fixed Income</option>
                  <option value="alternative">Alternative</option>

                </select>
              </div>
              <div className="col-lg-2">
                <label htmlFor="modelTrade">Sleeve Min Tol</label><br/>
                <input className="my-input" type="number" name="minTol" placeholder="Sleeve Min Tol" value={addSleeveMinTol} onChange={e => setAddSleeveMinTol(e.target.value)}/>
              </div>
              <div className="col-lg-2">
                <label htmlFor="modelTrade">Sleeve Max Tol</label><br/>
                <input className="my-input" type="number" name="maxTol" placeholder="Sleeve Max Tol" value={addSleeveMaxTol} onChange={e => setAddSleeveMaxTol(e.target.value)}/>
              </div>

            </div>
            <div className="row justify-content-start" >
              <div className="col-lg-2">
                <label htmlFor="modelView">Sleeve View</label><br/>
                <input className="my-input" name="modelView" placeholder="Sleeve View Rights" value={addSleeveView} onChange={e => setAddSleeveView(e.target.value)}/>
              </div>
              <div className="col-lg-2">
                <label htmlFor="modelEdit">Sleeve Edit</label><br/>
                <input className="my-input" name="modelEdit" placeholder="Sleeve Edit Rights" value={addSleeveEdit} onChange={e => setAddSleeveEdit(e.target.value)}/>
              </div>
              <div className="col-lg-2">
                <label htmlFor="modelTrade">Sleeve Trade</label><br/>
                <input className="my-input" name="modelTrade" placeholder="Sleeve Trade Rights" value={addSleeveTrade} onChange={e => setAddSleeveTrade(e.target.value)}/>
              </div>

            </div>

          <br/><hr/><br/>
        </div>
        }
        {addSleeveNameAvailable=="yes" && renderSleeveAdd()}
        {addSleeveNameAvailable=="yes" && addSleeveHoldings.reduce((prev, cur) => prev + cur.wt, 0)>1
          ? <div>
              <div className="row ml-2">
              <ExclamationCircleOutlined style={{'fontSize': '24px', color:'red','paddingTop':'5px','paddingRight':'20px'}}/>
                <p style={{"fontSize":"150%"}}>Total Allocation :</p>
                <p style={{'color':'red',"fontSize":"150%"}}>{parseFloat(addSleeveHoldings.reduce((prev, cur) => prev + cur.wt, 0).toFixed(5))}   </p>
              </div>
              <div className="row ml-2">
                <Button disabled type="primary">Submit</Button>
              </div>
            </div>
        : addSleeveNameAvailable=="yes" && <div>
            <div className="row ml-2">
              <p style={{"fontSize":"150%"}}>Total Allocation :</p>
              <p style={{"fontSize":"150%"}}>{parseFloat(addSleeveHoldings.reduce((prev, cur) => prev + cur.wt, 0).toFixed(5))}</p>
            </div>
            <div className="row ml-2">
              <Popconfirm title="Confirm Submit?" onConfirm={submitSleeveAdd}>
                <Button type="primary">Submit Changes</Button>
              </Popconfirm>
            </div>
          </div>}
        </div>
      )
    }else if(editType=="addModel"){
      return(
      <div className='mt-5'>
        <Row>
        <Col xl={6} lg={5} sm={12}>
        {addModelNameAvailable=="yes" &&
          <VictoryPie height={300} width={450} animate={{duration: 1500}} data={addHoldings} x="sleeve" y="swt" colorScale="cool" labelRadius = {120} innerRadius={70} radius={100} padAngle={2} padding={{'right': 50, 'left':50}}/>
        }
        </Col>
        <Col xl={6} lg={7} sm={12}>
          <div className='form-group row'>
            <div className='col sm-10'>
              <label htmlFor="addModelName">Model Name</label><br/>
              <input className='my-input' name='addModelName' placeholder="Model Name" value={addModelName} onChange={e =>{setAddModelName(e.target.value); setAddModelNameAvailable("pending")} } autoComplete="off"/>
              <Button className='ml-2' type="primary" onClick={checkModelName}>Check Availability</Button>
              {addModelNameAvailable=="no" && <><WarningFilled style={{'fontSize': '32px', color:'red','marginLeft':'20px'}}/><br/><br/><p>Model name already in use!</p></>}
              {addModelNameAvailable=="yes" && <CheckSquareFilled style={{'fontSize': '32px', color:'green','marginLeft':'20px'}}/>}

            </div>
          </div>
          {addModelNameAvailable=="yes" &&
          <div>
          <br/><hr/><br/>
            <div className="form-group row">
              <div className="col sm-10">
                <label htmlFor="modelOwner">Model Owner</label><br/>
                <select className="my-input" name="modelOwner" placeholder="Model Owner" value={modelOwner} onChange={handleModelOwner}>
                  {ownerList.map(x => {return(
                    <option value={x}>{x}</option>
                  )}) }
                </select>
              </div>
            </div>
              <div className="row justify-content-start">
                <div className="col-3">
                  <label htmlFor="modelView">Model View</label><br/>
                  <input className="my-input" name="modelView" placeholder="Model View Rights" value={modelView} onChange={e => setModelView(e.target.value)}/>
                </div>
                <div className="col-3">
                  <label htmlFor="modelEdit">Model Edit</label><br/>
                  <input className="my-input" name="modelEdit" placeholder="Model Edit Rights" value={modelEdit} onChange={e => setModelEdit(e.target.value)}/>
                </div>
                <div className="col-3">
                  <label htmlFor="modelTrade">Model Trade</label><br/>
                  <input className="my-input" name="modelTrade" placeholder="Model Trade Rights" value={modelTrade} onChange={e => setModelTrade(e.target.value)}/>
                </div>

              </div>
            <br/><hr/><br/>
          </div>
          }
          {addModelNameAvailable=="yes" && renderModelAdd()}
            {addModelNameAvailable=="yes" && addHoldings.reduce((prev, cur) => prev + cur.swt, 0)>1
              ? <div>
                  <div className="row ml-2">
                  <ExclamationCircleOutlined style={{'fontSize': '24px', color:'red','paddingTop':'5px','paddingRight':'20px'}}/>
                    <p style={{"fontSize":"150%"}}>Total Allocation :</p>
                    <p style={{'color':'red',"fontSize":"150%"}}>{parseFloat(addHoldings.reduce((prev, cur) => prev + cur.swt, 0).toFixed(5))}   </p>
                  </div>
                  <div className="row ml-2">
                    <Button disabled type="primary">Submit</Button>
                  </div>
                </div>
            : addModelNameAvailable=="yes" && <div>
                <div className="row ml-2">
                  <p style={{"fontSize":"150%"}}>Total Allocation :</p>
                  <p style={{"fontSize":"150%"}}>{parseFloat(addHoldings.reduce((prev, cur) => prev + cur.swt, 0).toFixed(5))}</p>
                </div>
                <div className="row ml-2">
                  <Popconfirm title="Confirm Submit?" onConfirm={submitModelAdd}>
                    <Button type="primary">Submit Changes</Button>
                  </Popconfirm>
                </div>
              </div>}
        </Col>

        </Row>
      </div>
      )
    }
  }

  return(
    <Layout className="layout">
      <Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1">
            Trading
          </Menu.Item>
          <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
            Home
          </Menu.Item>
          <Menu.Item key="3" onClick={() => axios.get('https://backend.tenet-wealth.com/refreshQuotes')} >
            Refresh Quotes
          </Menu.Item>
  

        </Menu>
      </Header>
      <Content style={{ padding: '20px 50px' }}>
        <div style={{background: "#fff", padding:'20px'}}>
          <Menu onClick={e=>setEditType(e.key)} selectedKeys={editType} mode="horizontal">
            <Menu.Item key="sleeve">
              Edit Sleeve
            </Menu.Item>
            <Menu.Item key="model">
              Edit Model
            </Menu.Item>
            <Menu.Item key="addSleeve">
              Add Sleeve
            </Menu.Item>
            <Menu.Item key="addModel">
              Add Model
            </Menu.Item>

          </Menu>

          {renderContent()}
        </div>
        <Modal title="Loading" visible={loading} closable={false} maskClosable={false} okButtonProps={{style: {display:'none'}}} cancelButtonProps={{style: {display:'none'}}} >
          <div className="d-flex justify-content-center align-items-center mt-5">
             <ReactLoading type="spin" color="navy" className='mt-5 mb-5' />
           </div>
        </Modal>
      </Content>
      <Toast onClose={() => setToast(false)} show={toast} delay={5000} autohide  style={{
        position: 'fixed',
        top: 30,
        right: 30,
        minHeight: '100px',
        minWidth: '200px',
        zIndex: 7000
      }}>
        <Toast.Header>
          <strong className="mr-auto">{toastMsg.header}</strong><br/>
        </Toast.Header>
        <Toast.Body>{toastMsg.body}</Toast.Body>
      </Toast>
    {/*  <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
    </Layout>
  )

}


export default Trading;

import React,{useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Button, Row, Col, Toast } from 'react-bootstrap';
import {Popconfirm} from 'antd'
import ReactLoading from "react-loading";



export default function WithdrawalsAddEdit(props) {

  const [acct,setAcct] = useState('');
  const [acctNm,setAcctNm] = useState('');

  const [dtSent,setDtSent] = useState('');
  const [cash,setCash] = useState('');
  const [wtdType,setWtdType] = useState('');
  const [amount,setAmount] = useState(0);
  const [sendOn,setSendOn] = useState('');
  const [destinationList,setDestinationList] = useState(['']);
  const [destination,setDestination] = useState('');
  const [message,setMessage] = useState('');
  const [notes,setNotes] = useState('');
  const [stateWith,setStateWith] = useState(0);
  const [fedWith,setFedWith] = useState(0);
  const [initalData,setInitialData] = useState({})

  const [editToast,setEditToast] = useState(false);
  const [editToastMsg,setEditToastMsg] = useState({});

  const [deleted,setDeleted] = useState(false);

  const generateMessage = () => {
    const netAmount = (amount*(1-stateWith-fedWith)).toFixed(2)
    const output = `Account: ${acctNm} \n Please send via the following method:  ${destination} \n Ammount: ${amount}\
     \n Federal Withholding: ${fedWith} \n State Withholding: ${stateWith} \n Estimated Net Total: ${netAmount}`
    setMessage(output);
  }

  useEffect(() =>{
    if (props.withdrawalID){
      axios.get('https://backend.tenet-wealth.com/v1/withdrawalsOverview/'+props.withdrawalID,{withCredentials: true}).then(res => {
        const data = JSON.parse(res.data.withdrawal)[0];
        setInitialData(data)
        setDestinationList(JSON.parse(res.data.destinations));
        setAcctNm(res.data.name);
        setAcct(data.acct);
        setCash(res.data.cash);
        setWtdType(data.type);
        setAmount(data.amount);
        setMessage(data.message);
        setNotes(data.notes);
      })
    } else if (props.acct) {
      axios.get('https://backend.tenet-wealth.com/v1/withdrawalsAdd/'+props.acct,{withCredentials: true}).then(res => {
        setAcct(props.acct);
        setCash(res.data.cash);
        setDestinationList(JSON.parse(res.data.destinations));
        setAcctNm(res.data.name);
    }).catch( e => {
        console.log(e);
    })
  }

  },[])
  const deleteWD = () => {
    const payload = initalData
    payload['active'] = false
    payload['account'] = payload['acct']
    delete payload['acct'];

    axios.post('https://backend.tenet-wealth.com/v1/withdrawals',payload,{withCredentials: true}).then( res => {
      setEditToastMsg({'header':'DELETE SUCCESSFUL','body':'id '+payload.id+' deleted'})
      setEditToast(true);
      setDeleted(true);
    }).catch( e =>{
      setEditToastMsg({'header':'ERROR','body': 'could not delete withdrawal. \n REASON: '+e});
      setEditToast(true);
    })
  }


  const sendWD = () =>{
    const withdrawalData = {
      'id':props.withdrawalID,
      'account':acct,
      'dtSent':dtSent,
      'type':wtdType,
      'amount':amount,
      'stateWith':stateWith,
      'fedWith':fedWith,
      'sendOn':sendOn,
      'destination':destination,
      'message':message,
      'notes':notes
    }
    axios.post('https://backend.tenet-wealth.com/v1/withdrawals',withdrawalData,{withCredentials: true}).then(res => {
        if(res.data.success){
          setEditToastMsg({'header':'Withdrawal Sent','body':res.data.success})
          setEditToast(true)
        } else if (res.data.error) {
          setEditToastMsg({'header':'Error','body':res.data.error})
          setEditToast(true)
        } else {
          setEditToastMsg({'header':'Error','body':'unkown error occured'})
          setEditToast(true)
        }
      }).catch( e => {
        console.log(e);
        setEditToastMsg({'header':'ERROR','body':'error'})
        setEditToast(true);
      }
      );
    }
  if (!acct){
    return(
      <div className="d-flex justify-content-center align-items-center">
         <ReactLoading type="spin" color="navy" className='mt-5 mb-5' />
       </div>
    )
  }

  if (deleted){
    return(
      <div>
        <p>Withdrawal has been deleted</p>
      </div>
    )
  }

  return(
    <div className="container mt-3 mb-3">
      <h4> Account Number : {acct}</h4>
      <h4> Name : {acctNm}</h4>
      <h4> Cash Available : {cash} </h4>
      <hr/>

      <Row className='mt-3'>
        <Col lg={4} md={4}>
          <Form.Label>Date Sent</Form.Label>
          <Form.Control as="input" type="date" value={dtSent} placeholder="yyyy-mm-dd" onChange = {e => setDtSent(e.target.value)}/>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col lg={4} md={4}>
          <Form.Label>Withdrawal Type</Form.Label>
          <Form.Control as="select" value={wtdType} onChange = {e => setWtdType(e.target.value)}>
            <option value=""></option>
            <option value='onetime'>One Time</option>
            <option value='adhoc'>Adhoc</option>
            <option value='swp'>SWP</option>
          </Form.Control>
        </Col>

        <Col lg={4} md={4}>
          <Form.Label>Amount</Form.Label>
          <Form.Control as="input" min="0" value={amount} type='number' onChange = {e => setAmount(e.target.value)}>
          </Form.Control>
        </Col>

      </Row>
      <Row className='mt-3'>
        <Col lg={4} md={4}>
          <Form.Label>State Withholding</Form.Label>
          <Form.Control as="input" max="1" min="0" step=".01" value={stateWith} type='number' onChange = {e => setStateWith(e.target.value)}>
          </Form.Control>
        </Col>

        <Col lg={4} md={4}>
          <Form.Label>Federal Withholding</Form.Label>
          <Form.Control as="input" max="1" min="0" step=".01" value={fedWith} type='number' onChange = {e => setFedWith(e.target.value)}>
          </Form.Control>
        </Col>

      </Row>

      <Row className='mt-3'>
        <Col>
          <Form.Label>Estimated Net Amount: {(amount*(1-stateWith-fedWith)).toFixed(2)}</Form.Label>
        </Col>

      </Row>

      <Row className='mt-3'>

        <Col lg={4} md={4}>
          <Form.Label>Send On</Form.Label>
          <Form.Control as="input" type="date" value={sendOn} placeholder="yyyy-mm-dd" onChange = {e => setSendOn(e.target.value)}/>
        </Col>


        <Col lg={4} md={4}>
          <Form.Label>Destination</Form.Label>
          <Form.Control as="select" value={destination} onChange={e => setDestination(e.target.value)}>
            <option value=''></option>
            {destinationList.map( (row,index) => (
              <option key={index} value={row}>{row}</option>
            )

            )}

          </Form.Control>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
        <Button onClick={generateMessage}>Auto-Generate Message</Button>
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col>
          <Form.Label>Message</Form.Label>
          <Form.Control as="textarea" value={message} style={{"height":"175px"}} onChange = {e => setMessage(e.target.value)} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>Notes</Form.Label>
          <Form.Control as="textarea" value={notes} onChange = {e => setNotes(e.target.value)} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Popconfirm title="Confirm Delete?" onConfirm={deleteWD}>
            <Button variant="danger" className='mt-3'>Delete Withdrawal</Button>
          </Popconfirm>
        </Col>
        <Col>
          <Button className='mt-3' onClick = {sendWD}>SEND</Button>
        </Col>

      </Row>

      <Toast onClose={() => setEditToast(false)} show={editToast} delay={5000} autohide  style={{
        position: 'fixed',
        top: 30,
        right: 30,
        minHeight: '100px',
        minWidth: '200px',
        backgroundColor: 'grey',
        zIndex: 7000
      }}>
        <Toast.Header>
          <strong className="mr-auto">{editToastMsg.header}</strong>
          <small>now</small>
        </Toast.Header>
        <Toast.Body style={{color:'white'}}>{editToastMsg.body}</Toast.Body>
      </Toast>

    </div>

  )
}

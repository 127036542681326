import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Form, Button, Row, Col, Toast } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup'
import { useHistory } from "react-router-dom";
import {Table, Layout, Menu, Popconfirm, Modal, Tabs, Statistic} from 'antd';
import ReactLoading from "react-loading";
import Select from 'react-select';


const { Header, Content, Footer } = Layout;
const {TabPane} = Tabs;

const BlockTrading = () => {
  let history = useHistory();
  const [minTrd,setMinTrd] = useState(false);
  const [trdType,setTrdType] = useState('');
  const [screener,setScreener] = useState(false);
  const [toast,setToast] = useState(false);
  const [toastMsg,setToastMsg] = useState({})
  const [pendingModal,setPendingModal] = useState(false)
  const [errorModal,setErrorModal] = useState(false)
  const [finishedModal,setFinishedModal] = useState(false)
  const [detailModal,setDetailModal] = useState(false)
  const [errorDetails,setErrorDetails] = useState([])
  const [createAllocations,setCreateAllocations] = useState(true)
  const [tradeDetails,setTradeDetails] = useState([])
  const [acctDetail,setAcctDetail] = useState([])
  const [csdnDetail,setCsdnDetail] = useState([])
  const [acctTradeDetail,setAcctTradeDetail] = useState([])
  const [csdnTradeDetail,setCsdnTradeDetail] = useState([])
  const [loading,setLoading] = useState(false);
  const [initialCalc,setInitialCalc] = useState({});
  const [detailAccount,setDetailAccount] = useState("")
  const [detailName,setDetailName] = useState("")
  const [detailR,setDetailR] = useState("")
  const [detailCsdn,setDetailCsdn] = useState("")
  const [detailType,setDetailType] = useState("")
  const [detailSym,setDetailSym] = useState("")
  const [detailCsdnCsdn,setDetailCsdnCsdn] = useState("")
  const [changesModal,setChangesModal] = useState(false);
  const [sleeve,setSleeve] = useState('');
  const [sleevesList,setSleevesList] = useState([]);
  const [recentChanges,setRecentChanges] = useState('');
  const [changeNumber,setChangeNumber] = useState(1);
  const [sellOrder,setSellOrder] = useState('prorata');

  function sendTrades(){
    setPendingModal(false);
    const output = {'createAllocations': createAllocations}
    setLoading(true);
    axios.post('https://backend.tenet-wealth.com/v1/sendPendedTrades',output,{withCredentials: true}).then(res => {
      console.log(res.data);
      setLoading(false);
      setFinishedModal(true)
      res.data.forEach(file => axios.get('https://backend.tenet-wealth.com/v1/downloadTradeFile/'+file,{withCredentials: true}).then(res=>{
        var url = window.URL.createObjectURL(new Blob([res.data]));
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }))
    }).catch( e =>{
      setToastMsg({'header':'ERROR','body': 'could not process. \n REASON: '+e});
      setToast(true);
      setLoading(false);
    });
  }

  function sendErrorTrade(override){
    setErrorModal(false);
    setLoading(true);
    if(override){
      var output = {...initialCalc,'overridePended':'yes'}
    }else{
      var output = {...initialCalc,'overridePended':'no'}
    }
    axios.post('https://backend.tenet-wealth.com/v1/pendTrades',output,{withCredentials: true}).then(res => {
      if(res.data['error_message']){
        setToastMsg({'header':'Caclulcation Error','body':res.data['error_message']})
        setErrorDetails(res.data['error_table'])
        setErrorModal(true)
      }else if (res.data['by_acct']) {
        setToastMsg({'header':'Calculation Processed','body':'trades have been pended'})
        setAcctDetail(res.data['by_acct'])
        setCsdnDetail(res.data['by_csdn'])
        console.log(res.data['by_acct'])
        console.log(res.data['by_csdn'])
        setPendingModal(true)
      }else{
        setToastMsg({'header':'No response','body':'No Calulations were made'})
      }
      setToast(true);
      setLoading(false)
    }).catch( e =>{
      setToastMsg({'header':'ERROR','body': 'could not process. \n REASON: '+e});
      setToast(true);
      setLoading(false);
    });
  }

  function sendBlockTrade(event) {
    event.preventDefault();
    setLoading(true)
    const form = event.target;
    var output = {}
    for (let input of form.elements){
      if(input.id){
        if(['buyeq','buylistonly','selllistonly'].includes(input.id)){
          output[input.id] = input.checked
        }else{
          output[input.id] = input.value
        }
      }
    }
    output['tradeType'] = trdType
    output['sellOrder'] =sellOrder
    setInitialCalc(output);
    axios.post('https://backend.tenet-wealth.com/v1/pendTrades',output,{withCredentials: true}).then(res => {
      if(res.data['error_message']){
        setToastMsg({'header':'Caclulcation Error','body':res.data['error_message']})
        setErrorDetails(JSON.parse(res.data['error_table']))
        setErrorModal(true)
      }else if (res.data['by_acct']) {
        setToastMsg({'header':'Calculation Processed','body':'trades have been pended'})
        setAcctDetail(res.data['by_acct'])
        setCsdnDetail(res.data['by_csdn'])
        setPendingModal(true)
      }else{
        setToastMsg({'header':'No response','body':'No Calulations were made'})
      }
      setToast(true);
      setLoading(false)
    }).catch( e =>{
      setToastMsg({'header':'ERROR','body': 'could not process. \n REASON: '+e});
      setToast(true);
      setLoading(false);
    });

  }

  function acctTradeDetails(acct){
    setDetailModal(true)
    axios.get('https://backend.tenet-wealth.com/v1/pendedTradesDetails',{withCredentials: true, params:{acct: acct}}).then(res=>{
      setAcctTradeDetail(res.data)
      const detailData = res.data[0];
      if(detailData){
        setDetailAccount(detailData['acct'])
        setDetailR(detailData['acctr'])
        setDetailType(detailData['accttype'])
        setDetailCsdn(detailData['csdn'])
        setDetailName(detailData['acctnm'])
      }
    })
  }

  function csdnTradeDetails(csdn,sym){
    setDetailModal(true)
    axios.get('https://backend.tenet-wealth.com/v1/pendedTradesDetails',{withCredentials: true, params:{csdn: csdn, sym: sym}}).then(res=>{
      setCsdnTradeDetail(res.data)
      setDetailCsdnCsdn(csdn)
      setDetailSym(sym)

    })
  }

  function closeDetail(){
    setDetailModal(false)
    setAcctTradeDetail([])
    setCsdnTradeDetail([])
    setDetailAccount("")
    setDetailR("")
    setDetailType("")
    setDetailCsdn("")
    setDetailName("")
    setDetailSym("")
    setDetailCsdnCsdn("")

  }

  useEffect(() =>{
    getSleeves();
  },[]);

  const getSleeves = () =>{
    axios.get('https://backend.tenet-wealth.com/v1/sleeves', {withCredentials: true}).then(res =>{
      const sList = JSON.parse(res.data.sleeves);
      setSleevesList(sList.map( (x,index) => ({'value': x, 'label':x,'key':index})));
    }).catch((err) => {
      if(err.response){
        if(err.response.status ==401){
        history.push("/login");
      };
    } else{console.log(err)}
    })
  };

  const getRecentChanges = (sleeves,number) =>{
    axios.get('https://backend.tenet-wealth.com/v1/recentChanges',{withCredentials: true, params:{sleeves: sleeves, n: number}}).then(res=>{
      setRecentChanges(res.data)
    }).catch((err) =>{
      console.log(err)
      setRecentChanges('')
    })

  }

  const sleeveSelectChange = (e) =>{
    if(e){
      const result = e.map(row => row.value).join()
      setSleeve(result);
      getRecentChanges(result,changeNumber);
    } else{
      setSleeve('');
    }
  }

  const sellOrderChange = (e) =>{
    if(e){
      const result = e.map(row => row.value).join()
      setSellOrder(result);
    } else{
      setSellOrder('prorata');
    }
  }

  const sellOrderList = [
    {value: 'prorata', label: 'proRata'},
    {value: 'pgn', label:'Percent Gain'},
    {value:'dchg', label:'Dollar Change'},
    {value:'sell_list_order', label: 'Sell List Order'}
  ]

  const acctColumns = [
  {
    title: 'Account',
    dataIndex: 'Account',
    key: 'Account',
    sorter: (a, b) => a.Account.toString().localeCompare(b.Account.toString())

  },
  {
    title: 'Account Name',
    dataIndex: 'Account Name',
    key: 'Account Name',
    sorter: (a, b) => a['Account Name'].localeCompare(b['Account Name'])

  },
  {
    title:'Registration',
    dataIndex: 'Registration',
    key: 'Registration'
  },
  {
    title: 'Model',
    dataIndex: 'Model',
    key: 'Model',
  },
  {
    title: 'Account Value',
    dataIndex: 'Account Value',
    key: 'Account Value',
    sorter: (a, b) => a['Account Value'] - b['Account Value']

  },
  {
    title: 'Starting Cash',
    dataIndex: 'Starting Cash',
    key: 'Starting Cash',
  },
  {
    title: 'Sells',
    dataIndex: 'Sells',
    key: 'Sells',
  },
  {
    title: 'Gain',
    dataIndex: 'Gain',
    key: 'Gain',
    sorter: (a, b) => a.Gain - b.Gain

  },
  {
    title: 'Buys',
    dataIndex: 'Buys',
    key: 'Buys',
  },
  {
    title: 'Ending Cash',
    dataIndex: 'Ending Cash',
    key: 'Ending Cash',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a['Ending Cash'] - b['Ending Cash']

  },
  {
    title:'Details',
    key: 'details',
    render: (text,record) =>(
     <Button type="primary" onClick={e => acctTradeDetails(record.Account)}>Details</Button>
    ),
  }
]
  const errorColumns = [
    {
      title: 'Account',
      dataIndex: 'acct',
      key: 'acct',

    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',

    },
  ]

  const csdnColumns = [
  {
    title: 'Custodian',
    dataIndex: 'csdn',
    key: 'csdn',

  },
  {
    title: 'Symbol',
    dataIndex: 'sym',
    key: 'sym',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.sym.toString().localeCompare(b.sym.toString())

  },
  {
    title: 'Last',
    dataIndex: 'Last',
    key: 'Last',
  },
  {
    title: 'Sell Proceeds',
    dataIndex: 'Sell Proceeds',
    key: 'Sell Proceeds',
    sorter: (a, b) => a['Sell Proceeds'] - b['Sell Proceeds']

  },
  {
    title: 'Sell Quantity',
    dataIndex: 'Sell Quantity',
    key: 'Sell Quantity',
  },
  {
    title: 'Gains',
    dataIndex: 'Gains',
    key: 'Gains',
    sorter: (a, b) => a['Gains'] - b['Gains']

  },
  {
    title: 'Buy Money',
    dataIndex: 'Buy Money',
    key: 'Buy Money',
    sorter: (a, b) => a['Buy Money'] - b['Buy Money']

  },
  {
    title: 'Buy Quantity',
    dataIndex: 'Buy Quantity',
    key: 'Buy Quantity',
  },
  {
    title:'Details',
    key: 'details',
    render: (text,record) =>(
     <Button type="primary" onClick={e => csdnTradeDetails(record.csdn,record.sym)}>Details</Button>
    ),
  }

]

  const acctTradeColumns = [
    {
      title: 'Symbol',
      dataIndex: 'sym',
      key: 'sym',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a['sym'].toString().localeCompare(b['sym'].toString())
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',

    },
    {
      title: 'Change Quantity',
      dataIndex: 'chgQ',
      key: 'chgQ',
      sorter: (a, b) => a['chgQ'] - b['chgQ']


    },
    {
      title: 'Gain',
      dataIndex: 'gain',
      key: 'gain',
      sorter: (a, b) => a['gain'] - b['gain']

    },
    {
      title: 'Money',
      dataIndex: 'money',
      key: 'money',
      sorter: (a,b) => a.money - b.money
    },
  ]

  const csdnTradeColumns = [
    {
      title: 'Account',
      dataIndex: 'acct',
      key: 'acct',
      sorter: (a, b) => a['acct'].toString().localeCompare(b['acct'].toString())

    },
    {
      title: 'Account Name',
      dataIndex: 'acctnm',
      key: 'acctnm',
      sorter: (a, b) => a['acctnm'].localeCompare(b['acctnm'])

    },
    {
      title: 'Account Registration',
      dataIndex: 'acctr',
      key: 'acctr'
    },
    {
      title: 'Account Type',
      dataIndex: 'accttype',
      key: 'accttype'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action'
    },
    {
      title: 'Change Quantity',
      dataIndex: 'chgQ',
      key: 'chgQ',
      sorter: (a, b) => a['chgQ'] - b['chgQ']

    },
    {
      title: 'Gain',
      dataIndex: 'gain',
      key: 'gain',
      sorter: (a, b) => a['gain'] - b['gain']
    },
    {
      title: 'Money',
      dataIndex: 'money',
      key: 'money',
      sorter: (a, b) => a['money'] - b['money']

    },
  ]



  if (trdType==''){
    return(
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1">
              Block Trading
            </Menu.Item>
            <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
              Home
            </Menu.Item>
            <Menu.Item key="3" className='ml-2' onClick={() => axios.get('https://backend.tenet-wealth.com/refreshQuotes')} >
              Refresh Quotes
            </Menu.Item>
            <Button className='ml-2' onClick={() => history.push('/pendedTrades')} ghost>
              Pended Trades
            </Button>

          </Menu>
        </Header>

        <Content style={{ padding: '20px 50px' }}>
          <div style={{background: "#fff", padding:'20px'}}>
            <div className="container-fluid">
              <div className='container'>
                  <Row className='mb-3'>
                    <Col lg={4} md={6} sm={6}>
                      <label>Trade Type</label>
                      <Form.Control as="select"  value= {trdType}  onChange = {e => setTrdType(e.target.value)}>
                       <option value=""> </option>
                       <option value='buy'>Buy</option>
                       <option value='sell'>Sell</option>
                       <option value='buyAndSell'>Buy and Sell</option>
                       <option value='meetcashneeds'>Meet Cash Needs</option>
                       <option value='withdrawals'>Meet Withdrawals</option>
                       <option value='liquidateAndClose'>Liquidate and Close</option>
                     </Form.Control>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
    )
  }

  if(loading){
    return(
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1">
              Block Trading
            </Menu.Item>
            <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
              Home
            </Menu.Item>
            <Menu.Item className='ml-2' key="3" onClick={() => axios.get('https://backend.tenet-wealth.com/refreshQuotes')} >
              Refresh Quotes
            </Menu.Item>
            <Button className='ml-2' onClick={() => history.push('/pendedTrades')} ghost>
              Pended Trades
            </Button>

          </Menu>
        </Header>

        <Content style={{ padding: '20px 50px' }}>
          <div style={{background: "#fff", padding:'20px','height':'50vh'}}>
            <ReactLoading type="bars" color="navy" />
          </div>
        </Content>
      </Layout>

    )
  }

  return (
    <Layout className="layout">
      <Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1">
            Block Trading
          </Menu.Item>
          <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
            Home
          </Menu.Item>
        </Menu>
      </Header>

      <Content style={{ padding: '20px 50px' }}>
        <div style={{background: "#fff", padding:'20px'}}>

      <div className="container-fluid">
        <div className='container'>
          <Form onSubmit={(e) => sendBlockTrade(e)}>
            <Row className='mb-3'>
              <Col lg={4} md={6} sm={6}>
                <label>Trade Type</label>
                <Form.Control as="select"  value= {trdType}  onChange = {e => setTrdType(e.target.value)}>
                 <option value='buy'>Buy</option>
                 <option value='sell'>Sell</option>
                 <option value='buyAndSell'>Buy and Sell</option>
                 <option value='meetcashneeds'>Meet Cash Needs</option>
                 <option value='withdrawals'>Meet Withdrawals</option>
                 <option value='liquidateAndClose'>Liquidate and Close</option>

               </Form.Control>
              </Col>
            </Row>
            {trdType !== 'liquidateAndClose' && (<>
            <Row>
              <Col  lg={4} md={6} sm={6}>
                <Form.Check
                 type="switch"
                 id="minTrade"
                 label="Minimum Trade Amount Restrictions"
                 onChange={e => setMinTrd(e.target.checked)}
                />
                <br/>
              {minTrd && <>
                <InputGroup className="mb-3">
                  <Form.Control as="input" type="number" id ='minpct' placeholder="Minimum Percent">
                  </Form.Control>
                  <InputGroup.Append>
                    <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                  </InputGroup.Append>

                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control as="input" type="number" id='mindollar' placeholder="Minimum Dollar Amount">
                  </Form.Control>

                </InputGroup>
              </>}
              </Col>
            </Row>

            <Row className='mt-3 ml-2'>
              <Button variant='primary' onClick={e => setChangesModal(true)}>Recent Sleeve Changes</Button>
            </Row>
            </>)}
              { (trdType=='sell' || trdType=='buyAndSell' || trdType=='meetcashneeds' || trdType=='withdrawals') &&
                (<>
                  <hr/>
                  <Row className='mt-3'>
                    <Col lg={10} md={8} sm={8}>
                      <Form.Label>
                        Sell list
                      </Form.Label>
                      <Form.Control as="input" type="text" id='selllist' placeholder="Comma seperated list of symbols to sell" >
                      </Form.Control>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col lg={10} md={8} sm={8}>
                      <Form.Label>
                        DONT Sell list
                      </Form.Label>

                      <Form.Control as="input" type="text" id='dontselllist' placeholder="Comma seperated list of symbols to NOT sell" >
                      </Form.Control>
                    </Col>
                  </Row>
                  <Row className='mt-3 d-block'>
                    <Col lg={6} md={6} sm={6}>
                      <Form.Label>
                        Sell Order
                      </Form.Label>

                      <Select
                        style={{'width': '100%'}}
                        isMulti
                        placeholder="Sell Order (default is prorata)"
                        options={sellOrderList}
                        onChange={sellOrderChange}
                        defaultValue={[{label:'proRata',value:'prorata'}]}
                      />
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <div className='container'>
                      <Form.Check
                       type="checkbox"
                       id="selllistonly"
                       label="Only sell from the list"
                      />
                    </div>
                  </Row>

                  <Row className='mt-3'>
                    <Col lg={3} md={4} sm={4}>
                      <Form.Label>
                        Liquidate Above %
                      </Form.Label>

                      <InputGroup>
                        <Form.Control as="input" step={.01} min={0} max={99} type="number" id="justSellAll" placeholder="Percent as decimal">
                        </Form.Control>
                        <InputGroup.Append>
                          <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                        </InputGroup.Append>

                      </InputGroup>
                    </Col>
                  </Row>
               </>)}
               <hr/>
               {trdType !== 'liquidateAndClose' && (
               <Row className='mt-3'>
                 <Col lg={4} md={4} sm={4}>
                   <Form.Label>
                     Minimum Cash Percent
                   </Form.Label>
                   <InputGroup className="mb-3">
                     <Form.Control as="input" type="number" step={.01} min={0} max={99} id='globalMinCash' placeholder="Minimum percent of account value to leave in cash" >
                     </Form.Control>
                     <InputGroup.Append>
                       <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                     </InputGroup.Append>

                   </InputGroup>
                 </Col>
               </Row> )}

              {(trdType=='buy' || trdType=='buyAndSell') &&
              (<>
                <Row>
                  <Col lg={10} md={8} sm={8}>
                    <Form.Label>
                      Buy list
                    </Form.Label>

                    <Form.Control as="input" type="text" id='buylist' placeholder="Comma seperated list of symbols to buy" >
                    </Form.Control>
                  </Col>
                </Row>
                <Row  className='mt-3'>
                  <Col lg={10} md={8} sm={8}>
                    <Form.Label>
                      DONT Buy list
                    </Form.Label>

                      <Form.Control as="input" type="text" id='dontbuylist' placeholder="Comma seperated list of symbols to NOT buy" >
                      </Form.Control>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <div className='container'>
                    <Form.Check
                     type="checkbox"
                     id="buylistonly"
                     label="Only buy from the list"
                    />
                  </div>
                </Row>

                <Row className='mt-3'>
                  <div className='container'>
                    <Form.Check
                     type="checkbox"
                     id="buyeq"
                     label="Buy Equivalences"
                    />
                  </div>
                </Row>
                <Row className='mt-3 mb-3'>
                  <Col lg={3} md={4} sm={4}>
                    <Form.Label>
                      Percent of Cash to Invest
                    </Form.Label>

                    <InputGroup>
                      <Form.Control required as="input" type="number" step={.1} max={100} min={0} defaultValue={100} id="cashinvest" placeholder="% of Cash">
                      </Form.Control>
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>
                </Row>
                <hr/>
             </>)}

             {trdType=='liquidateAndClose' &&
             (
               <>
                <Row className='mt-3 mb-3'>
                  <Form.Label>Accounts</Form.Label>
                    <Form.Control required as="input" type="text" id='accounts' placeholder="Accounts" >
                  </Form.Control>

                </Row>

               </>
             )

             }
            { trdType !== 'liquidateAndClose' && (
             <Row className='mt-3'>
               <Col  lg={4} md={6} sm={6}>
                 <Form.Check
                  type="switch"
                  id="screener"
                  label="Additional Screeners"
                  onChange={e => setScreener(e.target.checked)}
                 />
                 {screener && <>
                 <Form.Label>Sleeves</Form.Label>
                 <Form.Control as="input" type="text" id='sleeves' placeholder="Comma seperated list of Sleeves" >
                 </Form.Control>
                 <Form.Label>Models</Form.Label>
                 <Form.Control as="input" type="text" id='models' placeholder="Models" >
                 </Form.Control>
                 <Form.Label>Advisors</Form.Label>
                 <Form.Control as="input" type="text" id='advisors' placeholder="Advisors" >
                 </Form.Control>
                 <Form.Label>Accounts</Form.Label>
                 <Form.Control as="input" type="text" id='accounts' placeholder="Accounts" >
                 </Form.Control>
                 <Form.Label>HouseHoolds</Form.Label>
                 <Form.Control as="input" type="text" id='households' placeholder="Households" >
                 </Form.Control>
                 <Form.Label>Groups</Form.Label>
                 <Form.Control as="input" type="text" id='groups' placeholder="Groups" >
                 </Form.Control>
                 <Form.Label>Accounts to Exclude</Form.Label>
                 <Form.Control as="input" type="text" id='excludeaccounts' placeholder="Exclude Accounts" >
                 </Form.Control>
                  </> }
               </Col>
             </Row>
              )}
             <hr/>

             <Row className='mt-3'>
               <Button variant='success' type='submit' className='mr-5'>Calculate Trades</Button>
               <Button variant='danger'>Cancel</Button>
             </Row>

          </Form>
        </div>
        <Toast onClose={() => setToast(false)} show={toast} delay={5000} autohide  style={{
          position: 'fixed',
          top: 30,
          right: 30,
          minHeight: '100px',
          minWidth: '200px',
          zIndex: 7000
        }}>
          <Toast.Header>
            <strong className="mr-auto">{toastMsg.header}</strong><br/>
          </Toast.Header>
          <Toast.Body>{toastMsg.body}</Toast.Body>
        </Toast>

        </div>
        <Modal title="Trades Pended" maskClosable={false} visible={pendingModal} onCancel={() => setPendingModal(false)} width={2000} okButtonProps={{style: {display:'none'}}}>
            <Button type='primary' onClick={() => history.push("/pendedTrades")}>Pended Trades Page</Button>
        </Modal>

        <Modal title="Trades with Errors" maskClosable={false} visible={errorModal} onCancel={() => setErrorModal(false)} width={2000} okButtonProps={{style: {display:'none'}}}>
          <Table pagination={false}  scroll={{ y: '50vh' }} columns ={errorColumns} dataSource={errorDetails}/>
          <Row>
            <Button type='primary' className='mr-3' onClick={() => sendErrorTrade(true)}>Override Trades</Button>
            <Button type='primary' onClick={() => sendErrorTrade(false)}>Send Without Override</Button>
          </Row>
        </Modal>
        <Modal title="Trades Submited" maskClosable={false} visible={finishedModal} onCancel={() => setFinishedModal(false)} width={2000} okButtonProps={{style: {display:'none'}}}>
          <p>Trades Submitted. Trade files will download now</p>
        </Modal>
        <Modal title="Trade Details" visible={detailModal} onCancel={()=>closeDetail()} width={1500} okButtonProps={{style: {display:'none'}}} >
          {acctTradeDetail.length >0 ?
            <div>
              <Row className='mb-3'>
                <Col xs={6}>
                  <Statistic title="Account Number" value={detailAccount} formatter={(value) =>value}/>
                </Col>
                <Col xs={6}>
                  <Statistic title="Account Name" value={detailName} formatter={(value) =>value}/>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col xs={4}>
                  <Statistic title="Account Registration" value={detailR} formatter={(value) =>value}/>
                </Col>
                <Col xs={4}>
                  <Statistic title="Account Type" value={detailType} formatter={(value) =>value}/>
                </Col>
                <Col xs={4}>
                  <Statistic title="Custodian" value={detailCsdn} formatter={(value) =>value}/>
                </Col>

              </Row>
              <Table pagination={false} scroll={{ y: '50vh' }} columns={acctTradeColumns} dataSource={acctTradeDetail} />
            </div>
          : csdnTradeDetail.length > 0 ?
              <div>
                <Row className='mb-3'>
                  <Col xs={6}>
                    <Statistic title="Custodian" value={detailCsdnCsdn} formatter={(value) =>value}/>
                  </Col>
                  <Col xs={6}>
                    <Statistic title="Symbol" value={detailSym} formatter={(value) =>value}/>
                  </Col>
                </Row>
                <Table pagination={false} scroll={{ y: '50vh' }} columns={csdnTradeColumns} dataSource={csdnTradeDetail} />
              </div>
            : <ReactLoading type="bars" color="navy" />
          }
        </Modal>
        <Modal title="Recent Changes" visible={changesModal} onCancel={e => setChangesModal(false)}  okButtonProps={{style: {display:'none'}}}>
          <div className='container'>
            <Row className='mt-3 mb-1'>
              <label>Number of recent changes</label>
            </Row>
            <Row className='mb-3'>
              <input style={{width: '80px',  padding:'5px', 'border-radius':'10px'}} type='number' min={0} value={changeNumber} onChange={e => { setChangeNumber(e.target.value);
                                                                                getRecentChanges(sleeve,e.target.value)}} />
            </Row>
            <Row>
              <label>Sleeves</label>
            </Row>
            <Row className='mb-3 d-block'>
              <Select
                style={{'width': '100%'}}
                isMulti
                placeholder="Sleeve Details"
                options={sleevesList}
                onChange={sleeveSelectChange}
                isClearable
              />
              <p style={{'width':'100%','overflow-wrap': 'break-word'}}> {recentChanges} </p>
            </Row>
          </div>
        </Modal>
      </div>
    </Content>
  </Layout>

  )



}
export default BlockTrading;

import React,{useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {Form, Button, Row, Col, Toast, Modal } from 'react-bootstrap';
import { Layout, Menu, Table,} from 'antd';

import Select from 'react-select';

const { Header, Content, Footer } = Layout;

export default function FileDownload(){
  let history = useHistory();
  const [files,setFiles] = useState([]);


  const downloadFile = (filename) => {
    axios.get('https://backend.tenet-wealth.com/v1/downloadTradeFile/'+filename,{withCredentials: true}).then(res=>{
      var url = window.URL.createObjectURL(new Blob([res.data]));
      var link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch( e =>{
      console.log(e);
    });
  }

  const fileCols = [
    {
      title: 'Filename',
      dataIndex: 'file',
      key:'file'
    },
    {
      title:'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a,b) => a.date.localeCompare(b.date),
    },
    {
      title: 'Download',
      dataIndex: 'file',
      key:'file',
      render: (text,record) =>(
        <Button variant="success" onClick={(e)=>downloadFile(record.file)}>Download</Button>
      ),
    },

  ]

  useEffect(()=>{
    axios.get('https://backend.tenet-wealth.com/v1/filelist',{withCredentials:true}).then(res => {
      setFiles(JSON.parse(res.data.data))
    }).catch(e => {
      // check error needed
      // 400 error: redirect
      if(e.response.status ==401){
        history.push("/login");
      };
      console.log(e)
      // 500 error: show feedback
    });
  },[])

  return(
    <Layout className="layout">
    <Header>
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
        <Menu.Item key="1">
          File Download
        </Menu.Item>
        <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
          Home
        </Menu.Item>
      </Menu>
    </Header>
    <Content style={{ padding: '20px 50px' }}>
      <div style={{background: "#fff", padding:'20px'}}>
          <Table dataSource={files} columns = {fileCols} pagination={false}  scroll={{ y: '65vh' }}/>
      </div>

    </Content>
    </Layout>
  )
}

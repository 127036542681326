import React, {useState, useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Toast } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup'
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { uuid } from 'uuidv4';
import UserEdit from "./UserEdit.js"



import {Table, Layout, Menu, Popconfirm, Statistic, Switch, Modal, notification} from 'antd';
const { Header, Content, Footer } = Layout;



export default function AdminDashboard(){
  let history = useHistory();

  const [addUser,setAddUser] = useState(false);
  const [deleteUser,setDeleteUser] = useState(false);
  const [editUser,setEditUser] = useState(false);
  const [editUserList,setEditUserList] = useState([]);
  const [editUserSelect,setEditUserSelect] = useState('');
  const [editUserPage,setEditUserPage] = useState(false);

  const [otherData,setOtherData] = useState({pickRoles:[],pickCoreGroups:[],defaultEditList:[],defaultOwnerList:[],defaultTradeList:[]})

  const [deleteName,setDeleteName] = useState('')
  const [editName,setEditName] = useState('')



  const [roles,setRoles]= useState('')
  const [mobile,setMobile] = useState('')
  const [firstName,setFirstName] = useState('')
  const [lastName,setLastName] = useState('')
  const [username,setUsername] = useState('')
  const [coreGroup,setCoreGroup] = useState('')
  const [defaultEdit, setDefaultEdit] = useState('')
  const [defaultOwner,setDefaultOwner] = useState('')
  const [defaultTrade,setDefaultTrade] = useState('')
  const [email,setEmail] = useState('')
  const [ccEmail,setCCEmail] = useState('')
  const [tdBlock,setTdBlock] = useState('')
  const [tdBlockOptions,setTdBlockOptions] = useState(false)
  const [fidelityBlock,setFidelityBlock] = useState('')
  const [fidelityBlockOptions,setFidelityBlockOptions] = useState(false)
  const [schwabBlock,setSchwabBlock] = useState('')
  const [schwabBlockOptions,setSchwabBlockOptions] = useState(false)



  const handleAddUserClose = () => {
    setAddUser(false);
  }

  const handleDeleteUserClose = () => {
    setDeleteUser(false)
  }
  const handleEditUserClose = () => {
    setEditUser(false);
  }

  const handleEditUser = () => {

  }

  const handleDeleteUser = () => {
    const payload = {username: deleteName}
    axios.post('https://backend.tenet-wealth.com/v1/deleteuser',payload,{withCredentials: true}).then(res => {
      if(res.data['success']){
        setDeleteUser(false)
        notification['success']({
          message: 'User Deleted',
          description: `User ${deleteName} deleted successfully!`
        })

      } else if (res.data['error']) {
        notification['error']({
          message:'Delete Error',
          description: res.data['error']
        })
      } else {
        notification['error']({
          message:'Delete Error',
          description: 'Unkown error. Contact administrator'
        })

      }
    }).catch( e =>{
      notification['error']({
        message: 'Delete user failed',
        description: `Error: ${e}`
      })
    })

  }

  const roleChange = (e) =>{
    if(e){
      const result = e.map(row => row.value).join()
      setRoles(result);
    } else{
      setRoles('');
    }
  }
  const coreGroupChange = (e) =>{
    if(e){
      const result = e.value
      setCoreGroup(result);
    } else{
      setCoreGroup('');
    }
  }
  const editChange = (e) =>{
    if(e){
      const result = e.map(row => row.value).join()
      setDefaultEdit(result);
    } else{
      setDefaultEdit('');
    }
  }
  const ownerChange = (e) =>{
    if(e){
      const result = e.map(row => row.value).join()
      setDefaultOwner(result);
    } else{
      setDefaultOwner('');
    }
  }
  const tradeChange = (e) =>{
    if(e){
      const result = e.map(row => row.value).join()
      setDefaultTrade(result);
    } else{
      setDefaultTrade('');
    }
  }



  const handleAddUser = () => {

    const payload = {
      username:username,
      firstName:firstName,
      lastName:lastName,
      roles:roles,
      core_group:coreGroup,
      mobile:mobile,
      defaultEdit:defaultEdit,
      defaultOwner:defaultOwner,
      defaultTrade:defaultTrade,
      emailAddress:email,
      ccEmailAddresses:ccEmail,
      tdBlockAccount:tdBlock,
      tdBlockOptions:tdBlockOptions,
      schwabBlockAccount:schwabBlock,
      schwabBlockOptions:schwabBlockOptions,
      fidelityBlockAccount:fidelityBlock,
      fidelityBlockOptions:fidelityBlockOptions,
    }
    axios.post('https://backend.tenet-wealth.com/v1/adduser',payload,{withCredentials: true}).then(res => {
      if(res.data['success']){
        setAddUser(false)
        notification['success']({
          message: 'User Added',
          description: `User ${username} added successfully!`
        })

      }else if (res.data['error']) {
        notification['error']({
          message:'Add User Error',
          description: res.data['error']
        })

      }else{
        notification['error']({
          message:'Add User Error',
          description: 'Unkown error. Contact administrator'
        })

      }
    }).catch( e =>{
      notification['error']({
        message: 'Add user failed',
        description: `Error: ${e}`
      })
    })


  }

  useEffect(()=> {
    axios.get('https://backend.tenet-wealth.com/v1/editUserList',{withCredentials: true}).then(res =>{
      setEditUserList(res.data.userlist.map( x => ({'value': x.username, 'label':`${x.username} - group: ${x.core_group}`})));
    });

  },[])
  useEffect(()=>{
    axios.get('https://backend.tenet-wealth.com/v1/editUserInfo/'+window.localStorage.getItem('user'),{withCredentials: true}).then(res =>{
      setOtherData(res.data);

    })
  },[])



  return(
    <Layout className="layout">
    <Header>
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
        <Menu.Item key="1">
          Admin Dashboard
        </Menu.Item>
        <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
          Home
        </Menu.Item>
      </Menu>
    </Header>

    <Content style={{ padding: '20px 50px' }}>
      <div style={{background: "#fff", padding:'20px'}}>
        <Button className='mb-3' onClick={e => setAddUser(true)}>Add User</Button>
        <br/><br/>
        <Button className='mb-3' onClick={e => setEditUser(true)}>Edit User</Button>
        <br/><br/>
        <Button variant='danger' className='mb-3' onClick={e => setDeleteUser(true)}>Delete User</Button>
      </div>

      <Modal visible={addUser} title="Add User" onCancel={handleAddUserClose} width='50%' okButtonProps={{style: {display:'none'}}} cancelButtonProps={{style: {display:'none'}}}>
        <div className="container mt-5 mb-5">
          <Form.Group controlId="addUser">
            <div className='mb-2'>
              <Form.Label>User Roles</Form.Label>
              <Select
                isMulti
                placeholder = "Roles"
                options = {otherData.pickRoles.map( row => ({value:row, label: row}))}
                onChange = {roleChange}
              />
            </div>
            <Form.Control className='mb-2' type="number" name ='mobile' placeholder="Mobile Number" value={mobile} onChange={e => setMobile(e.target.value)}/>
            <Form.Control className='mb-2' type="text" name='first' placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)}/>
            <Form.Control className='mb-2' type="text" name='last' placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)}/>
            <Form.Control className='mb-2' type="text" name='username' placeholder="Username" value={username} onChange={e => setUsername(e.target.value)}/>
            <div className='mb-2'>
              <Form.Label>Core Group</Form.Label>
                <Select
                  options = {otherData.pickCoreGroups.map( row => ({value: row, label: row}))}
                  isSearchable = {false}
                  onChange = {coreGroupChange}
                />
            </div>
            <div className='mb-2'>
              <Form.Label>Default Edit List</Form.Label>
                <Select
                  isMulti
                  placeholder="Default Edit List"
                  options={otherData.defaultEditList.map(row => ({value:row, label:row}))}
                  onChange={editChange}
                  isClearable
                />
            </div>
            <div className='mb-2'>
              <Form.Label>Default Owner List</Form.Label>
              <Select
                isMulti
                placeholder="Default Owner List"
                options={otherData.defaultOwnerList.map(row => ({value:row, label:row}))}
                onChange={ownerChange}
                isClearable
              />
            </div>
            <div className='mb-2'>
              <Form.Label>Default Trade List</Form.Label>
              <Select
                isMulti
                placeholder="Default Trade List"
                options={otherData.defaultTradeList.map(row => ({value:row, label:row}))}
                onChange={tradeChange}
                isClearable
              />
            </div>
            <Form.Control className='mb-2' type="email" name='email' placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}/>
            <Form.Control className='mb-2' type="email" name='cc' placeholder="CC Email" value={ccEmail} onChange={e => setCCEmail(e.target.value)}/>
            <Form.Control type="number" name ='tdBlock' placeholder="TD Block Account Number" value={tdBlock} onChange={e => setTdBlock(e.target.value)}/><br/>
            <Switch className='mb-4' onChange={checked => setTdBlockOptions(checked)}/>
              <label>TD Block Options Privilege</label>
            <Form.Control type="number" name ='fidelityBlock' placeholder="Fidelity Block Account Number" value={fidelityBlock} onChange={e => setFidelityBlock(e.target.value)}/><br/>
            <Switch className='mb-4' onChange={checked => setFidelityBlockOptions(checked)}/>
              <label>Fidelity Block Options Privilege</label>
            <Form.Control type="number" name ='schwabBlock' placeholder="Schwab Block Account Number" value={schwabBlock} onChange={e => setSchwabBlock(e.target.value)}/><br/>
            <Switch className='mb-4' onChange={checked => setSchwabBlockOptions(checked)}/>
              <label>Schwab Block Options Privilege</label>

          </Form.Group>
          <Button variant="primary" onClick = {handleAddUser} className="mb-3">Add User</Button>

        </div>
      </Modal>

      <Modal visible={editUser} title="Edit User" onCancel = {handleEditUserClose} width='50%' okButtonProps={{style: {display:'none'}}} cancelButtonProps={{style: {display:'none'}}}>
        <div className='m-3'>
          <Select className='mb-3' options = {editUserList} onChange={(e) => setEditUserSelect(e.value)} placeholder='Select Username' />
          <br/><br/>
          {editUserSelect && <Button className='mt-3' onClick = {e => setEditUserPage(true)} className="mb-3">Edit {editUserSelect}</Button>}
        </div>
      </Modal>

      <Modal visible={deleteUser} title="Delete User" onCancel={handleDeleteUserClose} okButtonProps={{style: {display:'none'}}} cancelButtonProps={{style: {display:'none'}}}>
        <div className="container mt-5 mb-5">
          <Form.Control className='mb-2' type="text" name='delete' placeholder="Enter Username to delete" value={deleteName} onChange={e => setDeleteName(e.target.value)}/>
          <Button variant="danger" onClick = {handleDeleteUser} className="mb-3">Delete User</Button>
        </div>
      </Modal>

      <Modal title="Edit User" width="80%" visible={editUserPage} onCancel={e => setEditUserPage(false)} maskClosable= {false} okButtonProps={{style: {display:'none'}}}>
        <UserEdit username={editUserSelect} key={uuid()} />
      </Modal>

    </Content>
    </Layout>



  )
}

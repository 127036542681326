import React, {useState, useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Toast } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup'
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { uuid } from 'uuidv4';

import {Table, Layout, Menu, Popconfirm, Statistic, Switch, Modal, notification} from 'antd';
const { Header, Content, Footer } = Layout;

export default function Equivalences(){
  let history = useHistory();
  const [equivalences,setEquivalences] = useState([]);
  const [filterEQ,setFilterEQ] = useState([]);

  const editEQ = (name) => {
    console.log(name);
  }



  useEffect(()=>{
    axios.get('https://backend.tenet-wealth.com/v1/equivalences', {withCredentials: true}).then(res =>{
      var response = res.data.data
      response.map( (row,index) => row.key = index)
      setEquivalences(response);
      setFilterEQ(response);
    }).catch((err) => {
      if(err.response){
        if(err.response.status ==401){
        history.push("/login");
      };
    } else{console.log(err)}
    })

  },[])

  const eqColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Components',
      dataIndex: 'components',
      key: 'components',
      render: (comp,record)=> comp.join()

    },
    {
      title:'Edit',
      key: 'edit',
      dataIndex:'edit',
      render: (edit,record) =>(
        <>
        {edit==1 ? <Button type="primary" onClick={e => editEQ(record.name)}> Edit </Button>
        : <Button type="primary" disabled >Edit</Button>}
        </>
      ),
    },
  ]


  return(
    <Layout className="layout">
    <Header>
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
        <Menu.Item key="1">
          Equivalences
        </Menu.Item>
        <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
          Home
        </Menu.Item>
      </Menu>
    </Header>

    <Content style={{ padding: '20px 50px' }}>
      <div style={{background: "#fff", padding:'20px'}}>
        <Table columns={eqColumns} dataSource={filterEQ} bordered={true} pagination={false}  scroll={{ y: '50vh' }}/>
      </div>

    </Content>
    </Layout>
  )
}

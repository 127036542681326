import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import './dataTable.css';
import {  Button } from 'react-bootstrap';
import ReactLoading from "react-loading";

import DataTable from './DataTable';
import axios from 'axios';
import instance from './axios';

import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';


// queryInitState is the init state of direct query strings
const queryInitState = {
	sym: "",
	acct: "",
	type:"",
	scope:"",
}
// mapQueryInitState is the init state of mapping query strings. acct - name, acct - household, acct - advisor.
// Type different name, household, and advisor in the filters, get the corresponding acct, then get the restrictions
const mapQueryInitState = {
	acctnm: "",
	hh: "",
	advisor: ""
}

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

// main filter table component
export default function FilterTable() {
	// Hooks for redirect
	let history = useHistory();

	// raw data from the backend
    const [colData, setColData] = useState();
    const [acctNames, setAcctNames] =  useState();
    const [households, setHouseHolds] = useState()
    const [advisorList, setAdvisorList] = useState();

	// processed data
	const [rowData, setRowData] = useState([]);
	const [acctNameMap, setAcctNameMap] = useState();
	const [acctAdvisorMap, setAcctAdvisorMap] = useState();
	const [acctHouseholdMap, setAcctHouseholdMap]  = useState();

	// query
	const [query, setQuery] = useState(queryInitState);
	const [queryAcctName, setQueryAcctName] = useState("");
	const [mapQuery, setMapQuery] = useState(mapQueryInitState);

	useEffect(() => {
		// Get raw data from the backend
		// If error, redirect to '/login'
		instance.get("/restrictions", {withCredentials:true}).then(res => {

			setColData(res.data);

		}).catch(e => {
			// check error needed
			// 400 error: redirect
			if(e.response.status ==401){
				history.push("/login");
			};			// 500 error: show feedback
		});
		instance.get("/acctNames", {withCredentials:true}).then(res => {

			setAcctNames(res.data);
		}).catch(e => {
			// check error needed
        	// 400 error: redirect
          	history.push("/login");
          	// 500 error: show feedback
        })
		instance.get("/households", {withCredentials:true}).then(res => {
			setHouseHolds(res.data);
		}).catch(e => {
			// check error needed
            // 400 error: redirect
          	history.push("/login");
          	// 500 error: show feedback
        })
		instance.get("/advisorList", {withCredentials:true}).then(res => {

			setAdvisorList(res.data);
		}).catch(e => {
			// check error needed
            // 400 error: redirect
          	history.push("/login");
          	// 500 error: show feedback
        })
	},[])

	function toMap(obj, keyName, valueName){
		// toMap(obj, keyName, valueName) returns a map.
		// param : obj is an object. It includes column oriented data.
		// keyName, valueName are two column names in the obj. And in the returned map, keyName is the key,
		// valueName is the value.

		let map = new Map();
		if(obj!= undefined && obj != null){
			for(const key in obj[keyName]){
				map.set(obj[keyName][key], obj[valueName][key]);
			}
		}
		return map;
	}

	// The following 3 useEffect change the column oriented data to map data.
	useEffect(() => {
		setAcctNameMap(toMap(acctNames, "acct", "acctnm"));
	},[acctNames])

	useEffect(() => {

		setAcctHouseholdMap(toMap(households, "acct", "hh"));
	},[households])

	useEffect(() => {
		setAcctAdvisorMap(toMap(advisorList,  "acct", "advisor"));
    },[advisorList])


    useEffect(() => {
        // Convert col obj data to row array

        if(colData!=undefined && colData != null){
            let rows = [];
            for(const key in colData['id']){
                let row = {};
                for(const col in colData){
                    row[col] = colData[col][key];
                }
                rows.push(row);
            }
            setRowData(rows);
        }
    },[colData])


	function getUniqueVal(col){
		// getUniqueVal(col) remove duplicated names
		return [...new Set(Object.values(col))];
	}
	function globalQuery(rows){
		// This function combines all the direct query together
		// Returns the filtered data
		const columns = Object.keys(query);
		// array.some()     "OR" logic
		// array.every()    "AND" logic

		return rows.filter(row =>
			columns.every(col =>
				(col in row ? (row[col] || "" ): query[col]).toString().toLowerCase().indexOf(query[col]) > -1
			)

		);
		// rows.some();
	}

	// The following three functions filter the map data
	function filterAcctNames(rows){
		return (acctNameMap != undefined && acctNameMap != null) ? rows.filter(row =>
			 (( acctNameMap.has(row["acct"])&& acctNameMap.get(row["acct"])!=null )? acctNameMap.get(row["acct"]).toLowerCase():"")
            .indexOf(queryAcctName.toString().toLowerCase()) > -1
		): rows;
	}
	function filterHouseholds(rows){
		return acctHouseholdMap ? rows.filter(row =>
              (( acctHouseholdMap.has(row["acct"])&& acctHouseholdMap.get(row["acct"])!=null )? acctHouseholdMap.get(row["acct"]).toLowerCase():"")
              .indexOf(mapQuery['hh'].toLowerCase()) > -1
              ): rows;
	}
	function filterAdvisorList(rows){
		return acctAdvisorMap ? rows.filter(row =>
              (( acctAdvisorMap.has(row["acct"])&& acctAdvisorMap.get(row["acct"])!=null )? acctAdvisorMap.get(row["acct"]).toLowerCase():"")
              .indexOf(mapQuery['advisor'].toLowerCase()) > -1
              ): rows;
	}


    return (
    <AlertProvider template={AlertTemplate} {...options}>
		<div className='row'>
			<div className='col-1 text-center'>
				<Button className='mt-3 mb-3 ' variant='primary' onClick={() => history.push('/dashboard')}>Home</Button>
			</div>
			<div className="col-10 p-3" >
            <div className="text-left mt-5 mb-5">
				{/* Filters */}
                <div className="row m-2">
                    <label className="col-md-2" htmlFor="account-id"> ACCOUNT </label>
                    <input type="search" className="col-md-4" list="account-datalist" id="account-id" name="account-name"
                        value={query.acct} onChange={e => setQuery({...query, acct: e.target.value.toString().toLowerCase()})} />
                    <datalist id="account-datalist">
	                    {
	                        colData && colData["acct"]&& (getUniqueVal(colData.acct)).map(
	                            (account,i) => <option key={i} value={account}/>
	                        )
	                    }
                     </datalist>
                    <label className="col-md-2"> ACCOUNT SEARCH </label>
                    <input className="col-md-4" type="search"
                        value={queryAcctName} onChange={e => setQueryAcctName(e.target.value.toString())} />
                </div>
                <div className="row m-2">
	                <label className="col-md-2" htmlFor="symbol-id">SYMBOL</label>
	                <input type="search" className="col-md-4" list="symbol-datalist" id="symbol-id" name="symbol-name"
	                    value={query.sym} onChange={e => setQuery({...query, sym: e.target.value.toString().toLowerCase()})}/>
	                <datalist id="symbol-datalist">
                        {
	                        colData && colData["sym"]&& (getUniqueVal(colData.sym)).map(
	                            (symbol,i) => <option key={i} value={symbol}/>
	                        )
	                    }

	                </datalist>

	                <label className="col-md-2" htmlFor="type-id">TYPE</label>
	                <input type="search" className="col-md-4" list="type-datalist" id="type-id" name="type-name"
	                    value={query.type} onChange={e => setQuery({...query, type: e.target.value.toString().toLowerCase()})}/>
	                <datalist id="type-datalist">
                        {
	                        colData && colData["type"]&& (getUniqueVal(colData.type)).map(
	                            (type,i) => <option key={i} value={type}/>
	                        )
	                    }
	                </datalist>
                </div>

                <div className="row m-2">
	                <label className="col-md-2" htmlFor="scope-id">SCOPE</label>
	                <input type="search" className="col-md-4" list="scope-datalist" id="scope-id" name="scope-name"
	                    value={query.scope} onChange={e => setQuery({...query, scope: e.target.value.toString().toLowerCase()})}/>
	                <datalist id="scope-datalist">
                        {
	                        colData && colData["scope"]&& (getUniqueVal(colData.scope)).map(
	                            (scope,i) => <option key={i} value={scope}/>
	                        )
	                    }
	                </datalist>
                </div>

                <div className="row m-2">
	                <label className="col-md-2" htmlFor="advisor-id">ADVISOR</label>
	                <input type="search" className="col-md-4" list="advisor-datalist" id="advisor-id" name="advisor-name"
	                    value={mapQuery['advisor']} onChange={e => setMapQuery({...mapQuery, advisor: e.target.value})}/>
	                <datalist id="advisor-datalist">
                        {
	                        advisorList && advisorList["advisor"] && (getUniqueVal(advisorList["advisor"])).map(
	                            (advisor,i) => <option key={i} value={advisor}/>
	                        )
	                    }
	                </datalist>



                    <label className="col-md-2" htmlFor="household-id">HOUSEHOLD</label>
                    <input type="search" className="col-md-4" list="household-datalist" id="household-id" name="household-name"
                        value={mapQuery['hh']} onChange={e => setMapQuery({...mapQuery, hh: e.target.value})}/>
                    <datalist id="household-datalist">
                        {
	                        households && households["hh"] && (getUniqueVal(households["hh"])).map(
	                            (household,i) => <option key={i} value={household}/>
	                        )
	                    }
                    </datalist>

                </div>

            </div>

			{/* Data Table */}
			{/* Filter data and pass the result to DataTable component. acctAllowedList is for ADD RESTRICTION and must be present to load the component*/}

            { (colData && acctNames )? (<DataTable data={filterAdvisorList(filterHouseholds(filterAcctNames(globalQuery(rowData))))}
                acctAllowedList={acctNames && acctNames['acct'] && getUniqueVal(acctNames.acct)}/>) :
								 (<div className="d-flex justify-content-center align-items-center">
								 		<ReactLoading type="spin" color="navy" className='mt-5' />
									</div> ) }

        </div>
				<div className='col-1'>
				</div>
			</div>
    </AlertProvider>

    );

}

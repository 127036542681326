import React,{useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {Form, Button, Row, Col, Toast } from 'react-bootstrap';
import { Layout, Menu, Table, Modal} from 'antd';
import UserEdit from "./UserEdit.js"
import { uuid } from 'uuidv4';


import Select from 'react-select';

const { Header, Content, Footer } = Layout;

export default function Settings(){
  let history = useHistory();

  const [changePW,setChangePW]= useState(false);
  const [password,setPassword] = useState('');
  const [newPW,setNewPW] = useState('');
  const [newPW1,setNewPW1] = useState('');
  const [toast,setToast] = useState(false);
  const [toastMsg,setToastMsg] = useState({})
  const [editUserPage,setEditUserPage] = useState(false);

  const handlePWClose = () => {
    setChangePW(false);
  };
  const handlePWChange = () => {
    if((newPW == '' )|| (password=='')){
      setToastMsg({'header':'Enter a password','body':'no password was entered'})
      setToast(true);
    } else if(newPW == newPW1){
      console.log('match')
      axios.post('https://backend.tenet-wealth.com/v1/changepassword',{'password':newPW,'currPW':password},{withCredentials: true}).then(res => {
        if(res.data.success){
          setToastMsg({'header':'Success','body':res.data.success})
        } else {
          setToastMsg({'header':'ERROR','body':res.data.error})
        }
        setToast(true);
      }).catch( e => {
        console.log(e);
        setToastMsg({'header':'ERROR','body':'error'})
        setToast(true);
      }
      )
    } else{
      setToastMsg({'header':'New Password doesnt match','body':'check typing and try again'})
      setToast(true);
    }
  }

  return(
    <Layout className="layout">
    <Header>
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
        <Menu.Item key="1">
          Account Settings
        </Menu.Item>
        <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
          Home
        </Menu.Item>
      </Menu>
    </Header>
    <Content style={{ padding: '20px 50px' }}>
      <div style={{background: "#fff", padding:'20px'}}>
        <Button className='mb-3' onClick={e => setChangePW(true)}>Change Password</Button>
        <br/>
        <Button className='mb-3' onClick={e => setEditUserPage(true)}>Edit User Settings</Button>

      </div>

      <Modal title="Change Password" width="80%" visible={changePW} onCancel={handlePWClose} maskClosable={false} okButtonProps={{style: {display:'none'}}}>
        <div className="container mt-5 mb-5">
          <Form.Group controlId="addWatchlist">
            <Form.Control type="password" name='current' placeholder="Current Password" value={password} onChange={e => setPassword(e.target.value)}/><br/>
            <Form.Control type="password" name ='new' placeholder="New Password" value={newPW} onChange={e => setNewPW(e.target.value)}/><br/>
            <Form.Control type="password" name='new1' placeholder="Confirm New Password" value={newPW1} onChange={e => setNewPW1(e.target.value)}/>

          </Form.Group>
          <Button variant="primary" onClick = {handlePWChange} className="mb-3">Submit</Button>

        </div>
      </Modal>
      <Modal title="Edit User" width="80%" visible={editUserPage} onCancel={e => setEditUserPage(false)} maskClosable= {false} okButtonProps={{style: {display:'none'}}}>
        <UserEdit username={window.localStorage.getItem('user')} key={uuid()} />
      </Modal>

      <Toast onClose={() => setToast(false)} show={toast} delay={5000} autohide  style={{
        position: 'fixed',
        top: 30,
        right: 30,
        minHeight: '100px',
        minWidth: '200px',
        zIndex: 7000
      }}>
        <Toast.Header>
          <strong className="mr-auto">{toastMsg.header}</strong><br/>
        </Toast.Header>
        <Toast.Body>{toastMsg.body}</Toast.Body>
      </Toast>

    </Content>
    </Layout>
  )
}

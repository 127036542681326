import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Form, Toast, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import {Table, Layout, Menu, Button, Popconfirm, Statistic} from 'antd';
import './dashboard.css';
import {VictoryPie} from 'victory';
import {ExclamationCircleOutlined, WarningFilled, CheckSquareFilled } from '@ant-design/icons';
import ReactLoading from "react-loading";


const { Header, Content, Footer } = Layout;

const ManualTrading = () => {
  let history = useHistory();

  const [loading,setLoading] = useState(false);

  const [symlist,setSymlist] = useState([]);
  const [sym,setSym] = useState('');
  const [action,setAction] = useState('buy');
  const [qty,setQty] = useState('');
  const [qtyType,setQtyType] = useState('shares');
  const [price,setPrice] = useState(0);
  const [resType,setResType] = useState('')
  const [notes,setNotes] = useState('');


  const [acctList,setAcctList] = useState([]);
  const [selectList,setSelectList] = useState([]);

  const [acct,setAcct] = useState('');
  const [acctType,setAcctType] = useState('');
  const [availableQty,setAvailableQty] = useState('Enter Symbol for Quantity');
  const [cashAvailable,setCashAvailable] = useState('');

  const [toast,setToast] = useState(false);
  const [toastMsg,setToastMsg] = useState({})

  const handleClose = () => {
    setAcct('');
  }

  const acctSelectChange = (e) =>{
    setSym('')
    setQty('')
    if(e){
      setAcct(e.value);
      getCashAvailable(e.value);
    } else{
      setAcct('');
      setCashAvailable('');
    }
  }
  const symLookup = (e) => {
    const sym = e.target.value;
    if (sym.length >0) {
      axios.get('https://backend.tenet-wealth.com/symsearch/'+sym).then( res =>{
        if (res.data.length > 0 ) {
          const newList= [];
          res.data.map(row =>{
            newList.push({"value":row.ticker, "label": row.company})
          })
          setSymlist(newList);
        } else {
          setSymlist([]);
        }

      })
      .catch((err) =>{
        console.log('Error with symbol search')
      })
    }

  }

  const getCashAvailable = (acct) => {
    axios.get('https://backend.tenet-wealth.com/v1/accountCashAvailable/'+acct,{withCredentials: true}).then(res => {
      setCashAvailable(res.data['available'])
    }).catch((err) => {
      console.log(err);
      setCashAvailable('');
    })

  }

  const getAvailableQuantity = (symbol) => {
    setAvailableQty('Loading')
    if(action=='sell'){
      axios.get('https://backend.tenet-wealth.com/v1/accountAvailableQuantity',{ params: {
          acct: acct,
          sym: symbol,
          acctType: acctType
        },
        withCredentials: true
      }).then( res =>{
        setAvailableQty(res.data['available quantity'])
      })
      .catch((err) =>{
        setAvailableQty('No data returned. Most likely 0 quantity available')
      })
    }
  }

  const submitManualTrade = () => {
    var tradeQty = parseFloat(qty)
    var tradeResType = resType;
    if(action=='sell'){
      tradeResType = null;
      if(tradeQty>0){
        tradeQty=tradeQty * -1;
      }

    }
    const payload = {
      'acct':acct,
      'accttype':acctType,
      'action':action,
      'sym':sym,
      'qty':tradeQty,
      'qtytype':qtyType,
      'price':price,
      'restrictionType':tradeResType,
      'notes':notes,
    }
    setAcct('')
    setAcctType('')
    setSym('')
    setQty('')
    setPrice(0)
    setNotes('')
    setCashAvailable('');
    setLoading(true);
    setAvailableQty('Enter Symbol for Quantity')

    axios.post('https://backend.tenet-wealth.com/v1/manualTrade',payload,{withCredentials: true}).then(res => {
      setToastMsg({'header':'Trade Processed','body':JSON.parse(res.data).message})
      setToast(true);
      setLoading(false);
      JSON.parse(JSON.parse(res.data).files).forEach(file => axios.get('https://backend.tenet-wealth.com/v1/downloadTradeFile/'+file,{withCredentials: true}).then(res=>{
        var url = window.URL.createObjectURL(new Blob([res.data]));
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })).catch(e => {
  			// check error needed
  			// 400 error: redirect
        if(e.response.status ==401){
          history.push("/login");
        };
        setToastMsg({'header':'Error','body':e})
        setToast(true);
        setLoading(false);
  			// 500 error: show feedback
  		});

    })

  }

  useEffect(() => {
    axios.get('https://backend.tenet-wealth.com/v1/accounts',{withCredentials: true}).then(res => {
      setAcctList(res.data);
      setSelectList(res.data.map( x => ({'value': x['acct'], 'label':x['acctnm']+' | '+x['acctr']+' | '+x['acct']})));
    }).catch(e => {
			// check error needed
			// 400 error: redirect
      if(e.response.status ==401){
        history.push("/login");
      };
      console.log(e)
			// 500 error: show feedback
		});
  },[])

  return(
    <Layout className="layout">
      <Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1">
            Manual Trading
          </Menu.Item>
          <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
            Home
          </Menu.Item>
          <Button className='ml-2' onClick={() => history.push('/pendedTrades')} ghost>
            Pended Trades
          </Button>

        </Menu>
      </Header>

      <Content style={{ padding: '20px 50px' }}>
        <div style={{background: "#fff", padding:'20px'}}>
        {loading ? <div>
           <ReactLoading type="bars" color="navy" />
           <h2>Processing Trade</h2>
           </div>
        :<div>
        <Row>
            <Col lg={3} sm={6}>
              <Select
                className="mt-3 mb-3"
                placeholder="Account Select"
                value = {selectList.filter( row => row.value==acct)}
                options = {selectList}
                onChange={acctSelectChange}
                isClearable
              />
            </Col>
          </Row>
          {cashAvailable &&
            <Statistic className='mb-2' title="Cash Available" value={cashAvailable} formatter={(value) =>'$'+value}/>
          }

          <Row >
            <Col sm={2}>
            <label style={{paddingRight:'15px'}} htmlFor="swt0">Account Type</label>
            <Form.Control className="my-input" as="select"  value= {acctType}  onChange = {e => setAcctType(e.target.value)}>
              <option value=''></option>
              <option value='1'>Cash</option>
              <option value='2'>Margin</option>
              <option value='3'>Short/Cover</option>

            </Form.Control>

            </Col>
          </Row>

          <Row className="mt-3">
            <Col xl={1} sm={2}>
              <Form.Control className="my-input" as="select"  value= {action}  onChange = {e => setAction(e.target.value)}>
               <option value='buy'>Buy</option>
               <option value='sell'>Sell</option>
              </Form.Control>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xl={1} sm={2}>
              <input
                required
                name="sym"
                className="my-input"
                placeholder="Symbol"
                value={sym}
                list={"symbol-datalist"}
                onChange={e => {symLookup(e);
                                setSym(e.target.value);
                              }
                        }
                onBlur = {e => getAvailableQuantity(e.target.value)}
                autoComplete="off"
              />
              <datalist id={"symbol-datalist"} name='sym' onChange={e => {setSym(e.target.value);
                                                                          getAvailableQuantity(e.target.value)}}>
                {symlist.map((row,index) => {
                  return(
                    <option key={index} value={row.value}>{row.label}</option>
                  )
                })}
              </datalist>
            </Col>
            <Col xl={1} sm={2}>
              <input className="my-input" name="qty" placeholder="Quantity" type="number" value={qty} onChange={e => setQty(e.target.value)} autoComplete="off"/>
            </Col>
            <Col sm={2}>
              <Form.Control className="my-input" as="select"  value= {qtyType}  onChange = {e => setQtyType(e.target.value)}>
                <option value='buy'>Shares</option>
                <option value='sell'>Dollars</option>
              </Form.Control>
            </Col>
          </Row>
          {action=='sell' &&
            <Statistic className="mb-2" title="Available Quantity" value={availableQty} formatter={(value) =>value}/>

          }

          <Row className="mt-3">
            <Col>
              <label style={{paddingRight:'15px'}} htmlFor="swt0">Price</label>
              <input className="my-input" name="price" type="number" placeholder="Price" value={price} onChange={e => setPrice(e.target.value)} autoComplete="off"/>
            </Col>
          </Row>
          {action=='buy' &&
          <Row className="mt-3">
            <Col sm={2}>
            <label style={{paddingRight:'15px'}} htmlFor="swt0">Restriction Type</label>
            <Form.Control className="my-input" as="select"  value= {resType}  onChange = {e => setResType(e.target.value)}>
              <option value=''>None</option>
              <option value='exclude'>Exclude</option>
              <option value='dontsell'>Don't Sell</option>
            </Form.Control>

            </Col>
          </Row>
        }
          <Row className="mt-3">
            <Popconfirm title="Confirm Submit?" onConfirm={submitManualTrade}>
              <Button type="primary">Submit Trade</Button>
            </Popconfirm>
          </Row>
          </div>
        }

        </div>
      </Content>

      <Toast onClose={() => setToast(false)} show={toast} delay={5000} autohide  style={{
        position: 'fixed',
        top: 30,
        right: 30,
        minHeight: '100px',
        minWidth: '200px',
        zIndex: 7000
      }}>
        <Toast.Header>
          <strong className="mr-auto">{toastMsg.header}</strong><br/>
        </Toast.Header>
        <Toast.Body>{toastMsg.body}</Toast.Body>
      </Toast>
    {/*  <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
    </Layout>
  )

}


export default ManualTrading;

import React,{useEffect, useState} from 'react';
import {DELETE, EDIT, ZOOMIN, ADD} from './action';
import instance from './axios';
import './dataTable.css';

import { useAlert } from 'react-alert';


// Columns cannot be changed.
const nonEditableArray = ["acct", "delete", "scope", "sym", "active", "ts", "id" ];
const nonEditableSet = new Set(nonEditableArray);

export default function ZoomInModal({action, row, acctAllowedList}){
	const alert = useAlert();

	const [title, setTitle] = useState("");
	const [button, setButton] = useState();

	const [data, setData] = useState({});

	function handleChange(e){
    	setData({ ...data, [e.target.name]: e.target.value });
    }
	useEffect(() => {
    	setData(row);
    },[row])

	useEffect(() => {
		// Display different result based on the props : action, row, acctAllowedList

		// request header
		const config = {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        };

		function handleDelete(event){
			// Send DELETE request to the backend
			// row.id is the id of the row to be delted
            event.preventDefault();
            instance.delete("/restrictions/"+row.id.toString(), {withCredentials: true,}).then( res => {
                console.log(res);
                alert.success("DELETE SUCCESS!", {onClose: () => {window.location.reload(false);}});

            }).catch(e => {
                alert.error("DELETE FAILED. PLEASE TRY AGAIN!")
                console.log(e);
            })
        }
        function handleUpdate(event){
            // Send PUT request to the backend
            event.preventDefault();
            const dataToUpdate = JSON.stringify(data);
            instance.put("/restrictions", dataToUpdate, config).then(res => {
                console.log(res);
                alert.success("UPDATE SUCCESS!", {onClose: () => {window.location.reload(false);}});
            }).catch(e => {
                alert.error("UPDATE FAILED. PLEASE CHECK YOUR DATA AND TRY AGAIN!")
                console.log(e);
            })
        }
        function handleSubmit(event){
            // Send POST request to the backend
            event.preventDefault();
						if(data.scope == ""){
							alert.error("ERROR. 'scope' field was not included. Try again.")
						} else {
							const dataToSubmit = JSON.stringify(data);
							instance.post("/restrictions", dataToSubmit, config).then(res => {
									console.log(res);
									alert.success("ADD SUCCESS!", {onClose: () => {window.location.reload(false);}});
							}).catch(e => {
								alert.error("ADD FAILED. PLEASE CHECK YOUR DATA AND TRY AGAIN!")
								console.log(e);
							})
						}

        }

		// Display different buttons according to the action
		switch(action){

			case DELETE:
				setTitle("Delete");
				setButton(<button type="button" className="btn btn-danger" onClick={ e => handleDelete(e) } data-dismiss="modal">DELETE</button>);
				break;
			case EDIT:
				setTitle("Edit");
				setButton(<button type="button" className="btn btn-primary" onClick={ e => handleUpdate(e) } data-dismiss="modal">UPDATE</button>);
				break;
			case ZOOMIN:
				setTitle("Detail");
				setButton("");
				break;
			case ADD:
				setTitle("Add");
				setButton(<button type="button" className="btn btn-success" onClick={ e => handleSubmit(e) } data-dismiss="modal">SUBMIT</button>);
				break;

			default:
				setTitle("Warning");
		}
	},[data, action])

	return (<div>
            <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{"backgroundColor":"rgba(0, 0, 0, 0.5)"}} >
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
		                <div className="modal-header">
		                    <h5 className="modal-title" id="staticBackdropLabel">{title}</h5>
		                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
		                        <span aria-hidden="true">&times;</span>
		                    </button>
		                </div>
	                    <div className="modal-body">
	                        <form>

		                        <table className="table table-striped table-bordered">
		                            <tbody>
		                            {
		                                Object.keys(data).length > 0 && Object.keys(data).map( (col,i) =>
		                                    <tr key={i}>
																					{ col =="acct" ? (<th scope="row">Name (Account #, Group, or Household)</th>)
																						: (<th scope="row">{col} </th>)
																					}
		                                        {   /* only for ADD */
													action == ADD ? (
														col == "acct" ? (
															<td>
																<input
																	type="search"
																	list="acct-allowed-list"
																	name={col}
                                  value={data[col] || ""}
                                  onChange={e => handleChange(e)}/>
																<datalist id="acct-allowed-list" className="search-dropdown-list-scrollbar">
																	{
                                  	acctAllowedList.map(
                                  	    (acct,j) => <option key={j} value={acct}/>
                                  	)
                                  }
																</datalist>
															</td>

														) :

														(
															col == "scope" ? (
																<td><select className="form-control mt-1" name={col} value={data[col] || ""} onChange={e => handleChange(e)} >
																	<option value="">Select...</option>
																	<option value="all accounts">All Accounts</option>
																	<option value="account">Account</option>
																	<option value="group">Group</option>
																	<option value="household">Household</option>
																	<option value="global">Global</option>
																</select></td>
															) : (
															col == "type" ? (
																<td><select className="form-control mt-1" name={col} value={data[col] || ""} onChange={e => handleChange(e)}>
																	<option value="">Select...</option>
																	<option value="exclude">Exclude</option>
																	<option value="dontsell">Dont Sell</option>
																	<option value="liquidatefrom">Liquidate From</option>
																	<option value="accumincsec">Accumulate Income Security</option>
																	<option value="dontmonetize">Dont Monetize</option>
																	<option value="nondiscretionary">Nondiscretionary</option>
																	<option value="mintrade">Minimum Trade</option>
																	<option value="accuminc">Accumulate Income</option>
																	<option value="donttrade">Dont Trade</option>
																	<option value="dontpurchase">Dont Purchase</option>
																	<option value="dontbuy">Dont Buy</option>
																</select></td>															) :

															(col !=="active" && col !=="type" &&
																	<td><input
																		type="text"
																		name={col}
																		value={data[col] || ""}
																		onChange={e => handleChange(e)}
																	/>
																	</td>)
																)

													)):
		                                            (   /* for EDIT, DETAIL, DELETE*/
			                                            (action == EDIT && !nonEditableSet.has(col)) ?
					                                        <td><input
						                                            type="text"
						                                            name={col}
						                                            value={data[col] || ""}
						                                            onChange={e => handleChange(e)}
						                                            />
						                                    </td>
				                                        : <td>{data[col] || ""}</td>
			                                        )
			                                    }
		                                    </tr>
		                                )
		                            }
		                            </tbody>

		                        </table>
	                        </form>
	                    </div>
	                    <div className="modal-footer">

		                   <button type="button" className="btn btn-secondary" data-dismiss="modal">CLOSE</button>

		                   {button}
	                    </div>
                    </div>
                </div>
            </div>
        </div>)
}

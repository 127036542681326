import React, {useState,useEffect} from 'react';
import axios from 'axios'

const Home = () => {
  const [user,setUser]= useState('');
  const [loggedin,setLoggedin]= useState(null);

  useEffect(() => {
    axios.post('https://backend.tenet-wealth.com/authenticate',{"authenticate":"please"},{withCredentials: true}).then(response => {
     if (response.data.login) {
         setLoggedin(true);
         setUser(response.data.user);
     }
   })
   .catch((err) => {
     //Pop up bad login request
     setLoggedin(false);
   })
      },[]);


  const logout = async () => {
    await axios.post('https://backend.tenet-wealth.com/logout',{"logout":"please"}, {withCredentials: true})
    .then(response => {
      setLoggedin(false);
    }
    )
  }

  if (loggedin){
    return(
      <div className='container'>
        <h1>Welcome {user}</h1>
        <a className='btn btn-primary' href='/dashboard' role='button'>Dashboard</a>
        <br/><br/>
        <button className="btn btn-primary" onClick={() => logout()}>
          logout
        </button>
      </div>
    )
  }
  if (!loggedin){
    return(
      <div className="row align-items-center h-100">
        <div className="col-10 mx-auto">
          <h1>Welcome to the TWM Advisor-Portal</h1>
          <a className='btn btn-primary' href='/login' role='button'>Login</a>
        </div>
      </div>
    )
  }

  return(
    <h3>TWM Advisor-Portal</h3>
  )
};
export default Home;

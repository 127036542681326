import React, {useState,useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { Form, Toast, Row, Col} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup'
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import NumberFormat from 'react-number-format';
import {Table, Statistic, Layout, Menu, Button,Modal} from 'antd';
import {PlusOutlined } from '@ant-design/icons';


const { Header, Content, Footer } = Layout;

const Overview = () => {
  let history = useHistory();

  const [holdings,setHoldings] = useState([]);
  const [aum,setAum] = useState([]);
  const [sleevePerf,setSleevePerf] = useState([]);
  const [loading,setLoading] = useState(false);
  const [sleeveDetails,setSleeveDetails] = useState([])
  const [showDetail,setShowDetail] = useState(false);
  const [detailName,setDetailName] = useState('');
  const [detailLoading,setDetailLoading] = useState(false);
  const [toast,setToast] = useState(false);
  const [toastMsg,setToastMsg] = useState({})


  const handleClose = () => {
    setSleeveDetails({});
    setShowDetail(false);
  }
  const showModal = (sleeve) => {
    if(!detailLoading){

      setShowDetail(true);
      setDetailName(sleeve);
      getSleeveDetails(sleeve);
  }
  }

  useEffect(() =>{
    fetchOverview();
    const interval = setInterval(()=>{
      fetchOverview();
    },300000);
    return () => clearInterval(interval);
  },[])

  const getSleeveDetails = (selectedSleeve) => {
    setSleeveDetails([]);
    setDetailLoading(true);
    axios.get('https://backend.tenet-wealth.com/v1/sleeveDetails/'+selectedSleeve+'?quotes=true', {withCredentials: true}).then(res =>{
      const details = JSON.parse(res.data.sleeveInfo);
      if(Object.keys(details)[0]=="error"){
        setToast(true)
        setToastMsg({'header':'Error',body:details['error']})
        setShowDetail(false)
      }else{
        setSleeveDetails(details);
      }
      setDetailLoading(false)
    }).catch((err) => {
      if(err.response){
        if(err.response.status ==401){
        history.push("/login");
      };
    } else{
        console.log(err);
        setLoading(false);
      }
    })
  }
  const fetchOverview = () => {
    setLoading(true);
    axios.get('https://backend.tenet-wealth.com/v1/positionOverview', {withCredentials: true}).then(res =>{
      setHoldings(JSON.parse(res.data.holdings));
      setAum(JSON.parse(res.data.aum));
      setSleevePerf(JSON.parse(res.data.sleevesPerformance));
      setLoading(false);
    }).catch((err) => {
      if(err.response){
        if(err.response.status ==401){
        history.push("/login");
      };
    } else{console.log(err)}
    })
  };

  const renderAumDeck = () => {
    return(
    Object.keys(aum).map((keyName,i) => (
        <Col>
          <Statistic prefix="$" title= {keyName+" AUM"} value={aum[keyName]}/>
        </Col>
    ))
  )}

  const renderPosTable = () => {
    const posCol = [
    {
      title: 'Symbol',
      dataIndex: 'sym',
      key: 'sym',
      sorter: (a, b) => a.sym.localeCompare(b.sym)

    },
    {
      title: 'Description',
      dataIndex: 'description',
      key:'description'
    },
    {
      title: 'Quantity',
      dataIndex: 'cq',
      key: 'cq',
      sorter: (a, b) => a.cq - b.cq
    },
    {
      title: 'Market Value',
      dataIndex: 'cmv',
      key: 'cmv',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.cmv - b.cmv
    },
    {
      title: 'Last Price',
      dataIndex: 'lastPrice',
      key: 'lastPrice',
      sorter: (a,b) => a.lastPrice -b.lastPrice
    }]
    return(
      <Table size="small" columns={posCol} dataSource={holdings} bordered={true} pagination={false}  scroll={{ y: '50vh' }}/>

  )}

  const renderSleeveTable = () => {
    const sleeveCol = [
    {
      key: 'key',
      dataIndex:'key',
      render: (text,record) =>(
        <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={(e)=>showModal(record.sleeve)}/>
      ),
      width: '10%',
    },
    {
      title: 'Sleeve',
      dataIndex: 'sleeve',
      key: 'sleeve',
      sorter: (a, b) => a.sleeve.localeCompare(b.sleeve)

    },
    {
      title: "Daily Change",
      dataIndex: 'wtChg',
      key: 'wtChg',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.wtChg - b.wtChg
    }]
    return(
      <Table size="small" columns={sleeveCol} dataSource={sleevePerf} pagination={false} bordered={true} scroll={{ y: '50vh' }}/>

  )}

  const detailColumns = [
  {
    title: 'Symbol',
    dataIndex: 'sym',
    key: 'sym',
    sorter: (a, b) => a.sym.localeCompare(b.sym)

  },
  {
    title: 'Weight',
    dataIndex: 'wt',
    key: 'wt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.wt - b.wt
  },
  {
    title: 'Percent Change',
    dataIndex: 'pChg',
    key: 'pChg',
    sorter: (a, b) => a.pChg - b.pChg
  },]

  return(
    <Layout className="layout">
      <Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1">
            Portfolio Overview
          </Menu.Item>
          <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
            Home
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: '20px 50px' }}>
        <div style={{background: "#fff", padding:'20px'}}>
          <CardDeck className='mb-3'>
            {renderAumDeck()}
          </CardDeck>
          <div className='row'>
            <div className='col-md-7'>
              <h1>Positions</h1>
              {renderPosTable()}
            </div>
            <div className='col-md-5'>
              <h1>Sleeves</h1>
              {renderSleeveTable()}
            </div>
          </div>
          {loading &&
            <div className='row'>
              <ReactLoading type="bars" color="navy" />
              <br/>
              <h5>Updating...</h5>
            </div>}
        </div>

       <Modal title={detailName+' Holdings'} visible = {showDetail} onHide={handleClose} onCancel={handleClose} footer= {null}
>
        <Table
          loading={detailLoading}
          size="small"
          columns={detailColumns}
          dataSource={sleeveDetails}
          pagination={false}
          scroll={{ y: '70vh' }}
        />
       </Modal>
       <Toast onClose={() => setToast(false)} show={toast} delay={5000} autohide  style={{
         position: 'fixed',
         top: 30,
         right: 30,
         minHeight: '100px',
         minWidth: '200px',
         zIndex: 7000
       }}>
         <Toast.Header>
           <strong className="mr-auto">{toastMsg.header}</strong><br/>
         </Toast.Header>
         <Toast.Body>{toastMsg.body}</Toast.Body>
       </Toast>


      </Content>
    </Layout>
  )

}
export default Overview;

import React, {useState, useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Toast } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup'
import { useHistory } from "react-router-dom";
import {Layout, Menu, Badge} from 'antd';
import { NotificationFilled, SettingOutlined } from '@ant-design/icons';

import 'antd/dist/antd.css';
import './dashboard.css';


const publicUrl=process.env.PUBLIC_URL
const tradingImage=publicUrl + "stockTradingImage.jpg";
const watchlistImage=publicUrl +"WatchList.jpg";
const twmImage=publicUrl + "twm.jpg";
const { Header, Content, Footer, Sider } = Layout;


// Long: add a react icon for the restriction. later need to be replaced.
const restrictionsImage = publicUrl + "logo512.png";


const Dashboard = () => {
  let history = useHistory();
  const [notification,setNotification] = useState(0)

  const getNotifications = () => {
    axios.get('https://backend.tenet-wealth.com/v1/newNotifications',{withCredentials:true}).then(res => {
      setNotification(res.data.count);
    }).catch(e => {
			// check error needed
			// 400 error: redirect
      if(e.response.status ==401){
        history.push("/login");
      };
      console.log(e)
			// 500 error: show feedback
		});
  }

  const logout = async () => {
    await axios.post('https://backend.tenet-wealth.com/logout',{"logout":"please"}, {withCredentials: true})
    .then(response => history.push("/"))
  }

  useEffect(() => {
    getNotifications();
  },[])

  return (
    <Layout>
      <Sider style={{"minHeight":"100vh"}}>
        <div className="mt-3 mb-3" style={{"justifyContent":"center","display":"flex","filter":"invert(1)"}}>
          <img src={twmImage} style={{"maxWidth":"100px","maxHeight":"100px"}}/>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
          <Menu.Item key="1" >
            Dashboard
          </Menu.Item>
          <Menu.Item key="2" onClick={() => history.push('/settings')}>
            Account Settings
          </Menu.Item>
          <Menu.Item key="3" onClick={() => history.push('/notifications')}>
            <Badge count={notification}>
              <NotificationFilled style={{color: 'white'}}/>
            </Badge>
          </Menu.Item>
          <Menu.Item key="4" onClick={() => logout()}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{"background":"#fff"}}>
          <h1>Tenet Wealth Management</h1>
        </Header>
        <Content>
          <div className="container-fluid">
            <p>Logged in as {window.localStorage.getItem('user')} </p>
            <CardGroup>
              <Card onClick={() => history.push("/accounts")} className=" text-center  m-3 dashboard-card">

                <Card.Img variant="top" src={publicUrl + "accountIcon.png"} />
                  <Card.Title>Accounts</Card.Title>

              </Card>
              {/* Portfolio overview */}
              <Card onClick={() => history.push("/overview")} className=" text-center  m-3 dashboard-card">

                <Card.Img variant="top" src={publicUrl + "portfolioIcon.png"} />
                <Card.Title>Portfolio Overview</Card.Title>

              </Card>

              <Card onClick={() => history.push("/trading")} className=" text-center  m-3 dashboard-card" >

                <Card.Img variant="top" src={publicUrl + "modelIcon.png"} />
                <Card.Title>Models and Sleeve</Card.Title>

              </Card>
             {/* Restriction table*/ }
              <Card onClick={() => history.push("/restrictions")} className=" text-center  m-3 dashboard-card">

                <Card.Img variant="top" src={publicUrl + "restrictionIcon.png"}/>
                <Card.Title>Restrictions</Card.Title>

              </Card>

            {/* Block Trading */}
            <Card onClick={() => history.push("/blocktrading")} className=" text-center  m-3 dashboard-card">

              <Card.Img variant="top" src={publicUrl + "blockIcon.png"} />
              <Card.Title>Block Trading</Card.Title>

            </Card>

            <Card onClick={() => history.push("/cashmanagement")} className=" text-center  m-3 dashboard-card">

              <Card.Img variant="top" src={publicUrl + "cashIcon.png"} />
              <Card.Title>Cash Management</Card.Title>

            </Card>

            <Card onClick={() => history.push("/watchlist")} className=" text-center  m-3 dashboard-card">

              <Card.Img variant="top" src={publicUrl + "binocularIcon.png"} />
              <Card.Title>Watchlist</Card.Title>

            </Card>

            {/* Manual Trading */}
            <Card onClick={() => history.push("/manualTrading")} className=" text-center  m-3 dashboard-card">

              <Card.Img variant="top" src={publicUrl + "tradingIcon.png"} />
              <Card.Title>Manual Trading</Card.Title>

            </Card>

            <Card onClick={() => history.push("/fileDownload")} className=" text-center  m-3 dashboard-card">

              <Card.Img variant="top" src={publicUrl + "fileIcon.png"} />
              <Card.Title>File Download</Card.Title>

            </Card>
            <Card onClick={() => history.push("/equivalences")} className=" text-center  m-3 dashboard-card">

              <Card.Img variant="top" src={publicUrl + "equivalence.png"} />
              <Card.Title>Equivalences</Card.Title>
            </Card>


            {window.localStorage.admin =="true" &&
            <Card onClick={() => history.push("/admin")} className=" text-center  m-3 dashboard-card">
              <Card.Img variant="top" src={publicUrl + "adminIcon.png"} />
              <Card.Title>Admin Tools</Card.Title>
            </Card>
            }

            </CardGroup>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Advisor Portfolio Solutions ©2020 Created by William Sullivan</Footer>

        </Layout>
    </Layout>
  );
}

export default Dashboard;

import React,{useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Row, Col } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import Select from 'react-select';
import { Layout, Menu, Table, Button, Modal} from 'antd';
import AddRestriction from "./AddRestriction.js";
import EditRestriction from "./EditRestriction.js";
const { Header, Content, Footer } = Layout;

export default function Restrictions1() {
  let history = useHistory();

  const [acctList,setAcctList] = useState([]);
  const [selectList,setSelectList] = useState([]);
  const [symList,setSymList] = useState([]);
  const [advisorList,setAdvisorList] = useState([]);
  const [scopeList,setScopeList] = useState([]);
  const [hhList,setHHList] = useState([]);
  const [groupList,setGroupList] = useState([]);
  const [typeList,setTypeList] = useState([]);
  const [pmList,setPMList] = useState([]);


  const [acct,setAcct] = useState('');
  const [sym,setSym] = useState('');
  const [hh,setHH] = useState('');
  const [advisor,setAdvisor] = useState('');
  const [type,setType] = useState('');
  const [scope,setScope] = useState('');
  const [group,setGroup] = useState('');
  const [pm,setPm] = useState('');

  const [newResShow,setNewResShow] = useState(false);
  const [resLoading,setResLoading] = useState(true);

  const [id,setID] = useState("");
  const [editResShow,setEditResShow] = useState(false);

  const [restrictions,setRestrictions] = useState([]);
  const [restrictionsDisplay,setRestrictionsDisplay] = useState([])



  const getAccountData = () => {
    axios.get('https://backend.tenet-wealth.com/v1/accounts',{withCredentials: true}).then(res => {
      setAcctList(res.data);
      setSelectList(res.data.map( x => ({'value': x['acct'], 'label':x['acctnm']+ ' : '+x['acctr']+' : '+x['acct']})));

    }).catch(e => {
      // check error needed
      // 400 error: redirect
      if(e.response){
        if(e.response.status ==401){
          history.push("/login");
        };
      }else{
        console.log(e)

      }
      // 500 error: show feedback
    });

  }

  async function getRestrictions(){
    setResLoading(true);
    axios.get('https://backend.tenet-wealth.com/v1/restrictions',{withCredentials: true}).then(res => {
      setRestrictions(res.data);
      setSymList([...new Set(res.data.map(row => row.sym))].map(x => ({'value':x, 'label':x})))
      setHHList([...new Set(res.data.map(row => row.search_hh))].map(x => ({'value':x, 'label':x})).filter(x =>x.value.length >0))
      setAdvisorList([...new Set(res.data.map(row => row.search_advisor))].map(x => ({'value':x, 'label':x})).filter(x =>x.value.length >0))
      setScopeList([...new Set(res.data.map(row => row.scope))].map(x => ({'value':x, 'label':x})).filter(x =>x.value.length >0))
      setGroupList([...new Set(res.data.map(row => row.search_grps))].map(x => ({'value':x, 'label':x})).filter(x =>x.value.length >0))
      setTypeList([...new Set(res.data.map(row => row.type))].map(x => ({'value':x, 'label':x})))
      setPMList([...new Set(res.data.map(row => row.search_pm))].map(x => ({'value':x, 'label':x})).filter(x =>x.value.length >0))

      setRestrictionsDisplay(res.data);
      setResLoading(false);
    }).catch(e => {
      // check error needed
      // 400 error: redirect
      console.log(e);
      if(e.response){
        if(e.response.status ==401){
          history.push("/login");
        };

      }
      console.log(e);
      setResLoading(false)
      // 500 error: show feedback
    });

  }

  async function updateRestrictions(){
    setResLoading(true);
    axios.get('https://backend.tenet-wealth.com/v1/restrictions',{withCredentials: true}).then(res => {
      setRestrictions(res.data);
      setRestrictionsDisplay(handleClear(res.data,'none'));
      setResLoading(false);
    }).catch(e => {
      // check error needed
      // 400 error: redirect
      console.log(e);
      if(e.response){
        if(e.response.status ==401){
          history.push("/login");
        };

      }
      console.log(e);
      setResLoading(false)
      // 500 error: show feedback
    });
  }

  const acctSelectChange = (e) =>{

    if(e){
      if(acct){
        setAcct(e.value);
        setRestrictionsDisplay(filterAccount(handleClear(restrictions,'acct'),e.value));
      }else{
        setAcct(e.value);
        setRestrictionsDisplay(filterAccount(restrictionsDisplay,e.value));

      }
    } else{
      setAcct('');
      setRestrictionsDisplay(handleClear(restrictions,'acct'));
    }
  }

  const renderEditModal = () =>{
    if(id){
      return(
        <EditRestriction key={id.id} id={id}/>
      )
    }
  }

  const symSelectChange = (e) =>{
    if(e){
      if(sym){
        setSym(e.value);
        setRestrictionsDisplay(filterSym(handleClear(restrictions,'sym'),e.value));
      }else{
        setSym(e.value);
        setRestrictionsDisplay(filterSym(restrictionsDisplay,e.value));
      }
    } else{
      setSym('');
      setRestrictionsDisplay(handleClear(restrictions,'sym'));
    }
  }
  const hhSelectChange = (e) =>{
    if(e){
      if(hh){
        setHH(e.value);
        setRestrictionsDisplay(filterHH(handleClear(restrictions,'search_hh'),e.value));
      }else{
        setHH(e.value);
        setRestrictionsDisplay(filterHH(restrictionsDisplay,e.value));
      }
    } else{
      setHH('');
      setRestrictionsDisplay(handleClear(restrictions,'search_hh'));
    }
  }
  const advisorSelectChange = (e) =>{
    if(e){
      if(advisor){
        setAdvisor(e.value);
        setRestrictionsDisplay(filterAdvisor(handleClear(restrictions,'search_advisor'),e.value));
      }else{
        setAdvisor(e.value);
        setRestrictionsDisplay(filterAdvisor(restrictionsDisplay,e.value));
      }
    } else{
      setAdvisor('');
      setRestrictionsDisplay(handleClear(restrictions,'search_advisor'));
    }
  }
  const scopeSelectChange = (e) =>{
    if(e){
      if(scope){
        setScope(e.value);
        setRestrictionsDisplay(filterScope(handleClear(restrictions,'scope'),e.value));
      }else{
        setScope(e.value);
        setRestrictionsDisplay(filterScope(restrictionsDisplay,e.value));
      }
    } else{
      setScope('');
      setRestrictionsDisplay(handleClear(restrictions,'scope'));
    }
  }
  const groupSelectChange = (e) =>{
    if(e){
      if(group){
        setGroup(e.value);
        setRestrictionsDisplay(filterGroup(handleClear(restrictions,'search_grps'),e.value));
      }else{
        setGroup(e.value);
        setRestrictionsDisplay(filterGroup(restrictionsDisplay,e.value));
      }
    } else{
      setGroup('');
      setRestrictionsDisplay(handleClear(restrictions,'search_grps'));
    }
  }
  const typeSelectChange = (e) =>{
    if(e){
      if(type){
        setType(e.value);
        setRestrictionsDisplay(filterType(handleClear(restrictions,'type'),e.value));
      }else{
        setType(e.value);
        setRestrictionsDisplay(filterType(restrictionsDisplay,e.value));
      }
    } else{
      setType('');
      setRestrictionsDisplay(handleClear(restrictions,'type'));
    }
  }
  const pmSelectChange = (e) =>{
    if(e){
      if(pm){
        setPm(e.value);
        setRestrictionsDisplay(filterPM(handleClear(restrictions,'search_pm'),e.value));
      }else{
        setPm(e.value);
        setRestrictionsDisplay(filterPM(restrictionsDisplay,e.value));
      }
    } else{
      setPm('');
      setRestrictionsDisplay(handleClear(restrictions,'search_pm'));
    }
  }

  const handleClear = (data,column) =>{
    var output= data;
    if(column !== 'acct'){
      output=filterAccount(output,acct);
    }
    if(column !== 'sym'){
      output=filterSym(output,sym);
    }
    if(column !== 'search_hh'){
      output=filterHH(output,hh);
    }
    if(column !== 'search_advisor'){
      output=filterAdvisor(output,advisor);
    }
    if(column !== 'scope'){
      output=filterScope(output,scope);
    }
    if(column !== 'search_grps'){
      output=filterGroup(output,group);
    }
    if(column !== 'type'){
      output=filterType(output,type);
    }
    if(column !== 'search_pm'){
      output=filterPM(output,pm);
    }
    return output;
  }

  const filterAccount = (data,selectedAccount) => {
    if(selectedAccount){
      const filtered = data.filter(row => row.acct == selectedAccount);
      return filtered
    }else{
      return data
    }
  }

  const filterSym = (data,selectedSym) => {
    if(selectedSym){
      const filtered = data.filter(row => row.sym == selectedSym);
      return filtered
    }else{
      return data
    }
  }

  const filterHH = (data,selectedHH) => {
    if(selectedHH){
      const filtered = data.filter(row => row.search_hh == selectedHH);
      return filtered
    }else{
      return data
    }
  }

  const filterAdvisor = (data,selectedAdvisor) => {
    if(selectedAdvisor){
      const filtered = data.filter(row => row.search_advisor == selectedAdvisor);
      return filtered
    }else{
      return data
    }
  }

  const filterScope = (data,selectedScope) => {
    if(selectedScope){
      const filtered = data.filter(row => row.scope == selectedScope);
      return filtered
    }else{
      return data
    }
  }

  const filterGroup = (data,selectedGroup) => {
    if(selectedGroup){
      const filtered = data.filter(row => row.search_grps == selectedGroup);
      return filtered
    }else{
      return data
    }
  }

  const filterType = (data,selectedType) => {
    if(selectedType){
      const filtered = data.filter(row => row.type == selectedType);
      return filtered
    }else{
      return data
    }
  }


  const filterPM = (data,selectedPM) => {
    if(selectedPM){
      const filtered = data.filter(row => row.search_pm == selectedPM);
      return filtered
    }else{
      return data
    }
  }

  const editRestriction = (record) => {
    setEditResShow(true);
    setID(record);

  }

  const editResCancel = () => {
    setEditResShow(false);
    updateRestrictions();
    setID("");
  }

  const addRestriction = () => {
    setNewResShow(true)
  }
  const addRestrictionClose = () => {
    setNewResShow(false)
    updateRestrictions();
  }
  const addRestrictionOK = () => {
    setNewResShow(false)
    updateRestrictions();

  }

  const restrictionColumns = [
    {
      title: 'Account',
      dataIndex: 'acct',
      key: 'acct',
      sorter: (a, b) => {
        a = a.acct || '';
        b = b.acct || '';
        return a.localeCompare(b);
    }
    },
    {
      title: 'Symbol',
      dataIndex: 'sym',
      key: 'sym',
      sorter: (a, b) => {
        a = a.sym || '';
        b = b.sym || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Type',
      dataIndex:'type',
      key:'type',
      sorter: (a, b) => {
        a = a.type || '';
        b = b.type || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Scope',
      dataIndex: 'scope',
      key: 'scope',
      sorter: (a,b) => {
        a = a.scope || '';
        b = b.scope || '';
        return a.localeCompare(b);
    }
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty',
      sorter: (a,b) => {
        a = a.qty || 0;
        b = b.qty || 0;
        return a  - b ;
    }
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title:'Edit',
      key: 'edit',
      dataIndex:'edit',
      render: (edit,record) =>(
        <>
        {edit==1 ? <Button type="primary" onClick={e => editRestriction(record)}> Edit </Button>
        : <Button type="primary" disabled >Edit</Button>}
        </>
      ),
    },
  ]
  useEffect(() =>{
    getRestrictions();
    getAccountData();
  },[])

  return(
    <Layout className="layout">
      <Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1">
            Restrictions
          </Menu.Item>
          <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
            Home
          </Menu.Item>
          <Button onClick={addRestriction} ghost>
            New Restriction
          </Button>
        </Menu>
      </Header>
      <Content style={{ padding: '20px 50px' }}>
        <div style={{background: "#fff", padding:'20px'}}>
        <Row>
            <Col lg={3} sm={6}>
            <label>Account</label>
              <Select
                className="mb-3"
                placeholder="Account Select"
                value = {selectList.filter( row => row.value==acct)}
                options = {selectList}
                onChange={acctSelectChange}
                isClearable
              />
          </Col>
          <Col lg={3} sm={6}>
          <label>Symbol</label>
            <Select
              className="mb-3"
              placeholder="Symbol Select"
              value = {symList.filter( row => row.value==sym)}
              options = {symList}
              onChange={symSelectChange}
              isClearable
            />
        </Col>
        <Col lg={3} sm={6}>
        <label>Advisor</label>
          <Select
            className="mb-3"
            placeholder="Advisor Select"
            value = {advisorList.filter( row => row.value==advisor)}
            options = {advisorList}
            onChange={advisorSelectChange}
            isClearable
          />
      </Col>
      <Col lg={3} sm={6}>
        <label>Household</label>
          <Select
            className="mb-3"
            placeholder="Household Select"
            value = {hhList.filter( row => row.value==hh)}
            options = {hhList}
            onChange={hhSelectChange}
            isClearable
          />
      </Col>
    <Col lg={3} sm={6}>
      <label>Scope</label>
        <Select
          className="mb-3"
          placeholder="Scope Select"
          value = {scopeList.filter( row => row.value==scope)}
          options = {scopeList}
          onChange={scopeSelectChange}
          isClearable
        />
    </Col>
    <Col lg={3} sm={6}>
      <label>Type</label>
        <Select
          className="mb-3"
          placeholder="Type Select"
          value = {typeList.filter( row => row.value==type)}
          options = {typeList}
          onChange={typeSelectChange}
          isClearable
        />
    </Col>
    <Col lg={3} sm={6}>
      <label>Group</label>
        <Select
          className="mb-3"
          placeholder="Group Select"
          value = {groupList.filter( row => row.value==group)}
          options = {groupList}
          onChange={groupSelectChange}
          isClearable
        />
    </Col>
    <Col lg={3} sm={6}>
      <label>Portfolio Manager</label>
        <Select
          className="mb-3"
          placeholder="Portfolio Manager Select"
          value = {pmList.filter( row => row.value==pm)}
          options = {pmList}
          onChange={pmSelectChange}
          isClearable
        />
    </Col>







        </Row>
        <Row>
          <Table
            loading = {resLoading}
            columns={restrictionColumns}
            dataSource={restrictionsDisplay}
            pagination={false}
            scroll={{ y: '60vh' }}
          />
        </Row>
        </div>

        <Modal title="New Restriction"
          visible={newResShow}
          onCancel={addRestrictionClose}
          okButtonProps={{style: {display:'none'}}}
        >
          <AddRestriction/>

        </Modal>

        <Modal title="Edit Restriction"
          visible={editResShow}
          onCancel={editResCancel}
          okButtonProps={{style: {display:'none'}}}
        >
          {id && renderEditModal()}
        </Modal>
      </Content>
    </Layout>

  )
}

import React,{useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Button, Row, Col, Modal, Toast } from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import NumberFormat from 'react-number-format';
import {Statistic, Table, Popconfirm} from 'antd';

import DataTable from '../restrictions/DataTable.js'
import WithdrawalsAddEdit from '../withdrawals/WithdrawalsAddEdit.js';
import EditRestriction from '../restrictions/EditRestriction.js';
import ReactLoading from "react-loading";


//BUG: when the add restriciton button is clicked the overview modal becomes non-scrollable

export default function AccountOverview(props) {
    //props should have an acct (number) optional admin (boolean)
    //For now, if the accountoverview endpoint returns admin under access_level,
    // the ability to change advisor will be enabled
    const [loading,setLoading] = useState(true);
    const [addResLoading,setAddResLoading] = useState(false);
    const [error,setError] = useState('');
    const [acctInfo,setAcctInfo] = useState('');
    const [advisorSelect,setAdvisorSelect] =useState(false)
    const [initialModel,setInitialModel] = useState('');
    const [newModel,setNewModel] = useState('');

    const [initialAdvisor,setInitialAdvisor] = useState('');
    const [newAdvisor,setNewAdvisor] = useState('');
    const [advisorList,setAdvisorList] = useState([]);

    const [initialAcctName,setInitialAcctName] = useState('');
    const [newAcctName,setNewAcctName] = useState('');
    const [editAcctModal,setEditAcctModal] = useState(false);

    const [initialHH,setInitialHH] = useState('');
    const [newHH,setNewHH] = useState('');
    const [householdList,setHouseholdList] = useState([]);

    const [restrictPositionModal,setRestrictPositionModal] = useState(false);
    const [name,setName] = useState('')
    const [type,setType] = useState('exclude')
    const [sym,setSym] = useState('')
    const [qty,setQty] = useState(0);
    const [expDate,setExpDate] = useState('');
    const [notes,setNotes] = useState('');

    const [editNotes,setEditNotes] = useState('')

    const [positions,setPositions] = useState([]);
    const [restrictions,setRestrictions] = useState([]);
    const [withdrawals,setWithdrawals] = useState([]);
    const [withdrawalEdit,setWithdrawalEdit] = useState(false);
    const [withdrawalAdd,setWithdrawalAdd] = useState(false);
    const [withID,setWithID] = useState('');

    const [id,setID] = useState("");
    const [editResShow,setEditResShow] = useState(false);


    const handleClose = () =>{
       setWithdrawalEdit(false);
       getOverview();
    }
    const handleAddClose = () => {
      setWithdrawalAdd(false);
      getOverview();
    }
    const [editToast,setEditToast] = useState(false);
    const [editToastMsg,setEditToastMsg] = useState({});

    const [modelsList,setModelsList] = useState([]);

    const editRestriction = (record) => {
      setEditResShow(true);
      setID(record);

    }

    const editResCancel = () => {
      setEditResShow(false);
      getOverview();
      setID("");
    }


    const renderEditModal = () =>{
      if(id){
        return(
          <div className='m-3'>
            <EditRestriction key={id.id} id={id}/>
          </div>
        )
      }
    }



    const modelSelectChange = (e) =>{
      if(e){
        setNewModel(e.value);
      } else{
        setNewModel('');
      }
    }
    const advisorSelectChange = (e) =>{
      if(e){
        setNewAdvisor(e.value);
      } else{
        setNewAdvisor('');
      }
    }
    const hhSelectChange = (e) =>{
      if(e){
        setNewHH(e.value);
      } else{
        setNewHH('');
      }
    }

    const hhSelectCreate = (e) =>{
      if(e){
        console.log(e)
        setHouseholdList([...householdList,{value: e, label: e }])
        setNewHH(e);
      } else{
        setNewHH('');
      }

    }
    const editWithdrawal = (id) => {
      setWithdrawalEdit(true);
      setWithID(id);
    }
    const addWithdrawal = () =>{
      setWithdrawalAdd(true);
    }
    const editModel = () =>{
      if (window.confirm(`Are you sure you wish to change model from ${initialModel} to ${newModel}?`)){
        const modelData={'acct':acctInfo.acct,'model':newModel,'notes':editNotes};

        axios.post('https://backend.tenet-wealth.com/v1/modelChange',modelData,{withCredentials: true}).then(res => {
          if(res.data.success){
            setEditToastMsg({'header':'Model Changed','body':res.data.success})
            setEditToast(true);
            setInitialModel(newModel);
            setEditNotes('')
          } else if (res.data.error) {
            setEditToastMsg({'header':'Error','body':res.data.error})
            setEditToast(true);
          }else{
            setEditToastMsg({'header':'Error','body':'unkown error'})
            setEditToast(true);
          }
        }).catch( e =>{
          setEditToastMsg({'header':'ERROR','body': 'could not process. \n REASON: '+e});
          setEditToast(true);
        })

      }

    }
    const editAdvisor = () =>{
      if (window.confirm(`Are you sure you wish to change advisor from ${initialAdvisor} to ${newAdvisor}?`)){
        const advisorData={'acct':acctInfo.acct,'advisor':newAdvisor}
        axios.post('https://backend.tenet-wealth.com/v1/advisorChange',advisorData,{withCredentials: true}).then(res => {
          if(res.data.success){
            setEditToastMsg({'header':'Advisor Changed','body':res.data.success})
            setEditToast(true);
            setInitialAdvisor(newAdvisor);
          } else if (res.data.error) {
            setEditToastMsg({'header':'Error','body':res.data.error})
            setEditToast(true);
          }else{
            setEditToastMsg({'header':'Error','body':'unkown error'})
            setEditToast(true);
          }
        }).catch( e =>{
          setEditToastMsg({'header':'ERROR','body': 'could not process. \n REASON: '+e});
          setEditToast(true);
        })
      }
    }

    const editHH = () =>{
      console.log('edit hh clicked')

      const householdData={'acct':acctInfo.acct,'household':newHH,'acctname':acctInfo.acctname}

      axios.post('https://backend.tenet-wealth.com/v1/householdChange',householdData,{withCredentials: true}).then(res => {
        if(res.data.success){
          setEditToastMsg({'header':'Household Changed','body':res.data.success})
          setEditToast(true);
          setInitialHH(newHH);
        } else if (res.data.error) {
          setEditToastMsg({'header':'Error','body':res.data.error})
          setEditToast(true);
        }else{
          setEditToastMsg({'header':'Error','body':'unkown error'})
          setEditToast(true);
        }
      }).catch( e =>{
        setEditToastMsg({'header':'ERROR','body': 'could not process. \n REASON: '+e});
        setEditToast(true);
      })
    }

    const editAcct = () =>{
      const householdData={'acct':acctInfo.acct,'household':initialHH,'acctname':newAcctName}

      axios.post('https://backend.tenet-wealth.com/v1/householdChange',householdData,{withCredentials: true}).then(res => {
        if(res.data.success){
          setEditToastMsg({'header':'Name Changed','body':res.data.success})
          setEditToast(true);
          setInitialAcctName(newAcctName);
        } else if (res.data.error) {
          setEditToastMsg({'header':'Error','body':res.data.error})
          setEditToast(true);
        }else{
          setEditToastMsg({'header':'Error','body':'unkown error'})
          setEditToast(true);
        }
        setEditAcctModal(false);

      }).catch( e =>{
        setEditToastMsg({'header':'ERROR','body': 'could not process. \n REASON: '+e});
        setEditToast(true);
        setEditAcctModal(false);
      })
    }


    const editAcctHide = () =>{
      setEditAcctModal(false);
    }

    const editAcctName = () =>{
      setEditAcctModal(true);
    }

    const restrictPosition= (sym,quantity) => {
      if(quantity == 0){
        setEditToast(true);
        setEditToastMsg({'header':'No Quantity to Restrict','body':'Position has no available quantity to restrict. A manual restriction can be added on the Restrictions page'})
      }else{
        setRestrictPositionModal(true)
        setName('Restrict '+sym +' for account '+ props.acct)
        setSym(sym)
        setQty(quantity)
      }
    }

    const restrictPositionClose = () =>{
      setRestrictPositionModal(false)
      setName('')
      setSym('')
      setQty('')
    }

    const sendRestriction = () => {
      setAddResLoading(true);
      var output = {}
      output['name'] = name;
      output['type'] = type;
      output['account'] = props.acct;
      output['scope'] = 'account';
      output['sym'] = sym;
      output['qty'] = qty;
      output['notes'] = notes;
      output['expdt'] = expDate;


      axios.post('https://backend.tenet-wealth.com/v1/restrictions',output,{withCredentials: true}).then(res => {
        if(res.data.error){
          setEditToastMsg({'header':'Restriction Add Error','body':res.data.error})
        }else{
          setEditToastMsg({'header':'Restriction Add Processed','body':res.data.success})
          setName('')
          setSym('')
          setQty('')
        }
        setEditToast(true);
        setRestrictPositionModal(false);
        getOverview();
        setAddResLoading(false);
      }).catch( e =>{
        setEditToastMsg({'header':'ERROR','body': 'could not process. \n REASON: '+e});
        setEditToast(true);
        setAddResLoading(false)
      });

    }

    const getOverview = () => {
      setLoading(true);
      axios.get('https://backend.tenet-wealth.com/v1/accountOverview/'+props.acct,{withCredentials: true}).then(res =>{
      if(JSON.parse(res.data)[0]['error']){
        setError('Error :'+ JSON.parse(res.data)[0]['error'])
      } else{
        const data= JSON.parse(res.data)[0];
        if(data['access_level'][0]['access_level'] === "admin"){
          setAdvisorSelect(true);
        }

        if(data.error){
          setError('Error :'+data.error);
        }
        const info= data['acct_data'][0];

        if(info){
          setAcctInfo(info);
          setInitialAcctName(info.acctname);
          setNewAcctName(info.acctname);
        }
        if(info.model){
          setInitialModel(info.model);
          setNewModel(info.model);
        }
        if(info.advisor){
          setInitialAdvisor(info.advisor);
          setNewAdvisor(info.advisor);
        }
        if(info.hh){
          setInitialHH(info.hh);
          setNewHH(info.hh);
        }
        if(data.positions){
          setPositions(data.positions);
        }
        if(data.restrictions){
          setRestrictions(data.restrictions);
          console.log(data.restrictions)
        }
        if (data.withdrawals){
          setWithdrawals(data.withdrawals);
        }
        setLoading(false);
      }
      }).catch( e =>{
        setLoading(false);
        console.log(e);
        setError("ERROR: "+e);
    }

    )
    };

    const posColumns = [
      {
        title: 'Symbol',
        dataIndex: 'sym',
        key:'sym',
        defaultSortOrder:'ascend',
        sorter: (a, b) => a['sym'].toString().localeCompare(b['sym'].toString())
      },
      {
        title: 'Quantity',
        dataIndex: 'Quantity',
        key:'Quantity',
        sorter: (a,b) => a.Quantity - b.Quantity
      },
      {
        title: 'Restricted Quantity',
        dataIndex: 'Restricted Quantity',
        key:'Restricted Quantity',
        sorter: (a,b) => a['Restricted Quantity'] - b['Restricted Quantity']

      },
      {
        title: 'Restrictions',
        dataIndex: 'Restrictions',
        key:'Restrictions',
      },
      {
        title: 'Available Value',
        dataIndex: 'Available Value',
        key:'Available Value',
        sorter: (a,b) => a['Available Value'] - b['Available Value']

      },
      {
        title: '$ Gain',
        dataIndex: 'Gain',
        key:'Gain',
        sorter: (a,b) => a['Gain'] - b['Gain']

      },
      {
        title: '% Gain',
        dataIndex: 'Pct Gain',
        key:'Pct Gain',
        sorter: (a,b) => a['Pct Gain'] - b['Pct Gain']

      },
      {
        title: 'Restrict Position',
        dataIndex: 'sym',
        key:'sym',
        render: (text,record) =>(
          <Button type="danger" onClick={(e)=>restrictPosition(record.sym,record.Quantity-record['Restricted Quantity'])}>Restrict</Button>
        ),
      },

    ];


    const withColumns = [
      {
        title: 'Type',
        dataIndex: 'type',
        key:'type'
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key:'amount'
      },
      {
        title: 'Needed',
        dataIndex: 'needed',
        key:'needed'
      },
      {
        title: 'Next',
        dataIndex: 'next',
        key:'next'
      },
      {
        title: 'Details',
        dataIndex: 'display',
        key:'display'
      },
      {
        title: 'Date Sent',
        dataIndex: 'Date Sent',
        key:'Date Sent'
      },
      {
        title: 'Edit',
        dataIndex: 'id',
        key:'id',
        render: (text,record) =>(
          <Button type="primary" onClick={(e)=>editWithdrawal(record.id)}>Edit</Button>
        ),
      },
    ];

    const resColumns = [
      {
        title: 'Type',
        dataIndex: 'type',
        key:'type'
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key:'name'
      },
      {
        title: 'Symbol',
        dataIndex: 'sym',
        key:'sym'
      },
      {
        title: 'Quantity',
        dataIndex: 'qty',
        key:'qty'
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        key:'notes'
      },
      {
        title:'Edit',
        key: 'edit',
        dataIndex:'edit',
        render: (edit,record) =>(
          <Button type="danger" onClick={e => editRestriction(record)}> Edit </Button>
        ),
      },

    ];

    useEffect(() => {
      if(props.acct){
        getOverview();
        axios.get('https://backend.tenet-wealth.com/v1/models',{withCredentials: true}).then(res =>{
          setModelsList(res.data.map( x => ({'value': x, 'label':x})));
        });
        axios.get('https://backend.tenet-wealth.com/v1/advisorList',{withCredentials: true}).then(res =>{
          setAdvisorList(res.data.map( x => ({'value': x, 'label':x})));
        });
        axios.get('https://backend.tenet-wealth.com/v1/householdList',{withCredentials: true}).then(res =>{
          setHouseholdList(res.data.map( x => ({'value': x, 'label':x})));
        });
      };
      if(props.admin){
        setAdvisorSelect(true);
      }
    },[])

    if(error){
      return(
        <div className='container'>
          <h2>{error}</h2>
        </div>
      )
    }

    if(loading){
      return(
        <div className="d-flex justify-content-center align-items-center mt-5">
           <ReactLoading type="spin" color="navy" className='mt-5 mb-5' />
         </div>
      )
    }


    return(

      <div className='container-fluid'>
        <br/>
        <Row className='mt-3'>
          <Col>
            <Statistic title="Account Number" value={acctInfo.acct} formatter={(value) =>value}/>
          </Col>
          {props.mv>-1 &&
          (<Col>
            <Statistic title="Market Value" value={Math.round(props.mv)} prefix='$'/>
          </Col>)}
        </Row>
        <Row className='mt-3'>
          <Col>
            <Statistic title="Name" value={initialAcctName} formatter={(value) =>value}/>
            <Button type='primary' onClick={editAcctName}>Edit Account Name</Button>
          </Col>
          <Col>
            <Statistic title="Household" value={initialHH} formatter={(value) =>value}/>
          </Col>
        </Row>
        <br/>
        <hr/>
        <br/>
        <Row>
          <Col md={4}>
            <Form.Label>Model</Form.Label>
            <Select options = {modelsList}
              defaultValue = {{value: initialModel, label: initialModel}}
              onChange={modelSelectChange}
              isClearable
            />


          </Col>
          <Col md={4}>
            <Form.Label>Advisor</Form.Label>
            {advisorSelect
              ? <Select options = {advisorList}
                defaultValue = {{value: initialAdvisor, label: initialAdvisor}}
                onChange={advisorSelectChange}
                isClearable
                    />
              : <Form.Control as="input" disabled value={initialAdvisor} />
            }

          </Col>
          <Col md={4}>
            <Form.Label>Household</Form.Label>
            <CreatableSelect
              options = {householdList}
              onChange={hhSelectChange}
              onCreateOption={hhSelectCreate}
              value={[{label: newHH, value: newHH}]}
              isClearable
            />
          </Col>

        </Row>
        {( (initialModel!==newModel) || (initialAdvisor !== newAdvisor) || (initialHH !== newHH)) &&
          <Row className='mt-3'>
            <Col md={4}>
            {(initialModel!==newModel) &&
              <div>
                <input  className="form-control" autoComplete="off" name="editNotes" placeholder="Notes for model edit log" value={editNotes} onChange={e => setEditNotes(e.target.value)}/>
                <Button className='mt-3' type='primary' onClick={editModel}>Save Model Change</Button>
              </div>
            }
            </Col>
            <Col md={4}>
            {(initialAdvisor !== newAdvisor) &&
              <Button type='primary' onClick={editAdvisor}>Save Advisor Change</Button>
            }
            </Col>
            <Col md={4}>
            {(initialHH !== newHH) &&
              <Popconfirm title="Confirm Submit?" onConfirm={editHH}>
                <Button variant='primary'>Save Household Change</Button>
              </Popconfirm>
            }
            </Col>
          </Row>

        }
        <br/>
        <hr/>
        <br/>
        <div className='row ml-1'>
          <h2>Positions</h2>
        </div>

        <div className='row ml-1 mr-1' style={{'maxHeight':'50vh','overflowY':'auto'}}>
          <Table columns={posColumns} dataSource={positions} bordered={true} pagination={false}  scroll={{ y: '25vh' }}/>
        </div>
        <div className='row mt-3 ml-1'>
            <h2>Withdrawals</h2>
        </div>
        <div className=' mt-1 justify-content-left'>
            <button type="button" className="btn btn-primary btn-sm" onClick={e => addWithdrawal()}>ADD WITHDRAWAL</button>
        </div>
        <div className='row mt-3 mr-1 ml-1' style={{'maxHeight':'40vh','overflowY':'auto'}}>
          <Table columns={withColumns} dataSource={withdrawals} bordered={true} pagination={false}  scroll={{ y: '25vh' }}/>

        </div>
        <div className='row mr-1 ml-1'>
          <h2>Additional Restrictions</h2>
          <Table columns={resColumns} dataSource={restrictions} bordered={true} pagination={false}  scroll={{ y: '25vh' }}/>
        </div>

        <Modal show={withdrawalEdit} onHide={handleClose} size='lg' backdrop="static" style={{"width":"80%","backgroundColor":"rgba(0, 0, 0, 0.5)"}}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Withdrawal</Modal.Title>
          </Modal.Header>
          <WithdrawalsAddEdit withdrawalID={withID}/>
        </Modal>

        <Modal show={withdrawalAdd}  onHide={handleAddClose} size='lg' backdrop="static" style={{"width":"80%","backgroundColor":"rgba(0, 0, 0, 0.5)"}}>
          <Modal.Header closeButton style={{"backgroundColor":"#adacad"}}>
            <Modal.Title>New Withdrawal</Modal.Title>
          </Modal.Header>
          <WithdrawalsAddEdit acct={props.acct}/>
        </Modal>

        <Modal show={restrictPositionModal} onHide={restrictPositionClose}>
        <Modal.Header closeButton style={{"backgroundColor":"#adacad"}}>
          <Modal.Title>Restrict {sym} for {props.acct}</Modal.Title>
        </Modal.Header>
          {addResLoading ?
            <div className="d-flex justify-content-center align-items-center mt-5">
               <ReactLoading type="spin" color="navy" className='mt-5 mb-5' />
             </div>
           :
          <div className='m-2'>
            <div>
              <label>Restriction Name</label>
              <input className="form-control" name="name" value={name} onChange={e =>setName(e.target.value)}/>
            </div>
            <div>
              <label  className='mt-2'>Restriction Type</label>
              <select className="form-control" name="type" value={type} onChange={e => setType(e.target.value)}>
                <option value="exclude">Exclude</option>
                <option value="dontsell">Dont Sell</option>
              </select>
            </div>
            <div>
              <label>Quantity</label>
              <input className="form-control" name='qty' type='number' value={qty} onChange={e =>setQty(e.target.value)}/>
            </div>
            <div>
              <label className='mt-2'>Expiration Date</label>
              <input className="form-control" name="expdt" value={expDate} onChange={e =>setExpDate(e.target.value)} type="date"/>
            </div>
            <div>
              <label  className='mt-2'>Notes</label>
              <input className="form-control" name="notes" value={notes} onChange={e =>setNotes(e.target.value)} autoComplete="off"/>
            </div>
            <div className='mt-3'>
              <Button type="primary" onClick={(e)=>sendRestriction()}>Submit Restriction</Button>
            </div>
          </div> }
        </Modal>

        <Modal title="Edit Restriction"
          show={editResShow}
          onHide={editResCancel}
        >
        <Modal.Header closeButton style={{"backgroundColor":"#adacad"}}>
          <Modal.Title>Restriction Edit</Modal.Title>
        </Modal.Header>

          {id && renderEditModal()}
        </Modal>

        <Modal show={editAcctModal} onHide={editAcctHide}>
          <Modal.Header closeButton style={{"backgroundColor":"#adacad"}}>
            <Modal.Title>Edit Account Name</Modal.Title>
          </Modal.Header>
          <div className='m-3'>
            <Row>
              <input value={newAcctName} onChange={e => setNewAcctName(e.target.value)} />
              <Button className='mt-3' onClick={editAcct}>Submit Edit</Button>
            </Row>
          </div>
        </Modal>

        <Toast onClose={() => setEditToast(false)} show={editToast} delay={5000} autohide  style={{
          position: 'fixed',
          top: 30,
          right: 30,
          minHeight: '100px',
          minWidth: '200px',
          backgroundColor: 'grey'
        }}>
          <Toast.Header>
            <strong className="mr-auto">{editToastMsg.header}</strong>
            <small>now</small>
          </Toast.Header>
          <Toast.Body style={{color:'white'}}>{editToastMsg.body}</Toast.Body>
        </Toast>

      </div>

  )
}

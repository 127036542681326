import React,{useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {Form, Button, Row, Col, Toast, Modal } from 'react-bootstrap';
import { Layout, Menu, Table,} from 'antd';

import Select from 'react-select';

const { Header, Content, Footer } = Layout;

export default function Notifications(){
  let history = useHistory();
  const [notification,setNotification] = useState([])

  const getNotifications = () => {
    axios.get('https://backend.tenet-wealth.com/v1/allNotifications',{withCredentials:true}).then(res => {
      var response = res.data
      response.map( (row,index) => row.key = index)

      setNotification(response);
    }).catch(e => {
      // check error needed
      // 400 error: redirect
      if(e.response.status ==401){
        history.push("/login");
      };
      console.log(e)
      // 500 error: show feedback
    });
  }

  const deleteNotification = (id) => {
    axios.delete('https://backend.tenet-wealth.com/v1/notifications/'+id,{withCredentials:true}).then(res => {
      getNotifications();
    })
  }

  const notificationCol = [
    {
      title: 'New Notifications',
      dataIndex: 'header',
      key: 'header'
    },
    {
      title: '',
      dataIndex:'id',
      key:'id',
      render: (edit,record) =>(
        <>
        <Button type="primary" onClick={e => deleteNotification(record.id)}> Mark Read </Button>
        </>
      ),
    }
  ]

  const oldNotificationCol = [
    {
      title: 'Old Notifications',
      dataIndex: 'header',
      key: 'header'
    },
  ]

  useEffect(() => {
    getNotifications();
  },[])

  return(
    <Layout className="layout">
    <Header>
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
        <Menu.Item key="1">
          Notifications
        </Menu.Item>
        <Menu.Item key="2" onClick={() => history.push('/dashboard')}>
          Home
        </Menu.Item>
      </Menu>
    </Header>
    <Content style={{ padding: '20px 50px' }}>
      <div style={{background: "#fff", padding:'20px'}}>
        <h4>New Notifications</h4>
        <Table
          columns = {notificationCol}
          dataSource={notification.filter(row => row.new == 1)}
          expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.body}</p>,
            expandRowByClick: true
          }}
        />
        <h4>Old Notifications</h4>
        <Table
          columns = {oldNotificationCol}
          dataSource={notification.filter(row => row.new == 0)}
          expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.body}</p>,
            expandRowByClick: true
          }}
        />

      </div>

    </Content>
    </Layout>
  )
}
